import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
`

export const TableContainer = styled.div`
  width: 100%;
  height: 77vh;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  padding: 50px;
  margin-bottom: 30px;
`

export const Content = styled.div`
  height: 50%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`
