import React, { useEffect, useState } from 'react'
import { Button, InputText, Select, TextArea } from '@devapi/design-system'
import { FormikProvider, useFormik } from 'formik'
import {
  InputContainer,
  FormContainer,
  ButtonsContainer,
  DeleteAndNextContainer,
} from './InformationForm.style'
import * as Yup from 'yup'
import { IInformationsFormProps } from '../../@types'
import { findAllCategories } from 'src/modules/Connectors/providers'
import { ICategoryOption } from 'src/modules/Connectors/presentation/Connectors.interface'
import { useHistory } from 'react-router'
import { FiArrowRight, FiCopy, FiTrash2 } from 'react-icons/fi'
import { CloneConnectorModal } from './../CloneConnectorModal'

export const InformationForm = ({
  onSubmitInformations,
  formInitialValues,
  handleDisplayInfos,
  privacy,
  handleDelete,
}: IInformationsFormProps) => {
  const [categoriesList, setCategoriesList] = useState<ICategoryOption[]>([
    { label: 'Select' },
  ])
  const [isOpenCloneConnectorModal, setIsOpenCloneConnectorModal] =
    useState(false)
  const validationSchema = Yup.object().shape({
    publicName: Yup.string().required('Campo obrigatório'),
    logoUrl: Yup.string().required('Campo obrigatório'),
    baseUrl: Yup.string().required('Campo obrigatório'),
    type: Yup.string().required('Campo obrigatório'),
    categoryId: Yup.string().required('Campo obrigatório'),
    description: Yup.string().required('Campo obrigatório'),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formInitialValues,
    validate: handleDisplayInfos,
    validationSchema: validationSchema,
    onSubmit: onSubmitInformations,
  })

  const typeOptions = [{ label: 'REST', value: 'REST' }]

  const getAllCategories = async () => {
    const categories = await findAllCategories()
    if (categories?.length) {
      const listedCategories = categories.map(category => ({
        label: category.name,
        value: category._id,
      }))
      setCategoriesList([
        ...listedCategories.sort((category1, category2) => {
          if (category1.label > category2.label) {
            return 1
          }
          return -1
        }),
      ])
    }
  }

  useEffect(() => {
    getAllCategories()
  }, [])

  const history = useHistory()
  const handleCancel = () => {
    history.push('/connectors')
  }

  const handleOpenCloneConnectorModal = () => {
    setIsOpenCloneConnectorModal(!isOpenCloneConnectorModal)
  }

  return (
    <>
      <FormikProvider value={formik}>
        <FormContainer onSubmit={formik.handleSubmit}>
          <InputContainer size={'100%'}>
            <InputText
              isFormik
              name={'publicName'}
              value={formik.values.publicName}
              label={'Nome em Exibição'}
              onChange={formik.handleChange}
              disabled={privacy === 'PUBLIC'}
            />
          </InputContainer>
          <InputContainer size={'100%'}>
            <Select
              isFormik
              value={formik.values.type}
              name={'type'}
              label={'Tipo'}
              optionList={typeOptions}
              isDisable={privacy === 'PUBLIC'}
              isLabelBold={true}
            />
          </InputContainer>
          <InputContainer size={'100%'}>
            <InputText
              isFormik
              value={formik.values.baseUrl}
              name={'baseUrl'}
              label={'URL Base'}
              onChange={formik.handleChange}
              disabled={privacy === 'PUBLIC'}
            />
          </InputContainer>
          <InputContainer size={'48%'}>
            <InputText
              isFormik
              name={'logoUrl'}
              value={formik.values.logoUrl}
              label={'URL Logo'}
              onChange={formik.handleChange}
              disabled={privacy === 'PUBLIC'}
            />
          </InputContainer>
          <InputContainer size={'48%'}>
            <Select
              isFormik
              name={'categoryId'}
              value={formik.initialValues.categoryId}
              label={'Categoria'}
              optionList={categoriesList}
              isDisable={privacy === 'PUBLIC'}
              isLabelBold={true}
            />
          </InputContainer>
          <InputContainer size={'100%'}>
            <TextArea
              isFormik
              name={'description'}
              value={formik.values.description}
              label={'Descrição'}
              onChange={formik.handleChange}
              isDisabled={privacy === 'PUBLIC'}
            />
          </InputContainer>
          <ButtonsContainer>
            <Button
              type={'button'}
              colorType={'neutral'}
              variant={'outlined'}
              text={'Cancelar'}
              onClick={handleCancel}
            />
            <DeleteAndNextContainer>
              {handleDelete && privacy === 'PRIVATE' && (
                <Button
                  type={'button'}
                  colorType={'red'}
                  variant={'outlined'}
                  text={'Deletar'}
                  icon={<FiTrash2 />}
                  onClick={handleDelete}
                />
              )}
              {privacy === 'PUBLIC' && (
                <Button
                  type={'button'}
                  colorType={'green'}
                  variant={'outlined'}
                  text={'Clonar como privado'}
                  icon={<FiCopy />}
                  iconPosition={'left'}
                  onClick={handleOpenCloneConnectorModal}
                />
              )}
              <Button
                colorType={'green'}
                variant={'default'}
                type={'submit'}
                text={'Próximo'}
                icon={<FiArrowRight />}
                iconPosition={'right'}
              />
            </DeleteAndNextContainer>
          </ButtonsContainer>
        </FormContainer>
      </FormikProvider>
      <CloneConnectorModal
        isOpen={isOpenCloneConnectorModal}
        onClickCloseModal={handleOpenCloneConnectorModal}
      />
    </>
  )
}
