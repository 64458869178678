import styled from 'styled-components'

export const ModalContent = styled.div`
  width: 700px;
  height: 350px;
`

export const FormContainer = styled.form`
  margin-top: 25px;
`

export const InputContainer = styled.div`
  margin-top: 15px;
`

export const ModalActions = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin: 1rem 0 0 0;
`
