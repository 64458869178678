import React, { useState } from 'react'
import {
  Button,
  notificationError,
  notificationSuccess,
} from '@devapi/design-system'
import { useHistory, useLocation } from 'react-router-dom'
import { Container, Icon, Text, Title } from './UnconfirmedEmail.style'

import IconEmailWarnning from '../../../assets/icons/mail-warning.svg'

import api from 'src/services/api'
import { ILocationProps } from './UnconfrimedEmail.interface'

const UnconfirmedEmail = () => {
  const history = useHistory()
  const location = useLocation<ILocationProps>()
  const [isLoading, setIsLoading] = useState(false)

  const handleSendConfirmationEmail = async () => {
    const { email } = location.state
    try {
      setIsLoading(true)
      if (email) {
        await api.post('/auth/send-email-confirmation', {
          email,
        })

        history.push('/')

        notificationSuccess({
          message: 'Email enviado com sucesso',
          position: 'top-right',
        })
      }
    } catch (error) {
      notificationError({
        message:
          error.response?.data?.message ||
          'Ocorreu um erro para enviar o email.',
        position: 'top-right',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Container>
      <Icon>
        <img src={IconEmailWarnning} alt="IconEmailWarnning" />
      </Icon>
      <Title>Seu email ainda não foi confirmado!</Title>
      <Text>
        Toda sua comunicação com a DevApi é via email. É por ele que enviaremos
        seus alertas.
      </Text>
      <Text>
        Confirme seu endereço email para não perder nada, e utilize todo o
        potência que a DevApi tem a oferecer.
      </Text>
      <Button
        colorType="secondary"
        text={isLoading ? 'Enviando...' : 'Receber email novamente'}
        variant="default"
        type="button"
        onClick={handleSendConfirmationEmail}
      />
    </Container>
  )
}

export { UnconfirmedEmail }
