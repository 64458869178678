import { ITokenData } from 'src/@types/token.interface'
import jwt from 'jsonwebtoken'

export const isExpiredPlan = (tokenAccessed: string) => {
  const tokenDecoded = jwt.decode(tokenAccessed) as ITokenData

  const daysRemaining = tokenDecoded.company_plan.days_remaining || 0

  const isActive =
    tokenDecoded.company_plan.plan_type === 'FREE' && daysRemaining <= 0
  return isActive
}
