import api from 'src/services/api'
import { IUpdateApiPayload } from '../@types/shared.interface'

export const updateApi = async ({
  connectors,
  description,
  id,
  name,
  url,
}: IUpdateApiPayload) => {
  return await api.put(`/apis/${id}`, { name, description, connectors, url })
}
