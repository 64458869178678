import React from 'react'
import styled from 'styled-components'
import { IApiContent, IApiSerialized } from '../@types/shared.interface'
import { StatusTag } from '../components/StatusTag'

const statusDeployLabel = {
  failed: 'Não Publicado',
  success: 'Publicado',
  pending: 'Aguardando',
  running: 'Publicando',
  canceled: 'Cancelado',
}

const statusDeployColor = {
  failed: 'failed',
  success: 'success',
  pending: 'pending',
  running: 'running',
  canceled: 'canceled',
}

const A = styled.a`
  text-decoration: none;
`

export const handleTableApi = (data: IApiContent[]): IApiSerialized[] => {
  return data.map(api => {
    return {
      id: api._id,
      name: api.name,
      docUrl: (
        <A href={api.url} target="_blank" rel="noreferrer">
          {api.url}
        </A>
      ),
      description: api.description,
      url: api.url,
      connectors: api.connectors,
      version: 'v1',
      statusDeploy: (
        <StatusTag
          label={
            statusDeployLabel[api.status_deploy] || statusDeployLabel.pending
          }
          variant={statusDeployColor[api.status_deploy]}
        />
      ),
    }
  })
}
