import styled from 'styled-components'
import theme from 'src/assets/styles/theme'
import {
  IChartContainerProps,
  IProgressBarProps,
  IAlignProps,
  IAmountProps,
} from './ChartOverviewKpi.interface'

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 2rem;
`

export const ChartContainer = styled.div<IChartContainerProps>`
  width: ${props => (props.width ? `${props.width}px` : '200px')};
`

export const ChartLabel = styled.label``

export const ChartKpi = styled.div`
  height: 200px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 1rem;
  align-items: center;
  border: 1px solid ${theme.colors.gray[300]};
  border-radius: 5px;
  padding: 2rem;
`

export const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const Align = styled.div<IAlignProps>`
  text-align: ${({ position }) => position};
`

export const Amount = styled.p<IAmountProps>`
  font-size: ${({ size }) => (size ? `${size}px` : '1rem')};
  color: ${({ color }) => color || 'inherit'};
  font-weight: bold;
`

export const Text = styled.p`
  color: ${theme.colors.gray[400]};
`

export const ContainerBar = styled.div`
  height: 7px;
  width: 100%;
  position: relative;
`

export const BaseBar = styled(ContainerBar)`
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 3px;
  transition: width 5s ease-in-out;
`

export const BackgroundBar = styled(BaseBar)`
  background: ${theme.colors.gray[300]};
  width: 100%;
`

export const ProgressBar = styled(BaseBar)<IProgressBarProps>`
  background: ${theme.colors.blue[200]};
  width: ${({ progress }) => `${progress}%`};
`
