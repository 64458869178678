import React from 'react'
import { IApiLogsResponse } from '../@types/providers.interface'
import { ILogHandlerResponse } from '../@types/shared.interface'
import { Card } from '../presentation/logs/Logs.style'

export const handleTableLogs = (
  logs: IApiLogsResponse,
): ILogHandlerResponse => {
  let projectName = ''

  if (!logs.content.length)
    return {
      content: [],
      pagination: {
        currentPage: 0,
        totalPages: 0,
      },
      projectName: '',
    }

  return {
    content: logs.content.map(log => {
      projectName = log.project
      return {
        id: log._id,
        project: log.project || '-',
        tenant: log.tenant || '-',
        success: <Card color="success">{log.success || 0}</Card>,
        error: <Card color="error">{log.errors || 0}</Card>,
      }
    }),
    pagination: {
      currentPage: logs.page.current,
      totalPages: logs.page.totalPages,
    },
    projectName,
  }
}
