import React, { useState } from 'react'
import * as Yup from 'yup'

import {
  Button,
  InputText,
  Modal,
  notificationError,
  notificationSuccess,
  Select,
  SelectHours,
} from '@devapi/design-system'
import {
  ICreateJobs,
  ICreateJobsPayload,
  IHours,
  ISelectsOptions,
} from './CreateJobs.interface'
import { FormikHelpers, FormikProvider, useFormik } from 'formik'
import { HiCheck } from 'react-icons/hi'

import {
  Container,
  Form,
  InputContainer,
  JobTimer,
  ButtonContainer,
  ColumForm,
  ParamsContainer,
  AceButton,
  LineForm,
} from './CreateJobs.style'
import { ISelectOptions } from '../Jobs.interface'
import { handlePayload } from '../../adapters/handlePayload'
import { createJob } from '../../providers/job.create'

import AceEditor from 'react-ace'
import 'ace-builds/src-noconflict/theme-monokai'
import { findRepository } from '../../providers/repository.find'
import { handleRepositoryId } from '../../adapters/handleRepositoryId'
import { handleRepositorySelect } from '../../adapters/handleRepositorySelect'

const CreateJobs = ({
  isOpen,
  onClickCloseModal,
  onSuccess,
  projectOptions,
  tenantOptions,
}: ICreateJobs) => {
  const [selects, setSelects] = useState({} as ISelectsOptions)
  const [isAceEditor, setIsAceEditor] = useState(false)
  const [codeParams, setCodeParams] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)
  const [automationOptions, setAutomationOptions] = useState([
    {
      label: '',
      value: '',
    },
  ])

  const schema = Yup.object().shape({
    name: Yup.string().required('Nome obrigatório'),
    time: Yup.number()
      .positive('Deve ser um número')
      .required('Tempo obrigatório'),
    automation: Yup.string().required('Campo obrigatório'),
    project: Yup.string().required('Campo obrigatório'),
    tenant: Yup.string().required('Campo obrigatório'),
    timerType: Yup.string().required('Campo obrigatório'),
  })

  const onSubmit = async (
    values: ICreateJobsPayload,
    { resetForm }: FormikHelpers<ICreateJobsPayload>,
  ) => {
    try {
      setIsLoading(true)
      const { data } = await findRepository(values.project)

      const id = handleRepositoryId(data)
      const payload = handlePayload(values, id)

      await createJob(payload)
      onSuccess()
      onClickCloseModal()

      notificationSuccess({
        message: 'Job criado com sucesso.',
        position: 'top-right',
      })

      resetForm()
    } catch (error) {
      notificationError({
        message:
          error.response?.data?.message || 'Não foi possível criar o job.',
        position: 'top-right',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      project: '',
      automation: '',
      tenant: '',
      time: '',
      hours: '',
      timerType: 'HOURS',
      codeParams: '',
    },
    validationSchema: schema,
    onSubmit,
  })

  const timerOptions = [
    {
      value: 'MINUTES',
      label: 'Minutos',
    },
    {
      value: 'HOURS',
      label: 'Horas',
    },
    {
      value: 'DAYS',
      label: 'Dias',
    },
  ]

  const handleSelectedTimer = (element: ISelectOptions) => {
    setSelects({ ...selects, timer: element.value })
  }

  const handleSelectedAutomation = (element: ISelectOptions) => {
    setSelects({ ...selects, automation: element.value })
  }

  const findAutomation = async (projectId: string) => {
    try {
      const { data } = await findRepository(projectId)
      setAutomationOptions(handleRepositorySelect(data))
      setIsDisabled(false)
    } catch (error) {
      setIsDisabled(true)
      notificationError({
        message:
          error.response?.data?.message ||
          'Não foi possível carregar as automações.',
        position: 'top-right',
      })
    }
  }

  const handleSelectedProject = (element: ISelectOptions) => {
    setSelects({ ...selects, project: element.value })
    findAutomation(element.value)
  }

  const handleSelectedTenant = (element: ISelectOptions) => {
    setSelects({ ...selects, tenant: element.value })
  }

  const handleSelectedHours = (element: IHours) => {
    setSelects({ ...selects, hours: element })
    formik.setFieldValue('hours', `${element.hour}:${element.minute}`)
  }

  const handleParams = (params: string) => {
    setCodeParams(params)
  }

  const handleOpenAce = () => {
    setIsAceEditor(true)
  }

  const handleClickCancelOnAce = () => {
    setIsAceEditor(false)
  }

  const handleConfirmParams = () => {
    try {
      JSON.parse(codeParams)
      setIsAceEditor(false)
      formik.setFieldValue('codeParams', codeParams)
      notificationSuccess({
        message: 'Os parâmetros foram salvos com sucesso',
        position: 'top-right',
      })
    } catch (error) {
      notificationError({
        message:
          error.response?.data?.message ||
          'Os parâmetros devem estar no formato JSON',
        position: 'top-right',
      })
    }
  }

  return (
    <Modal isOpen={isOpen} title="Novo job" onClick={onClickCloseModal}>
      <Container>
        {!isAceEditor ? (
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit}>
              <InputContainer>
                <LineForm height="75px">
                  <ColumForm padding="10px 5px 0px 0px">
                    <InputText
                      isFormik
                      name="name"
                      label="Nome do job"
                      placeHolder="Digite um nome"
                      onChange={formik.handleChange}
                      value={formik.values.name}
                      errorForm={!!formik.errors.name}
                      errorMessage={formik.errors.name}
                    />
                  </ColumForm>
                  <ColumForm padding="10px 0px 0px 5px">
                    <Select
                      isFormik
                      name="project"
                      label="Selecione o projeto"
                      defaultValue="Nenhum"
                      optionList={projectOptions}
                      onClick={handleSelectedProject}
                      isErrorForm={!!formik.errors.project}
                      errorMessage={formik.errors.project}
                      isLabelBold={true}
                    />
                  </ColumForm>
                </LineForm>
                <LineForm height="75px">
                  <ColumForm padding="10px 5px 0px 0px">
                    <Select
                      isFormik
                      name="automation"
                      label="Selecione a automação"
                      defaultValue="Nenhum"
                      optionList={automationOptions}
                      onClick={handleSelectedAutomation}
                      isErrorForm={!!formik.errors.automation}
                      errorMessage={formik.errors.automation}
                      isDisable={isDisabled}
                      isLabelBold={true}
                    />
                  </ColumForm>
                  <ColumForm padding="10px 0px 0px 5px">
                    <Select
                      isFormik
                      name="tenant"
                      label="Selecione o tenant"
                      defaultValue="Nenhum"
                      optionList={tenantOptions}
                      onClick={handleSelectedTenant}
                      isErrorForm={!!formik.errors.tenant}
                      errorMessage={formik.errors.tenant}
                      isLabelBold={true}
                    />
                  </ColumForm>
                </LineForm>
                <LineForm height="76px">
                  <ColumForm padding="10px 5px 0px 0px">
                    <JobTimer>
                      <InputText
                        isFormik
                        name="time"
                        label="Meu job executará em"
                        type="text"
                        placeHolder="Tempo"
                        onChange={formik.handleChange}
                        value={formik.values.time}
                        errorForm={!!formik.errors.time}
                        errorMessage={formik.errors.time}
                        fieldSize="50%"
                      />
                      <Select
                        isFormik
                        name="timerType"
                        defaultValue="Horas"
                        optionList={timerOptions}
                        onClick={handleSelectedTimer}
                        isErrorForm={!!formik.errors.timerType}
                        errorMessage={formik.errors.timerType}
                        margin="10px 0px 0px 5px"
                      />
                    </JobTimer>
                  </ColumForm>
                  <ColumForm padding="10px 0px 0px 5px">
                    {selects.timer === 'DAYS' && (
                      <SelectHours
                        label="Executar em"
                        defaultValue={{
                          hour: selects.hours?.hour || '',
                          minute: selects.hours?.minute || '',
                        }}
                        width="50%"
                        onChange={handleSelectedHours}
                      />
                    )}
                  </ColumForm>
                </LineForm>
                <LineForm height="50px">
                  <ColumForm padding="10px 0px 0px 0px">
                    <AceButton onClick={handleOpenAce}>
                      <span>Inserir parâmetros customizados {`</>`}</span>
                    </AceButton>
                  </ColumForm>
                </LineForm>
              </InputContainer>
              <ButtonContainer isOpenAce={isAceEditor}>
                <Button
                  role="button-save"
                  colorType="green"
                  variant="default"
                  text={isLoading ? 'Enviando job...' : 'Salvar job'}
                  icon={<HiCheck color="white" size={20} />}
                  type="submit"
                  isDisabled={isLoading}
                />
              </ButtonContainer>
            </Form>
          </FormikProvider>
        ) : (
          <ParamsContainer>
            <AceEditor
              fontSize={16}
              width="699px"
              height="400px"
              value={codeParams}
              mode="json"
              theme="monokai"
              onChange={handleParams}
              debounceChangePeriod={700}
              setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                showLineNumbers: true,
              }}
              showPrintMargin={true}
              showGutter={true}
              highlightActiveLine={false}
            />

            <ButtonContainer margin="15px 0 0 0" isOpenAce={isAceEditor}>
              <Button
                colorType="neutral"
                variant="outlined"
                text="Cancelar"
                onClick={handleClickCancelOnAce}
              />
              <Button
                colorType="green"
                variant="default"
                text="Salvar parâmetros"
                icon={<HiCheck color="white" size={20} />}
                onClick={handleConfirmParams}
              />
            </ButtonContainer>
          </ParamsContainer>
        )}
      </Container>
    </Modal>
  )
}

export { CreateJobs }
