import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
`

export const TitleContainer = styled.div`
  width: 100%;
`

export const ListContainer = styled.div`
  padding: 50px;
`

export const PaginationContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 16px;
`
