import api from 'src/services/api'
import {
  ICreateTenantPayload,
  ITenantResponse,
} from '../@types/provider.interface'

export const createTenant = async ({
  description,
  name,
}: ICreateTenantPayload) => {
  return await api.post<ITenantResponse>('/tenants', {
    name,
    description,
    tags: [],
  })
}
