import api from 'src/services/api'

export const findAuthorizationsById = async (
  authorizationId: string,
  connectorId: string,
) => {
  return await api.get(
    `/connectors/${connectorId}/authorizations/${authorizationId}`,
  )
}
