import React, { useEffect, useState } from 'react'
import { AiOutlineGlobal } from 'react-icons/ai'
import { MdArrowDropDown } from 'react-icons/md'
import { FiSettings, FiLogOut } from 'react-icons/fi'
import AvatarImageGeneric from '../../assets/images/generic-avatar.svg'
import jwt from 'jsonwebtoken'
import { useAuth } from 'src/context/Auth'

import {
  Container,
  PlanAlert,
  OptionsHeaderContainer,
  OptionsHeader,
  AvatarIcon,
  UseTermsLink,
  PlanUpdateButton,
  MenuDropdow,
  LanguageOption,
  UserNameEmail,
  UserName,
  UserEmail,
  OptionButtonPerfil,
  OptionButtonLogout,
} from './Header.style'
import { IHeaders } from './Header.interface'
import { ITokenData } from 'src/@types/token.interface'
import api from 'src/services/api'
import {
  Loading,
  notificationError,
  notificationSuccess,
} from '@devapi/design-system'

export const Header = ({ redirectRoute }: IHeaders) => {
  const { accessToken } = useAuth()

  const [token, setToken] = useState(accessToken)

  const tokenDecoded = jwt.decode(token) as ITokenData

  const LanguageOptions = ['PT-BR']

  const [isOpenMenuLanguageDropdown, setIsOpenMenuLanguageDropdown] = useState(
    process.env.NODE_ENV === 'test' || false,
  )
  const [isOpenMenuPerfilDropdown, setIsOpenMenuPerfilDropdown] = useState(
    process.env.NODE_ENV === 'test' || false,
  )
  const [typeMenuDropdown, setTypeMenuDropdown] = useState('')
  const [fileAvatarData, setFileAvatarData] = useState(
    tokenDecoded.user_info.avatar || AvatarImageGeneric,
  )
  const [isLoading, setIsLoading] = useState(false)

  const menuLanguageDropdown = () => {
    if (isOpenMenuPerfilDropdown) setIsOpenMenuPerfilDropdown(false)

    setIsOpenMenuLanguageDropdown(!isOpenMenuLanguageDropdown)
    setTypeMenuDropdown('languages')
  }

  const perfilMenuDropdown = () => {
    if (isOpenMenuLanguageDropdown) setIsOpenMenuLanguageDropdown(false)

    setIsOpenMenuPerfilDropdown(!isOpenMenuPerfilDropdown)
    setTypeMenuDropdown('perfil')
  }

  const handleClickLogout = () => {
    localStorage.clear()
    window.location.pathname = '/'
  }

  const handleClickProfile = () => {
    setIsOpenMenuPerfilDropdown(!isOpenMenuPerfilDropdown)
    redirectRoute('/profile/edit')
  }

  const daysRemaining = tokenDecoded.company_plan.days_remaining || 0
  const updatePlan = async () => {
    setIsLoading(true)

    return api
      .post('/companies/user/updateplan')
      .then(() => {
        notificationSuccess({
          message: 'Recebemos sua solicitação, aguarde entrarmos em contato',
          position: 'top-right',
        })
      })
      .catch(() => {
        notificationError({
          message: 'Não foi possível atualizar o plano',
          position: 'top-right',
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  document
    .getElementById('profile-dropdown-menu')
    ?.addEventListener('mouseleave', () => {
      if (isOpenMenuPerfilDropdown) setIsOpenMenuPerfilDropdown(false)
    })

  document
    .getElementById('language-dropdown-menu')
    ?.addEventListener('mouseleave', () => {
      if (isOpenMenuLanguageDropdown) setIsOpenMenuLanguageDropdown(false)
    })

  useEffect(() => {
    setToken(accessToken)
    if (tokenDecoded.user_info.avatar) {
      setFileAvatarData(tokenDecoded.user_info.avatar)
    }
  }, [accessToken, tokenDecoded.user_info.avatar, tokenDecoded])

  return (
    <>
      <Loading isOpen={isLoading} text="Solicitando atualização..." />
      <Container>
        {tokenDecoded.company_plan.plan_type === 'FREE' && (
          <PlanAlert>
            Você tem {daysRemaining} dias restantes no seu teste.
            <PlanUpdateButton onClick={() => updatePlan()}>
              Atualize plano
            </PlanUpdateButton>
          </PlanAlert>
        )}
        <OptionsHeaderContainer>
          <OptionsHeader isBorderRight={true}>
            <UseTermsLink href="https://www.devapi.com.br/termos-de-uso">
              Termos de uso
            </UseTermsLink>
          </OptionsHeader>
          <OptionsHeader isBorderRight={true} onClick={menuLanguageDropdown}>
            <AiOutlineGlobal size="20px" style={{ marginRight: '7px' }} />
            PT-BR
            <MdArrowDropDown size="20px" />
          </OptionsHeader>
          <OptionsHeader isBorderRight={false} onClick={perfilMenuDropdown}>
            <AvatarIcon imageAvatarUrl={fileAvatarData} />
          </OptionsHeader>
        </OptionsHeaderContainer>
      </Container>
      {isOpenMenuLanguageDropdown && (
        <MenuDropdow type={typeMenuDropdown} id="language-dropdown-menu">
          {LanguageOptions.map((option, index) => (
            <LanguageOption key={index}>{option}</LanguageOption>
          ))}
        </MenuDropdow>
      )}
      {isOpenMenuPerfilDropdown && (
        <MenuDropdow type={typeMenuDropdown} id="profile-dropdown-menu">
          <UserNameEmail>
            <UserName>{tokenDecoded.user_info.name}</UserName>
            <UserEmail>{tokenDecoded.user_info.email}</UserEmail>
          </UserNameEmail>
          <div>
            <OptionButtonPerfil
              data-testid="header-btn-profile"
              onClick={handleClickProfile}
            >
              <FiSettings size="16px" />
              Perfil
            </OptionButtonPerfil>
            <OptionButtonLogout
              data-testid="header-btn-logout"
              onClick={handleClickLogout}
            >
              <FiLogOut size="16px" />
              Sair
            </OptionButtonLogout>
          </div>
        </MenuDropdow>
      )}
    </>
  )
}
