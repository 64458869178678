import { useEffect, useState } from 'react'
import jwt from 'jsonwebtoken'
import { ITokenData } from './UseTokenData.interface'

const UseTokenData = () => {
  const [tokenData, setTokenData] = useState<ITokenData>({} as ITokenData)

  useEffect(() => {
    const token = window.localStorage.getItem('accessToken')

    if (token) {
      const tokenData = jwt.decode(token) as ITokenData
      setTokenData(tokenData)
    }
  }, [])

  return tokenData
}

export default UseTokenData
