import React from 'react'
import { Button, InputText, Select } from '@devapi/design-system'
import {
  FiArrowLeft,
  FiArrowRight,
  FiPlusCircle,
  FiTrash2,
} from 'react-icons/fi'
import { FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup'

import theme from 'src/assets/styles/theme'
import {
  NoParamsContainer,
  NoParamsText,
  ParamsContainer,
  ParamsActions,
  ParamsButtons,
  FormContainer,
  InputContainer,
  DeleteContainer,
} from './DefaultParametersForm.style'
import { IDefaultParametersFormProps, ISelectOptions } from '../../@types'

const DefaultParametersForm = ({
  handleSetDefaultParameters,
  defaultParametersValue,
  handleChangeStep,
  handleClose,
  privacy,
}: IDefaultParametersFormProps) => {
  const schema = Yup.object().shape({
    fields: Yup.array().of(
      Yup.object().shape({
        type: Yup.string(),
        name: Yup.string().required('Nome é obrigatório'),
        value: Yup.string().required('Valor é obrigatório'),
      }),
    ),
  })

  const paramsOptions = [
    {
      label: 'QueryString',
      value: 'querystring',
    },
    {
      label: 'Header',
      value: 'header',
    },
  ]

  const onSubmit = values => {
    handleSetDefaultParameters(values.fields)
  }

  const formik = useFormik({
    validateOnMount: false,
    initialValues: {
      fields: defaultParametersValue,
    },
    validationSchema: schema,
    onSubmit,
  })

  const handleAddInput = () => {
    const formikValues = formik.values
    formikValues.fields.push({ type: '', name: '', value: '' })
    formik.setValues(formikValues)
  }

  const handleDelete = (idx: number) => {
    const formikValues = formik.values
    formikValues.fields.splice(idx, 1)
    formik.setValues(formikValues)
  }

  const handleSelectedType = (element: ISelectOptions, name: string) => {
    formik.setFieldValue(name, element.value.toUpperCase())
  }

  const handlePrevStep = () => {
    handleSetDefaultParameters(formik.values.fields)
    handleChangeStep('autentication')
  }
  return (
    <ParamsContainer>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          {!formik.values.fields.length ? (
            <NoParamsContainer>
              <NoParamsText>Não há parâmetros padrão cadastrado.</NoParamsText>
              <Button
                colorType="green"
                type="button"
                variant="default"
                text="Adicionar parâmetros padrão"
                icon={<FiPlusCircle />}
                iconPosition="left"
                onClick={handleAddInput}
                isDisabled={privacy === 'PUBLIC'}
              />
            </NoParamsContainer>
          ) : (
            <>
              <Button
                colorType="green"
                type="button"
                variant="default"
                text="Adicionar parâmetros padrão"
                icon={<FiPlusCircle />}
                iconPosition="left"
                onClick={handleAddInput}
                isDisabled={privacy === 'PUBLIC'}
              />
              {formik.values.fields.map((field, index) => {
                const _prefix = `[[fields][${index}]`
                return (
                  <FormContainer key={index}>
                    <InputContainer>
                      <Select
                        label="Tipo"
                        name={`${_prefix}[type]`}
                        value={field.type}
                        optionList={paramsOptions}
                        isDisable={privacy === 'PUBLIC'}
                        onClick={(element: ISelectOptions) =>
                          handleSelectedType(element, `${_prefix}[type]`)
                        }
                        isLabelBold={true}
                      />
                    </InputContainer>
                    <InputContainer>
                      <InputText
                        isFormik
                        name={`${_prefix}[name]`}
                        label="Nome *"
                        value={field.name}
                        onChange={formik.handleChange}
                        disabled={privacy === 'PUBLIC'}
                      />
                    </InputContainer>
                    <InputContainer>
                      <InputText
                        isFormik
                        name={`${_prefix}[value]`}
                        label="Valor *"
                        value={field.value}
                        onChange={formik.handleChange}
                        disabled={privacy === 'PUBLIC'}
                      />
                    </InputContainer>
                    {privacy === 'PRIVATE' && (
                      <DeleteContainer
                        onClick={() => handleDelete(index)}
                        data-testid="button-delete"
                      >
                        <FiTrash2 size="16px" color={theme.colors.red} />
                      </DeleteContainer>
                    )}
                  </FormContainer>
                )
              })}
            </>
          )}
          <ParamsActions>
            <Button
              text="Cancelar"
              variant="outlined"
              colorType="neutral"
              type="button"
              onClick={handleClose}
            />
            <ParamsButtons>
              <Button
                text="Anterior"
                variant="default"
                colorType="green"
                type="button"
                icon={<FiArrowLeft />}
                iconPosition="left"
                onClick={handlePrevStep}
              />
              <Button
                text="Próximo"
                variant="default"
                colorType="green"
                type="submit"
                icon={<FiArrowRight />}
                iconPosition="right"
              />
            </ParamsButtons>
          </ParamsActions>
        </form>
      </FormikProvider>
    </ParamsContainer>
  )
}

export { DefaultParametersForm }
