import {
  IDashboardDataResponse,
  ISerializedDashboardData,
} from '../@types/shared.interface'
import { serializeKeysAndValues } from '../utils'

export const serializeDashboardData = (
  dashboardResponse: IDashboardDataResponse,
): ISerializedDashboardData => {
  const { apis, automations, jobs, plan, tenants } = dashboardResponse

  return {
    apis: {
      consumption: serializeKeysAndValues(apis.consumption, {
        key: 'date',
      }),
      dailyData: {
        errors: serializeKeysAndValues(apis.daily_data.errors, {
          key: 'date',
        }),
        successes: serializeKeysAndValues(apis.daily_data.successes, {
          key: 'date',
        }),
        total: serializeKeysAndValues(apis.daily_data.total, {
          key: 'date',
        }),
      },
      total: apis.total,
      totalErrors: apis.total_errors,
      totalSuccesses: apis.total_sucess,
    },
    automations: {
      total: automations.total,
    },
    jobs: {
      consumption: serializeKeysAndValues(jobs.consumption, {
        key: 'date',
      }),
      dailyData: {
        errors: serializeKeysAndValues(jobs.daily_data.errors, {
          key: 'date',
        }),
        successes: serializeKeysAndValues(jobs.daily_data.successes, {
          key: 'date',
        }),
        total: serializeKeysAndValues(jobs.daily_data.total, {
          key: 'date',
        }),
      },
      total: jobs.total,
      totalErrors: jobs.total_errors,
      totalSuccesses: jobs.total_sucess,
    },
    plan: {
      current: plan.current,
      max: plan.max,
    },
    tenants: {
      topTenants: serializeKeysAndValues(tenants.top_tenants, {
        key: 'name',
      }),
      total: tenants.total,
    },
  }
}
