/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { BsArrowClockwise, BsClock, BsPencil, BsTrash } from 'react-icons/bs'
import { DiVisualstudio } from 'react-icons/di'
import {
  Button,
  InputSearch,
  Loading,
  notificationError,
  Pagination,
  Table,
} from '@devapi/design-system'
import { findAllApi } from '../providers'
import { InternalHeader } from 'src/components/InternalHeader'
import { Content, Container, TableContainer } from './ApiManagment.style'
import { IPaginationState, IFiltersState } from './ApiManagement.interface'
import theme from 'src/assets/styles/theme'
import { handleTableApi } from '../adapters/handleTableApi'
import { CreateApi } from './create'
import { DeleteApiManagement } from './delete'
import { EditApi } from './edit'
import { IApiSerialized } from '../@types/shared.interface'
import { FiUsers } from 'react-icons/fi'

const ApiManagement = () => {
  const [apis, setApis] = useState<IApiSerialized[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [pagination, setPagination] = useState({} as IPaginationState)
  const [filters, setFilters] = useState({} as IFiltersState)
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false)
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
  const [isOpenEditModal, setIsOpenEditModal] = useState(false)
  const [responsive, setResponsive] = useState(false)
  const [editingApi, setEditingApi] = useState({} as IApiSerialized)
  const [apiIdToDelete, setApiIdToDelete] = useState('')

  const history = useHistory()

  const width = window.innerWidth
  const crumbs = [{ label: 'API Management', value: 'apis' }]
  const columns = [
    { key: 'name', label: 'Nome', isOrder: true, columnWidth: '200px' },
    { key: 'docUrl', label: 'Doc URL', columnWidth: '100px' },
    { key: 'version', label: 'Versão', isOrder: true, columnWidth: '100px' },
    {
      key: 'statusDeploy',
      label: 'Status Deploy',
      isOrder: true,
      columnWidth: '140px',
    },
    { key: 'opt', label: 'Opções', columnWidth: '90px' },
  ]

  const handleSearchTerm = (searchTerm: string) => {
    setFilters({ ...filters, searchTerm: searchTerm })
  }

  const filter = [
    {
      field: (
        <InputSearch
          label="Encontrar API"
          placeHolder="Pesquisa"
          onChange={handleSearchTerm}
          fieldSize={responsive ? '250px' : '320px'}
          defaultValue={filters.searchTerm || ''}
          isLabelBold={false}
        />
      ),
    },
  ]

  const handleOrderBy = (colum: string) => {
    const validOrderBy = {
      name: 'name',
      version: 'version',
      statusDeploy: 'statusDeploy',
    }

    setFilters({ ...filters, orderBy: validOrderBy[colum] })
  }

  useEffect(() => {
    if (width <= 1366) {
      setResponsive(true)
    } else {
      setResponsive(false)
    }
  }, [width])

  const handleFindAllApis = async () => {
    try {
      setIsLoading(true)
      const response = await findAllApi(pagination.currentPage, filters)
      const { content, page } = response.data
      const formattedApi = handleTableApi(content)
      setApis(formattedApi)
      setPagination({
        ...pagination,
        currentPage: page.current,
        totalPages: page.totalPages,
      })
    } catch (error) {
      notificationError({
        message:
          error.response?.data?.message || 'Não foi possível carregar as APIS',
        position: 'top-right',
      })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    handleFindAllApis()
    return () => {
      setIsLoading(false)
    }
  }, [pagination.currentPage, filters])

  const handleToggleCreateModal = () => {
    setIsOpenCreateModal(!isOpenCreateModal)
  }

  const handleToggleDeleteModal = () => {
    setIsOpenDeleteModal(!isOpenDeleteModal)
  }

  const handleToggleEditModal = () => {
    setIsOpenEditModal(!isOpenEditModal)
  }

  const handleEditApi = itemRow => {
    const findApi = apis.find(api => api.id === itemRow.id)
    if (findApi) {
      setIsOpenEditModal(true)
      setEditingApi(findApi)
    }
  }

  const handleFindPermissionsByApiId = itemRow => {
    const { id } = itemRow
    history.push(`/apis/${id}/permissions`)
  }

  const handleOpenDevStudio = itemRow => {
    const { id } = itemRow
    window.location.pathname = `/devstudio/api/${id}`
  }

  const deleteApiById = itemRow => {
    const findApis = apis.find(item => item.id === itemRow.id)
    if (findApis && findApis.id) {
      setApiIdToDelete(findApis.id)
      setIsOpenDeleteModal(true)
    }
  }

  const handleChangePage = (page: number) => {
    setPagination({
      ...pagination,
      currentPage: page,
    })
  }

  const viewLogs = itemRow => {
    const { id } = itemRow
    history.push(`/apis/${id}/logs`)
  }

  return (
    <Container>
      <InternalHeader
        filters={filter}
        crumbs={crumbs}
        buttonText="Nova API"
        onClick={handleToggleCreateModal}
      />
      <Loading isOpen={isLoading} text="Buscando Apis..." />
      {!isLoading ? (
        <Content>
          <TableContainer>
            <Table
              tableData={apis}
              tableHeaderOptions={columns}
              noContentText="Nenhum resultado encontrado."
              orderBy={['name', 'version', 'statusDeploy']}
              sortTableData={handleOrderBy}
              optionsActions={[
                <BsClock
                  {...theme.icon}
                  color={theme.colors.green}
                  onClick={viewLogs}
                />,
                <FiUsers
                  {...theme.icon}
                  onClick={handleFindPermissionsByApiId}
                />,
                <DiVisualstudio
                  {...theme.icon}
                  color={theme.colors.primary}
                  onClick={handleOpenDevStudio}
                />,
                <BsPencil
                  {...theme.icon}
                  color={theme.colors.primary}
                  onClick={handleEditApi}
                />,
                <BsTrash
                  size={20}
                  color={theme.colors.primary}
                  style={{ marginBottom: '-6px' }}
                  onClick={deleteApiById}
                />,
              ]}
              buttonReload={
                <Button
                  colorType="green"
                  variant="outlined"
                  text="Recarregar"
                  icon={<BsArrowClockwise />}
                  onClick={handleFindAllApis}
                />
              }
            />
          </TableContainer>
          {pagination.totalPages > 1 && (
            <Pagination
              size="default"
              totalPages={pagination.totalPages}
              page={pagination.currentPage}
              onChange={(page: number) => handleChangePage(page)}
            />
          )}
        </Content>
      ) : (
        ''
      )}

      <DeleteApiManagement
        isOpen={isOpenDeleteModal}
        onClose={handleToggleDeleteModal}
        onSuccess={handleFindAllApis}
        apiId={apiIdToDelete}
      />
      <CreateApi isOpen={isOpenCreateModal} onClose={handleToggleCreateModal} />
      <EditApi
        onSuccess={handleFindAllApis}
        isOpen={isOpenEditModal}
        onClose={handleToggleEditModal}
        editingApi={editingApi}
      />
    </Container>
  )
}

export { ApiManagement }
