import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import {
  Loading,
  notificationError,
  notificationSuccess,
} from '@devapi/design-system'
import { IEmailConfirmtaionRoutePrams } from './EmailConfirmation.interface'
import api from 'src/services/api'

const EmailConfirmation = () => {
  const [isLoading, setIsLoading] = useState(true)
  const { token } = useParams<IEmailConfirmtaionRoutePrams>()
  const history = useHistory()

  useEffect(() => {
    const handleConfirmEmail = async () => {
      try {
        await api.post('/auth/email-confirmation', { token })

        notificationSuccess({
          message: 'Email confirmado com sucesso',
          position: 'top-right',
        })
      } catch (error) {
        notificationError({
          message:
            error.response?.data?.message ||
            'Não foi possivel confirmar email.',
          position: 'top-right',
        })
      } finally {
        history.push('/')
        setIsLoading(false)
      }
    }
    handleConfirmEmail()
  }, [token, history])

  return <Loading isOpen={isLoading} text="Confirmando email..." />
}

export { EmailConfirmation }
