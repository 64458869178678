import React from 'react'
import { Switch } from 'react-router-dom'

import Route from './Route'

import { Profile } from '../modules/Profile/Profile'
import { SignIn } from '../modules/Auth/SignIn'
import { ForgotPassword } from '../modules/Auth/ForgotPassword'
import { RouterContainer } from './Route.style'
import { UnconfirmedEmail } from 'src/modules/Auth/UnconfirmedEmail'
import { EmailConfirmation } from 'src/modules/Hooks/EmailConfirmation'
import { Dashboard } from 'src/modules/Dashboard'
import { RecoveryPassword } from 'src/modules/Auth/RecoveryPassword'
import { Tenants } from 'src/modules/Tenants'
import { Users } from 'src/modules/Users'
import { Jobs } from 'src/modules/Jobs/presentation'
import { Automations } from 'src/modules/Automations'
import { ApiManagement, Permissions } from 'src/modules/ApiManagement'
import {
  CreateConnectorForm,
  Connectors,
  CreateConnector,
} from 'src/modules/Connectors'
import { JobLogs } from 'src/modules/Jobs/presentation/logs'
import { EditConnector } from 'src/modules/Connectors/presentation/edit'
import { ApiLogs } from 'src/modules/ApiManagement/presentation/logs'

const Routes = () => (
  <>
    <RouterContainer>
      <Switch>
        <Route path="/" exact component={SignIn} />
        <Route path="/profile" component={Profile} isPrivate />
        <Route path="/dashboard" exact component={Dashboard} isPrivate />
        <Route path="/users" component={Users} isPrivate />
        <Route path="/forgot-password" exact component={ForgotPassword} />
        <Route path="/unconfirmed-email" component={UnconfirmedEmail} />
        <Route
          path="/pt/hooks/email-confirmation/:token"
          component={EmailConfirmation}
        />
        <Route path="/recovery-password/:token" component={RecoveryPassword} />
        <Route path="/tenants" component={Tenants} isPrivate />
        <Route path="/ipaas/jobs" exact component={Jobs} isPrivate />
        <Route
          path="/ipaas/jobs/:id/logs"
          exact
          component={JobLogs}
          isPrivate
        />
        <Route
          path="/ipaas/automations"
          exact
          component={Automations}
          isPrivate
        />
        <Route path="/apis" exact component={ApiManagement} isPrivate />
        <Route
          path="/apis/:id/permissions"
          exact
          component={Permissions}
          isPrivate
        />
        <Route path="/apis/:id/logs" exact component={ApiLogs} isPrivate />
        <Route path="/connectors" exact component={Connectors} isPrivate />
        <Route
          path="/connectors/new-connector"
          exact
          component={CreateConnector}
          isPrivate
        />
        <Route
          path="/connectors/:id"
          exact
          component={EditConnector}
          isPrivate
        />
        <Route
          path="/connectors/new-connector/constructor"
          exact
          component={CreateConnectorForm}
          isPrivate
        />
      </Switch>
    </RouterContainer>
  </>
)

export default Routes
