import React from 'react'
import { FaUser } from 'react-icons/fa'
import { useHistory } from 'react-router'
import { IConnectorRedirectCardProps } from './ConnectorRedirectCard.interface'
import {
  Container,
  Logo,
  Label,
  LogoContainer,
  IconContainer,
  Icon,
} from './ConnectorRedirectCard.style'
import logoDevapi from 'src/assets/images/generic-connector-logo.svg'

export const ConnectorRedirectCard = ({
  imageUrl,
  label,
  redirectPath,
  isPrivate,
}: IConnectorRedirectCardProps) => {
  const history = useHistory()
  const handleRedirect = () => {
    history.push(redirectPath)
  }

  const logo = imageUrl || logoDevapi

  return (
    <Container onClick={handleRedirect}>
      <IconContainer>
        {isPrivate && (
          <Icon>
            <FaUser />
          </Icon>
        )}
      </IconContainer>
      <LogoContainer>
        <Logo src={logo} data-testid="connector-logo" />
      </LogoContainer>
      <Label>{label}</Label>
    </Container>
  )
}
