import theme from 'src/assets/styles/theme'
import styled from 'styled-components'

export const ParamsContainer = styled.div`
  margin-top: 30px;
  max-height: 100%;
`
export const NoParamsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 70px;
`

export const NoParamsText = styled.h2`
  color: ${theme.colors.gray[300]};
  font-size: 26px;
  margin-bottom: 25px;
`

export const ParamsActions = styled.div`
  padding-top: 10vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ParamsButtons = styled.div`
  display: flex;
  button {
    margin-left: 15px;
  }
`

export const FormContainer = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  margin-top: 30px;
`

export const InputContainer = styled.div`
  margin-right: 20px;
`

export const DeleteContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 58px;
  width: 48px;
  padding-top: 12px;
`
