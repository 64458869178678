import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import jwt from 'jsonwebtoken'
import { BsUpload } from 'react-icons/bs'

import {
  Container,
  UserAvatar,
  TitlePage,
  MediaUploadContainer,
  SizeFileText,
  ButtonContainer,
  FormRow,
  UploadField,
  FormSpacer,
} from './EditProfile.style'
import { ITokenData, IFormData } from './EditProfile.interface'
import AvatarImageGeneric from '../../../assets/images/generic-avatar.svg'

import { FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup'
import {
  Button,
  InputText,
  InputPhone,
  notificationError,
  notificationSuccess,
  Loading,
} from '@devapi/design-system'
import api from 'src/services/api'
import { useAuth } from 'src/context/Auth'

export const EditProfile = () => {
  const { accessToken, refreshToken } = useAuth()
  const tokenDecoded = jwt.decode(accessToken) as ITokenData

  const [isLoading, setIsLoading] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [userCompletePhone, setUserCompletePhone] = useState('')
  const [fileName, setFileName] = useState('')
  const [fileAvatarData, setFileAvatarData] = useState(
    tokenDecoded.user_info.avatar || AvatarImageGeneric,
  )

  const countryCode = useRef('')
  const areaCode = useRef('')
  const numberPhone = useRef('')
  const [phoneNumerHasError, setPhoneNumberHasError] = useState(false)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const uploadRef = useRef(null) as any

  const handleChange = (value: string) => {
    if (!value) return setPhoneNumberHasError(true)
    setPhoneNumberHasError(false)

    const country = value.substring(0, 2)
    const area = value.substring(2, 4)
    const phone = value.substring(4, 13)

    countryCode.current = `+${country}`
    areaCode.current = area
    numberPhone.current = phone
  }

  const onSubmit = async (form: IFormData) => {
    if (phoneNumerHasError) return null

    setIsSaving(true)

    const formData = new FormData()
    const fields = [
      'name',
      'surname',
      'email',
      'country_code',
      'area_code',
      'phone',
      'avatar',
    ]

    form.country_code = countryCode.current
    form.area_code = areaCode.current
    form.phone = numberPhone.current

    Object.keys(form).forEach(key => {
      if (fields.includes(key)) formData.append(key, form[key])
    })

    try {
      const { data } = await api.put(
        `/user/profile/${tokenDecoded.company_user_id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      if (refreshToken) refreshToken(data)
      notificationSuccess({
        message: 'Perfil atualizado com sucesso!',
        position: 'top-right',
      })
    } catch (error) {
      notificationError({
        message: 'Erro ao atualizar perfil',
        position: 'top-right',
      })
    } finally {
      setIsLoading(false)
      setIsSaving(false)
    }
  }

  const schema = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório'),
    surname: Yup.string().required('Sobrenome é obrigatório'),
    email: Yup.string().email('Email inválido').required('Email é obrigatório'),
    phone: Yup.string().test(
      'validateIfPhoneIsComplete',
      'Número incompleto',
      value => {
        return !value?.includes('_')
      },
    ),
  })

  const formik = useFormik({
    initialValues: {
      name: '',
      surname: '',
      email: '',
      area_code: '',
      country_code: '',
      phone: '',
    },
    validationSchema: schema,
    onSubmit,
  })

  const onChangeUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length) {
      const file = event.target.files[0]

      if (file.size > 400000) {
        notificationError({
          message: 'O tamanho da imagem não pode ser maior que 400kb',
          position: 'top-right',
        })
        setFileName('')
        formik.setFieldValue('avatar', null)
        return
      }
      setFileAvatarData(URL.createObjectURL(file))
      setFileName(file.name)
      formik.setFieldValue('avatar', file)
    } else formik.setFieldValue('avatar', null)
  }

  const getUserData = useCallback(async () => {
    await api
      .get(`/companies/users/${tokenDecoded.company_user_id}`)
      .then(({ data }) => {
        setUserCompletePhone(
          `${data.country_code}${data.area_code}${data.phone}`,
        )

        formik.setValues({
          name: data.name,
          surname: data.surname,
          email: data.email,
          area_code: data.area_code,
          country_code: data.country_code,
          phone: data.phone,
        })

        countryCode.current = `+${data.country_code}`
        areaCode.current = data.area_code
        numberPhone.current = data.phone
      })
      .catch(error => {
        notificationError({
          message:
            error.response?.data?.message ||
            'Erro ao consultar dados do perfil',
          position: 'top-right',
        })
      })
      .finally(() => setIsLoading(false))
  }, [tokenDecoded.company_user_id, formik])

  useEffect(() => {
    if (isLoading) getUserData()
  }, [])

  return (
    <Container>
      <Loading isOpen={isLoading} text="Carregando" />
      <Loading isOpen={isSaving} text="Salvando..." />
      {!isLoading && (
        <>
          <TitlePage>Editar perfil</TitlePage>
          <MediaUploadContainer>
            <UserAvatar
              imageAvatarUrl={fileAvatarData}
              data-testid="user-avatar"
            />
            <div>
              <SizeFileText>JPG ou PNG, tamanho máximo 400KB</SizeFileText>
              <ButtonContainer>
                <Button
                  iconPosition="left"
                  icon={<BsUpload />}
                  colorType="green"
                  variant="default"
                  text="Carregar foto"
                  onClick={() => uploadRef.current.click()}
                />
                <UploadField
                  data-testid="upload-file"
                  ref={uploadRef}
                  type="file"
                  accept="image/png, image/jpeg"
                  onChange={onChangeUpload}
                />
                <span data-testid="avatar-filename">{fileName}</span>
              </ButtonContainer>
            </div>
          </MediaUploadContainer>
          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
              <FormRow>
                <InputText
                  type="text"
                  label="Nome"
                  name="name"
                  defaultValue={formik.values.name}
                  onChange={formik.handleChange}
                  errorMessage={formik.errors.name}
                  errorForm={!!formik.errors.name}
                />
                <FormSpacer />
                <InputText
                  label="Sobrenome"
                  name="surname"
                  defaultValue={formik.values.surname}
                  onChange={formik.handleChange}
                  errorMessage={formik.errors.surname}
                  errorForm={!!formik.errors.surname}
                />
              </FormRow>
              <FormRow>
                <InputText
                  label="Email"
                  name="email"
                  value={tokenDecoded.user_info.email}
                  disabled
                />
                <FormSpacer />
                <InputPhone
                  label="Telefone"
                  onChange={handleChange}
                  value={userCompletePhone}
                  errorForm={phoneNumerHasError}
                  errorMessage="Telefone é obrigatório."
                />
              </FormRow>
              <Button
                role="button-save"
                variant="default"
                colorType="green"
                text="Salvar alterações"
                type="submit"
              />
            </form>
          </FormikProvider>
        </>
      )}
    </Container>
  )
}
