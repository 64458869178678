import React, { useState, useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { isExpiredPlan } from './../../utils/isExpiredPlan'
import { useAuth } from 'src/context/Auth'
import api from 'src/services/api'

import {
  Content,
  ButtonContainer,
  Title,
  SubTitle,
  ExpiredImage,
} from './ExpiredModal.style'
import ExpiredMascot from './../../assets/images/generic-expired.svg'
import {
  Button,
  Modal,
  notificationError,
  notificationSuccess,
} from '@devapi/design-system'

const ExpiredModal = () => {
  const history = useHistory()
  const { accessToken } = useAuth()
  const expiredModalRef = useRef(null)
  const [isOpenModal, setIsOpenModal] = useState(false)

  const handleMutationCallback = () => {
    if (!document.body.contains(expiredModalRef.current)) {
      window.location.pathname = '/dashboard'
    } else {
      if (window.location.pathname !== '/dashboard') {
        history.push('/dashboard')
      }
    }
  }

  const handleCreateObserver = () => {
    const options = { childList: true, subtree: true }
    new MutationObserver(handleMutationCallback).observe(document.body, options)
  }

  const handleExpired = () => {
    const isExpired = isExpiredPlan(accessToken)
    setIsOpenModal(isExpired)
    if (isExpired) {
      handleCreateObserver()
    }
  }

  useEffect(() => {
    handleExpired()
  }, [])

  const handleClickLogout = () => {
    localStorage.clear()
    window.location.pathname = '/'
  }

  const updatePlan = async () => {
    try {
      await api.post('/companies/user/updateplan')
      notificationSuccess({
        message: 'Recebemos sua solicitação, aguarde entraremos em contato',
        position: 'top-right',
      })
    } catch (error) {
      notificationError({
        message: 'Não foi possível atualizar o plano',
        position: 'top-right',
      })
    }
  }

  const closeModalNotification = () => {
    notificationError({
      message: 'Seu período de teste expirou!',
      position: 'top-right',
    })
  }

  return (
    <Modal isOpen={isOpenModal} title=" " onClick={closeModalNotification}>
      <Content ref={expiredModalRef}>
        <ExpiredImage imageExpired={ExpiredMascot} />
        <Title>Seu período de teste expirou!</Title>
        <SubTitle>
          Para continuar, você pode escolher um plano ideal para você falando
          com um de nossos especialistas.
        </SubTitle>

        <ButtonContainer>
          <Button
            role="btn-logout"
            colorType="neutral"
            variant="outlined"
            text="Sair"
            type="button"
            onClick={handleClickLogout}
          />
          <Button
            role="btn-submit"
            iconPosition={'left'}
            colorType="green"
            variant="default"
            text="Fale com um especialista"
            onClick={updatePlan}
          />
        </ButtonContainer>
      </Content>
    </Modal>
  )
}

export { ExpiredModal }
