import styled from 'styled-components'
import { ILimitJobStyle } from './LimitJob.interface'

export const Container = styled.div``

export const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;

  p {
    font-weight: bold;
  }
`

export const Progress = styled.div<ILimitJobStyle>`
  position: relative;
  width: 295px;
  height: 10px;

  border-radius: 5px;

  background: rgb(22, 167, 143);
  background: linear-gradient(
    90deg,
    rgba(22, 167, 143, 1) 0%,
    rgba(222, 211, 0, 1) 50%,
    rgba(236, 27, 27, 1) 100%
  );

  > div {
    right: 0%;
    position: absolute;
    width: ${props => `calc(100% - ${props.progress})`};
    height: 10px;
    background-color: #cbd5e0;
    border-radius: ${props =>
      parseInt(props.progress) === 0 ? '4px' : '0px 4px 4px 0px'};
  }
`
