import styled from 'styled-components'

import theme from '../../../assets/styles/theme'
import { IUserAvatarStyle } from './EditProfile.interface'

export const Container = styled.div`
  border: 1px solid ${theme.colors.gray[200]};
  height: 100%;
  border-radius: 10px;
  padding: 23px 1.3vw;

  @media screen and (max-width: 1366px) {
    padding: 20px 1vw;
  }
`

export const TitlePage = styled.div`
  font-weight: 700;
  font-size: 1.35rem;
  width: 100%;
  margin-bottom: 5vh;
`

export const MediaUploadContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

export const SizeFileText = styled.div`
  color: ${theme.colors.gray[350]};
  margin-bottom: 10px;
`

export const UserAvatar = styled.div<IUserAvatarStyle>`
  background-image: url(${props => props.imageAvatarUrl});
  background-color: ${theme.colors.gray[100]};
  background-size: 100%;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  margin-right: 1.3vw;
  height: 132px;
  width: 132px;
  border-radius: 50%;

  @media screen and (max-width: 1366px) {
    height: 102px;
    width: 102px;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  & > button {
    margin-right: 13px;
  }
`

export const FormRow = styled.div`
  width: 70%;
  display: flex;
  padding: 15px 0;

  @media screen and (max-width: 1366px) {
    width: 100%;
  }
`

export const FormSpacer = styled.div`
  width: 15px;
`

export const UploadField = styled.input.attrs({ type: 'file' })`
  display: none;
  border-style: none;
  outline: none;
  /* -webkit-user-select: none; */
`
