import React, { useEffect, useState } from 'react'
import { FormikProvider, useFormik } from 'formik'
import {
  Button,
  InputText,
  notificationError,
  Loading,
  InputSearch,
  Checkbox,
} from '@devapi/design-system'
import { FiCheck } from 'react-icons/fi'

import * as Yup from 'yup'

import { findAllConnectors } from 'src/modules/Automations/providers/Connectors.find'
import {
  Container,
  InputContainer,
  ModalActions,
  ConnectorsErrorMessage,
  SearchContainer,
  SearchItem,
  FormContainer,
  CheckBoxContainer,
} from './AutomationForm.style'
import {
  IPaginationResponse,
  IFindAllConnectorsParams,
} from '../../../../@types/providers.interface'
import {
  IAutomationFormProps,
  IConnectorSerialized,
  ICreateAutomationPayload,
} from './AutomationForm.interface'
import { createAutomation } from 'src/modules/Automations/providers/automations.create'
import { connectorsSerializer } from 'src/modules/Automations/adapters/ConnectorsSerializer'
import { ConnectorsList } from 'src/components/ConnectorsList'

const AutomationForm = ({ onClose }: IAutomationFormProps) => {
  const [isPrivateConnectors, setIsPrivateConnectors] = useState(false)
  const [isPublicConnectors, setIsPublicConnectors] = useState(false)
  const [params, setParams] = useState({} as IFindAllConnectorsParams)
  const [isLoading, setIsLoading] = useState(false)
  const [connectorsSelected, setConnectorsSelected] = useState<string[]>([])
  const [connectors, setConnectors] = useState<IConnectorSerialized[]>([])
  const [currentPage, setCurrentPage] = useState<number | undefined>(undefined)
  const [totalPage, setTotalPage] = useState<number | undefined>(undefined)
  const [pagination, setPagination] = useState({} as IPaginationResponse)

  const schema = Yup.object().shape({
    name: Yup.string().required('Nome Obrigatório'),
    connectors: Yup.array().min(1, 'Selecione pelo menos um conector'),
  })

  const onSubmit = async (values: ICreateAutomationPayload) => {
    try {
      setIsLoading(true)
      const response = await createAutomation({
        name: values.name,
        connectors: values.connectors,
      })
      onClose()
      window.location.pathname = `/devstudio/ipaas/${response.data._id}`
    } catch (error) {
      notificationError({
        message:
          error.response?.data?.message ||
          'Ocorreu um erro para criar a automação.',
        position: 'top-right',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      connectors: [],
    },
    validationSchema: schema,
    onSubmit,
  })

  const handleFindAllConnectors = async () => {
    try {
      const response = await findAllConnectors({
        current: pagination.current,
        params,
      })
      if (response.page.current > 1) {
        const formatedConnectors = await connectorsSerializer(response.content)
        formatedConnectors.forEach(connector => {
          setConnectors(values => [...values, connector])
        })
      } else {
        const formatedConnectors = await connectorsSerializer(response.content)

        setConnectors(formatedConnectors)
      }
      setPagination(response.page)
      setCurrentPage(response.page.current)
      setTotalPage(response.page.totalPages)
    } catch (error) {
      notificationError({
        message:
          error.response?.data?.message ||
          'Não foi possivel carregar conectores.',
        position: 'top-right',
      })
    }
  }

  useEffect(() => {
    handleFindAllConnectors()
  }, [pagination.current, params])

  const handleConector = (selected: boolean, id: string) => {
    if (selected) {
      setConnectorsSelected(oldValue => [...oldValue, id])
      formik.setFieldValue('connectors', [...connectorsSelected, id])

      const connectorIndex = connectors.findIndex(
        connector => connector.id === id,
      )
      connectors[connectorIndex].isSelected = true
      return
    }
    const updateList = connectorsSelected.filter(connector => connector !== id)

    const connectorIndex = connectors.findIndex(
      connector => connector.id === id,
    )
    connectors[connectorIndex].isSelected = false
    setConnectorsSelected(updateList)
    formik.setFieldValue('connectors', updateList)
  }

  const handleCheckPrivacyPrivate = () => {
    setIsPrivateConnectors(!isPrivateConnectors)
    setParams(values => {
      return { ...values, privacy: isPrivateConnectors ? '' : 'PRIVATE' }
    })
    setPagination(values => {
      return { ...values, current: 1 }
    })
    setIsPublicConnectors(false)
  }

  const handleCheckPrivacyPublic = () => {
    setIsPublicConnectors(!isPublicConnectors)
    setParams(values => {
      return { ...values, privacy: isPublicConnectors ? '' : 'PUBLIC' }
    })
    setPagination(values => {
      return { ...values, current: 1 }
    })
    setIsPrivateConnectors(false)
  }

  const handleSearch = (value: string) => {
    setParams(values => {
      return { ...values, search: value }
    })
    setPagination(values => {
      return { ...values, current: 1 }
    })
  }

  const handleNextPage = (newPage: number) => {
    setPagination(values => {
      return { ...values, current: newPage }
    })
  }
  return (
    <Container>
      <Loading text="Criando automação..." isOpen={isLoading} />
      <FormContainer>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <InputContainer>
              <InputText
                label="Nome"
                name="name"
                isFormik
                placeHolder="Nome"
                value={formik.values.name}
                onChange={formik.handleChange}
                errorMessage={formik.errors.name}
                errorForm={!!formik.errors.name}
              />
            </InputContainer>
            <SearchContainer>
              <InputSearch
                label="Encontrar conector"
                placeHolder="Pesquisa"
                onChange={handleSearch}
              />
              <SearchItem>
                <CheckBoxContainer>
                  <Checkbox
                    label="Conectores privados"
                    value="PRIVATE"
                    isChecked={isPrivateConnectors}
                    isDisabled={false}
                    onChange={handleCheckPrivacyPrivate}
                  />
                </CheckBoxContainer>
              </SearchItem>
              <SearchItem>
                <CheckBoxContainer>
                  <Checkbox
                    label="Conectores pre-built"
                    value="PUBLIC"
                    isChecked={isPublicConnectors}
                    isDisabled={false}
                    onChange={handleCheckPrivacyPublic}
                  />
                </CheckBoxContainer>
              </SearchItem>
            </SearchContainer>
            {formik.errors.connectors && (
              <ConnectorsErrorMessage>
                {formik.errors.connectors}
              </ConnectorsErrorMessage>
            )}
            <ConnectorsList
              connectors={connectors}
              handleConector={handleConector}
              currentPage={currentPage}
              totalPages={totalPage}
              handleNextPage={handleNextPage}
            />
            <ModalActions>
              <Button
                type="reset"
                colorType="neutral"
                variant="outlined"
                text="Cancelar"
                onClick={onClose}
              />
              <Button
                role="button-save"
                variant="default"
                colorType="green"
                type="submit"
                text="Salvar"
                isDisabled={isLoading}
                icon={<FiCheck />}
              />
            </ModalActions>
          </form>
        </FormikProvider>
      </FormContainer>
    </Container>
  )
}
export { AutomationForm }
