import React from 'react'
import { Button } from '@devapi/design-system'
import { ModalActions, ModalContent } from './SelectAutomationType.style'
import { AutomationCard } from 'src/components/AutomationCard'
import { BiRightArrowAlt } from 'react-icons/bi'
import { ISelectAutomationType } from './SelectAutomationType.interface'

// import { IconNoCode } from './components/IconNoCode'
import { IconHighCode } from './components/IconHighCode'

const SelectAutomationType = ({
  automationTypeActive,
  onSelect,
  onClose,
  handleStep,
}: ISelectAutomationType) => {
  const automationType = {
    'no-code': 'no-code',
    'high-code': 'high-code',
  }

  return (
    <>
      <ModalContent>
        {/* <AutomationCard
          isSelected={automationType[automationTypeActive] === 'no-code'}
          text="No Code"
          icon={<IconNoCode />}
          onClick={() => onSelect(automationType['high-code'])}
        /> */}
        <AutomationCard
          isSelected={automationType[automationTypeActive] === 'high-code'}
          text="High Code"
          icon={<IconHighCode />}
          onClick={() => onSelect(automationType['high-code'])}
        />
      </ModalContent>
      <ModalActions>
        <Button
          type="button"
          variant="outlined"
          text="Cancelar"
          colorType="neutral"
          onClick={onClose}
        />
        <Button
          type="button"
          variant="default"
          text="Próximo"
          colorType="green"
          icon={<BiRightArrowAlt />}
          iconPosition="right"
          isDisabled={!automationType[automationTypeActive]}
          onClick={() => handleStep('automation-form')}
        />
      </ModalActions>
    </>
  )
}

export { SelectAutomationType }
