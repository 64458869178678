import theme from 'src/assets/styles/theme'
import styled from 'styled-components'

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`

export const Pipe = styled.div`
  width: 110px;
  height: 3px;
  margin-bottom: 3px;
  background-color: ${theme.colors.secondary};
`

export const Title = styled.p`
  margin: 0;
  font-size: 2rem;

  span {
    font-weight: 700;
  }
`
export const Form = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  button {
    margin-top: 10px;
    height: 55px;
    width: 100%;

    span {
      font-weight: bold;
      font-size: 19px;
    }
  }
`

export const InputContainer = styled.div`
  width: 100%;
  margin-bottom: 12px;

  input {
    height: 55px;
  }

  & + div {
    margin-top: 9px;
  }
`
