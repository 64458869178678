import React from 'react'

import { Switch, Route, useHistory } from 'react-router-dom'
import { EditProfile } from './EditProfile'
import { UpdatePassword } from './UpdatePassword'
import { Plan } from './Plan'

import {
  OptionsProfileContainer,
  OptionsProfile,
  ProfileContentContainer,
  Container,
  Content,
} from './Profile.style'
import { InternalHeader } from 'src/components/InternalHeader'

export const Profile = () => {
  const history = useHistory()
  const atualRote = history.location.pathname

  const changeRouterHandler = (route: string) => {
    history.push(`/profile/${route}`)
  }

  const crumbs = [{ label: 'Perfil', value: 'profile' }]

  return (
    <Container>
      <InternalHeader crumbs={crumbs} />
      <Content>
        <OptionsProfileContainer>
          <OptionsProfile
            data-testid="route_profile/edit"
            onClick={() => changeRouterHandler('edit')}
            isActiveItem={atualRote === '/profile/edit'}
          >
            Editar perfil
          </OptionsProfile>
          <OptionsProfile
            data-testid="route_profile/update-pass"
            onClick={() => changeRouterHandler('change-password')}
            isActiveItem={atualRote === '/profile/change-password'}
          >
            Alterar Senha
          </OptionsProfile>
          <OptionsProfile
            data-testid="route_profile/my-plan"
            onClick={() => changeRouterHandler('my-plan')}
            isActiveItem={atualRote === '/profile/my-plan'}
          >
            Meu Plano
          </OptionsProfile>
        </OptionsProfileContainer>
        <ProfileContentContainer>
          <Switch>
            <Route path="/profile/edit" exact component={EditProfile} />
            <Route path="/profile/my-plan" exact component={Plan} />
            <Route
              path="/profile/change-password"
              exact
              component={UpdatePassword}
            />
          </Switch>
        </ProfileContentContainer>
      </Content>
    </Container>
  )
}
