import React, { useState } from 'react'
import { Modal } from '@devapi/design-system'
import { SelectAutomationType } from './components/SelectAutomationType'
import { ICreateAutomationsProps } from './CreateAutomations.interface'
import { AutomationForm } from './components/AutomationForm'
import { modalTitle } from '../../utils/handleModalTitle'

const CreateAutomations = ({ isOpen, onClose }: ICreateAutomationsProps) => {
  const [automationType, setAutomationType] = useState('')
  const [step, setStep] = useState('select-type')

  const handleSelectAutomationType = (type: string) => {
    setAutomationType(type)
  }

  const handleChangeStep = (nextStep: string) => {
    setStep(nextStep)
  }

  const handleCloseModal = () => {
    onClose()
    setStep('select-type')
    setAutomationType('')
  }

  const stepActive = {
    'select-type': (
      <SelectAutomationType
        onSelect={handleSelectAutomationType}
        automationTypeActive={automationType}
        onClose={handleCloseModal}
        handleStep={handleChangeStep}
      />
    ),
    'automation-form': (
      <AutomationForm
        onClose={handleCloseModal}
        automationType={automationType}
      />
    ),
  }

  return (
    <Modal
      isOpen={isOpen}
      title={modalTitle(automationType)}
      subTitle={
        step === 'select-type'
          ? 'Selecione abaixo a opção que deseja realizar'
          : ''
      }
      fontSize="26px"
      onClick={handleCloseModal}
    >
      {stepActive[step]}
    </Modal>
  )
}

export { CreateAutomations }
