import axios from 'axios'
import { errors } from '../utils/errorsMapper'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

api.interceptors.response.use(
  response => response,
  error => {
    const {
      response: {
        data: { code },
      },
    } = error

    const expiredToken = code === 1015
    const unauthorized = code === 1014
    const invalidToken = code === 1016

    if (expiredToken || unauthorized || invalidToken) {
      window.localStorage.clear()
      window.location.href = '/'
      return
    }

    const newError = {
      ...error,
      response: {
        ...error.response,
        data: { message: errors[code] },
      },
    }

    return Promise.reject(newError)
  },
)

export default api
