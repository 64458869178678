import styled from 'styled-components'

export const ListConnectors = styled.div`
  display: grid;

  grid-template-columns: repeat(4, 1fr);
  padding-top: 10px;
  grid-gap: 10px;
  max-height: 300px;
`

export const ConnectorsWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 20px 0;
  min-height: 200px;
  position: relative;
  overflow-y: auto;

  scrollbar-color: #738099 transparent;
  scrollbar-width: thin;

  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #738099;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #738099;
  }
`
