import styled from 'styled-components'

export const ModalContent = styled.div`
  width: 700px;
  height: 350px;
`

export const FormContainer = styled.form`
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  margin-top: 25px;
`

export const InputContainer = styled.div`
  margin-top: 15px;
`

export const Buttoncontainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin: 1rem 0 0 0;
`
