import React from 'react'
import * as Yup from 'yup'
import {
  ICloneConnectorModalProps,
  ICloneConnectorParams,
  ICloneForm,
} from './CloneConnectorModal.interface'
import {
  Content,
  FormContainer,
  ButtonContainer,
  SubTitle,
} from './CloneConnectorModal.style'
import {
  Button,
  InputText,
  Modal,
  notificationError,
  notificationSuccess,
} from '@devapi/design-system'
import { FiCheck } from 'react-icons/fi'
import { useHistory, useParams } from 'react-router-dom'
import { FormikProvider, useFormik } from 'formik'
import { cloneConnector } from '../../providers'

const CloneConnectorModal = ({
  isOpen,
  onClickCloseModal,
}: ICloneConnectorModalProps) => {
  const { id } = useParams<ICloneConnectorParams>()
  const history = useHistory()

  const schema = Yup.object().shape({
    cloneName: Yup.string().required('Nome do conector é obrigatório'),
  })

  const handleCloseModal = () => {
    formik.resetForm()
    onClickCloseModal()
  }

  const onSubmit = async (values: ICloneForm) => {
    const payload = { clone_name: values.cloneName }
    try {
      await cloneConnector(payload, id)
      notificationSuccess({
        message: 'Conector clonado com sucesso!',
        position: 'top-right',
      })
      history.push('/connectors')
    } catch (error) {
      notificationError({
        message: 'Não foi possível clonar o conector.',
        position: 'top-right',
      })
      handleCloseModal()
    }
  }

  const formik = useFormik({
    initialValues: { cloneName: '' },
    validationSchema: schema,
    onSubmit: onSubmit,
  })

  return (
    <Modal
      isOpen={isOpen}
      title="Clonar conector como privado"
      fontSize="20px"
      onClick={handleCloseModal}
    >
      <Content>
        <SubTitle>
          Escolha o nome final do conector privado. Caracteres especiais não são
          permitidos.
        </SubTitle>
        <FormikProvider value={formik}>
          <FormContainer onSubmit={formik.handleSubmit}>
            <InputText
              value={formik.values.cloneName}
              isFormik
              name="cloneName"
              placeHolder="Conector"
              label="Nome do conector *"
              onChange={formik.handleChange}
            />
            <ButtonContainer>
              <Button
                colorType="neutral"
                variant="outlined"
                text="Cancelar"
                type="button"
                onClick={handleCloseModal}
              />
              <Button
                role="btn-submit"
                icon={<FiCheck />}
                iconPosition={'left'}
                colorType="green"
                variant="default"
                text="Clonar"
              />
            </ButtonContainer>
          </FormContainer>
        </FormikProvider>
      </Content>
    </Modal>
  )
}

export { CloneConnectorModal }
