import api from 'src/services/api'
import { IApiContent, ICreateApiPayload } from '../@types/shared.interface'

export const createAPI = async ({
  name,
  connectors,
  description,
  url,
}: ICreateApiPayload) => {
  return await api.post<IApiContent>('/apis', {
    name,
    url,
    description,
    connectors,
  })
}
