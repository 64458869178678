import React from 'react'
import * as Yup from 'yup'
import { Button, InputGroup, InputText, TextArea } from '@devapi/design-system'
import { FormikProvider, useFormik } from 'formik'
import { BiRightArrowAlt } from 'react-icons/bi'

import {
  Container,
  InputContainer,
  Form,
  Buttoncontainer,
} from './ApiForm.style'
import { IApiFormProps } from './ApiForm.interface'

const ApiForm = ({
  onClose,
  handleNextStep,
  handleOnSubmit,
  formData,
}: IApiFormProps) => {
  const schema = Yup.object().shape({
    apiUrl: Yup.string().required('URL é obrigatória'),
    project: Yup.string().required('Nome do projeto é obrigatório'),
    description: Yup.string().required('Descrição é obrigatória'),
  })

  const onSubmit = values => {
    handleOnSubmit({
      name: values.project,
      url: values.apiUrl,
      description: values.description,
    })
    handleNextStep('step3')
  }

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: schema,
    initialValues: {
      apiUrl: formData.url,
      project: formData.name,
      description: formData.description,
    },
    onSubmit,
  })

  return (
    <Container>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <InputContainer>
            <InputText
              isFormik
              label="Nome do Projeto"
              name="project"
              placeHolder="Nome"
              value={formik.values.project}
              onChange={formik.handleChange}
              errorForm={!!formik.errors.project}
              errorMessage={formik.errors.project}
            />
          </InputContainer>
          <InputContainer>
            <InputGroup
              isFormik
              startGroup="https://"
              endGroup=".devapi.com.br/v1"
              label="URL"
              name="apiUrl"
              placeHolder="URL"
              value={formik.values.apiUrl}
              onChange={formik.handleChange}
              isErrorForm={!!formik.errors.apiUrl}
              errorMessage={formik.errors.apiUrl}
            />
          </InputContainer>
          <InputContainer>
            <TextArea
              isFormik
              name="description"
              rows={8}
              columns={20}
              placeHolder="Descrição"
              label="Descrição"
              value={formik.values.description}
              onChange={formik.handleChange}
              isErrorForm={!!formik.errors.description}
              errorMessage={formik.errors.description}
            />
          </InputContainer>
          <Buttoncontainer>
            <Button
              type="reset"
              colorType="neutral"
              variant="outlined"
              text="Cancelar"
              onClick={onClose}
            />
            <Button
              role="button-save"
              variant="default"
              colorType="green"
              type="submit"
              text="Próximo"
              iconPosition="right"
              icon={<BiRightArrowAlt />}
            />
          </Buttoncontainer>
        </Form>
      </FormikProvider>
    </Container>
  )
}

export { ApiForm }
