import theme from 'src/assets/styles/theme'
import styled from 'styled-components'

export const Container = styled.div`
  width: 750px;
`

export const ModalActions = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;

  button {
    margin-right: 15px;
  }
`

export const SearchContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
`

export const SearchItem = styled.div`
  width: 50%;
  margin: 0 15px 0 15px;
`

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`
export const ConnectorsErrorMessage = styled.div`
  white-space: normal;
  font-size: small;
  color: ${theme.colors.red};
`
export const RightContainer = styled.div`
  display: flex;
`

export const CheckBoxContainer = styled.div`
  height: 38px;
  display: flex;
  align-items: center;
`
