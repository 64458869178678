import { IConnectorData } from '../@types/adapters.interface'

export const connectorsSerializer = (connectors: IConnectorData[]) => {
  return Promise.all(
    connectors.map(async connector => {
      return {
        privacy: connector.privacy,
        status: connector.status,
        companyId: connector.company_id,
        authorizationId: connector.authorization_id,
        defaultParamsId: connector.default_params_id,
        resourcesId: connector.resources_id,
        id: connector._id,
        createdAt: connector.created_at,
        updatedAt: connector.updated_at,
        v: connector.__v,
        isSelected: false,
        info: {
          logoUrl: connector.info.logo_url,
          publicName: connector.info.public_name,
          internalName: connector.info.internal_name,
          type: connector.info.type,
          baseUrl: connector.info.base_url,
          categoryId: connector.info.category_id,
          description: connector.info.description,
        },
      }
    }),
  )
}
