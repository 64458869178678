import React from 'react'
import * as Yup from 'yup'
import {
  Button,
  InputText,
  Modal,
  notificationError,
  notificationSuccess,
  TextArea,
} from '@devapi/design-system'
import { ICreateTenantProps } from './CreateTenant.interface'
import { FiCheck } from 'react-icons/fi'
import {
  FormContainer,
  ModalContent,
  InputContainer,
  Buttoncontainer,
} from './CreateTenant.style'
import { FormikProvider, useFormik } from 'formik'
import { ICreateTenantPayload } from '../../@types/provider.interface'
import { createTenant } from '../../providers/tenant.create'

const CreateTenant = ({
  isOpen,
  handleToggleModal,
  onSuccess,
}: ICreateTenantProps) => {
  const onSubmit = async (values: ICreateTenantPayload) => {
    try {
      await createTenant(values)
      onSuccess()
      handleToggleModal()
      notificationSuccess({
        message: 'Tenant criado com sucesso',
        position: 'top-right',
      })
      formik.resetForm()
    } catch (error) {
      notificationError({
        message:
          error.response?.data?.message || 'Não foi possivel criar tenant.',
        position: 'top-right',
      })
    }
  }

  const schema = Yup.object().shape({
    name: Yup.string().required('Nome Obrigatório'),
    description: Yup.string().required('Descrição Obrigatório'),
  })

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
    },
    validationSchema: schema,
    onSubmit,
  })

  const handleClose = () => {
    handleToggleModal()
    formik.resetForm()
  }

  return (
    <Modal
      title="Novo Tenant"
      fontSize="26px"
      isOpen={isOpen}
      onClick={handleClose}
    >
      <ModalContent>
        <FormikProvider value={formik}>
          <FormContainer onSubmit={formik.handleSubmit}>
            <InputContainer>
              <InputText
                label="Nome"
                name="name"
                placeHolder="Nome"
                value={formik.values.name}
                onChange={formik.handleChange}
                errorMessage={formik.errors.name}
                errorForm={!!formik.errors.name}
                isFormik
              />
            </InputContainer>
            <InputContainer>
              <TextArea
                name="description"
                label="Descrição"
                placeHolder="Descrição"
                columns={20}
                rows={8}
                onChange={formik.handleChange}
                value={formik.values.description}
                errorMessage={formik.errors.description}
                isErrorForm={!!formik.errors.description}
                isFormik
              />
            </InputContainer>
            <Buttoncontainer>
              <Button
                onClick={handleToggleModal}
                type="reset"
                colorType="neutral"
                variant="outlined"
                text="Cancelar"
              />
              <Button
                role="button-save"
                variant="default"
                colorType="green"
                type="submit"
                text="Salvar"
                icon={<FiCheck />}
              />
            </Buttoncontainer>
          </FormContainer>
        </FormikProvider>
      </ModalContent>
    </Modal>
  )
}

export { CreateTenant }
