import React, { useState } from 'react'

import { IViewDetailsProps } from './LogsModal.interface'
import {
  Container,
  Content,
  RequestDetails,
  Text,
  ErrorDataContainer,
  Title,
  HeadersContainer,
  RequestListContainer,
  HeaderContainer,
  HeaderTitle,
  Details,
  Log,
  StatusCard,
} from './LogsModal.style'

import AceEditor from 'react-ace'
import 'ace-builds/src-noconflict/theme-twilight'
import 'ace-builds/src-noconflict/mode-json'
import { MdClose } from 'react-icons/md'
import theme from 'src/assets/styles/theme'

const LogsModal = ({
  isOpen,
  projectName,
  handleCloseModal,
  errorData,
}: IViewDetailsProps) => {
  const [selectedLog, setSelectedLog] = useState(0)

  const colorVariation = {
    5: theme.colors.red,
    4: theme.colors.orange,
    2: theme.colors.green,
  }

  const handleChangeLog = (index: number) => {
    if (selectedLog === index) return true
    return false
  }

  const handleStatusClick = (index: number) => {
    setSelectedLog(index)
  }

  const details = [
    {
      name: 'Error',
      value: errorData[selectedLog].error || '',
    },
    {
      name: 'Status',
      value: errorData[selectedLog].status || '',
    },
    {
      name: 'URL',
      value: errorData[selectedLog].url || '',
    },
    {
      name: 'Method',
      value: errorData[selectedLog].method.toUpperCase() || '',
    },
    {
      name: 'Params',
      value: errorData[selectedLog].params || '',
    },
  ]

  const errorDataDetails = {
    message: errorData[selectedLog].message,
    error: errorData[selectedLog].error,
    status: errorData[selectedLog].status,
    cause: errorData[selectedLog].cause,
  }

  return (
    <Container isOpen={isOpen} title={projectName}>
      <Content>
        <RequestListContainer>
          {errorData.map((item, index) => {
            return (
              <StatusCard
                key={index}
                color={colorVariation[item.status.substr(0, 1)]}
                isActive={handleChangeLog(index)}
                onClick={() => handleStatusClick(index)}
              >
                <p>{item.status}</p>
              </StatusCard>
            )
          })}
        </RequestListContainer>
        <Log>
          <HeaderContainer>
            <HeaderTitle>{projectName}</HeaderTitle>
            <MdClose onClick={handleCloseModal} />
          </HeaderContainer>
          <Details>
            <RequestDetails>
              {details.map((item, index) => {
                return (
                  <Text key={index}>
                    {`${item.name}:`} <span>{item.value}</span>{' '}
                  </Text>
                )
              })}
            </RequestDetails>
            <ErrorDataContainer>
              <Title>Error data</Title>
              <AceEditor
                style={{ borderRadius: '5px' }}
                fontSize={15}
                wrapEnabled
                width="650px"
                height="134px"
                value={JSON.stringify(errorDataDetails, null, '\t')}
                mode="json"
                theme="twilight"
                tabSize={3}
                highlightActiveLine={false}
                readOnly
                setOptions={{
                  showLineNumbers: false,
                }}
              />
            </ErrorDataContainer>
            <HeadersContainer>
              <Title>Headers</Title>
              <AceEditor
                style={{ borderRadius: '5px' }}
                fontSize={15}
                wrapEnabled
                width="650px"
                height="345px"
                value={JSON.stringify(
                  errorData[selectedLog].headers,
                  null,
                  '\t',
                )}
                mode="json"
                theme="twilight"
                tabSize={3}
                highlightActiveLine={false}
                readOnly
                setOptions={{
                  showLineNumbers: false,
                }}
              />
            </HeadersContainer>
          </Details>
        </Log>
      </Content>
    </Container>
  )
}

export { LogsModal }
