import React from 'react'
import {
  Redirect,
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
} from 'react-router-dom'
import { useAuth } from 'src/context/Auth'
import AuthLayout from '../modules/_layouts/AuthLayout'
import DefaultLayout from 'src/modules/_layouts/default'

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean
  component: React.ComponentType
}

const Route = ({
  component: Component,
  isPrivate = false,
  ...rest
}: RouteProps): JSX.Element => {
  const { accessToken } = useAuth()
  const Layout = isPrivate ? DefaultLayout : AuthLayout

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        return isPrivate === !!accessToken ? (
          <Layout>
            <Component />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: isPrivate ? '/' : '/dashboard',
              state: location,
            }}
          />
        )
      }}
    />
  )
}

export default Route
