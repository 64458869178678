import api from 'src/services/api'
import {
  ITenantResponse,
  IUpdateTenantPayload,
} from '../@types/provider.interface'

export const updateTenant = async ({
  id,
  name,
  description,
}: IUpdateTenantPayload) => {
  return await api.put<ITenantResponse>(`/tenants/${id}`, {
    name,
    description,
    tags: [],
  })
}
