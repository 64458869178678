import React from 'react'
import { IContextProps } from './interfaces/Context.interface'

import { AuthProvider } from './Auth'

const AppProvider: React.FC<IContextProps> = ({ children }: IContextProps) => {
  return (
    <>
      <AuthProvider>{children}</AuthProvider>
    </>
  )
}

export default AppProvider
