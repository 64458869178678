import React from 'react'
import { handleConnectorResponse } from '../adapters/AutomationsSerializer'
import { ConnectorIcon } from '../components'
import {
  IAutomationSerialized,
  IAutomationTable,
} from '../presentation/Automations.interface'
import { findConnectorById } from '../providers/Connectors.find'

export const handleConvertAutomations = (
  automationsSerialized: IAutomationSerialized[],
): Promise<IAutomationTable[]> => {
  return Promise.all(
    automationsSerialized.map(async automation => {
      const { connectors, createdAt } = automation
      const logoUrlArr = await handleExtractLogoUrl(connectors)
      return {
        ...automation,
        createdAt: convertDate(createdAt),
        connectors: <ConnectorIcon logoUrlArr={logoUrlArr} />,
      }
    }),
  )
}

const handleExtractLogoUrl = (connectorsId: string[]) => {
  return Promise.all(
    connectorsId.map(async connector => {
      const connectorResponse = await findConnectorById(connector)
      const { logoUrl } = handleConnectorResponse(connectorResponse)
      return logoUrl
    }),
  )
}

const convertDate = (dateString: string) => {
  const date = new Date(dateString).toLocaleDateString()
  return date
}
