import React from 'react'
import { Container, IconContainer } from './Connector.style'

export const ConnectorIcon = (props: { logoUrlArr: string[] }) => {
  const { logoUrlArr } = props

  return (
    <Container>
      {logoUrlArr.map((logoUrl, idx) => {
        return (
          <IconContainer key={idx}>
            <img src={logoUrl} />
          </IconContainer>
        )
      })}
    </Container>
  )
}
