import { IErrorContentResponse } from '../@types/providers.interface'
import { IErrorData } from '../@types/shared.interface'

export const handleErrorData = (
  logDetails: IErrorContentResponse[],
): IErrorData[] => {
  if (!logDetails.length) return []

  return logDetails.map(log => ({
    message: log.message,
    error: log.data_error,
    status: log.status_code.toString(),
    cause: '[]',
    url: log.config_error.url,
    method: log.config_error.method,
    params: log.config_error.params,
    headers: log.headers_error,
  }))
}
