import {
  IConnectorParams,
  IConnectorResponseData,
} from './../@types/shared.interface'
import { notificationError } from '@devapi/design-system'
import api from 'src/services/api'

export const findAllConnectors = async ({
  currentPage,
  orderBy,
  search,
  category,
  privacy,
}: IConnectorParams) => {
  try {
    const res: IConnectorResponseData = await api.get('/connectors/', {
      params: {
        limit: 55,
        page: currentPage,
        ...(search ? { search } : {}),
        ...(orderBy ? { orderby: orderBy } : {}),
        privacy: privacy || '',
        ...(category ? { category: category } : {}),
      },
    })
    const { page, content } = res.data
    return { page, content }
  } catch (err) {
    const {
      data: { message },
    } = err.response || {
      data: { message: 'Não foi possivel carregar conectores' },
    }
    notificationError({ message: message, position: 'top-right' })
  }
}

export const findConnectorById = async (id: string) => {
  return await api.get(`/connectors/${id}`)
}
