import { IKeyValueObject } from '../@types/shared.interface'
import {
  ISerializeKeysAndValuesOptions,
  ISerializedKeyObject,
} from '../@types/adapters.interface'

export const serializeKeysAndValues = (
  data: IKeyValueObject,
  options: ISerializeKeysAndValuesOptions,
): ISerializedKeyObject[] => {
  const option = {
    name: {
      key: 'name',
      value: (item: string) => item,
    },
    date: {
      key: 'date',
      value: (item: string) => new Date(item).toLocaleDateString('pt-BR'),
    },
  }
  return Object.entries(data).map(item => ({
    [option[options.key].key]: option[options.key].value(item[0]),
    total: Number(item[1]),
  }))
}
