import React, { useEffect, useState } from 'react'
import { useDashboard } from '../../contexts/DashboardContext'
import {
  Container,
  ChartKpi,
  ChartLabel,
  ChartKpiDetails,
  ChartKpiTotal,
  ChartKpiDescription,
  FlexBetween,
  Toggle,
  ToggleItem,
} from './ChartDailyKpi.style'
import { BarChart, Bar, XAxis, YAxis, Tooltip } from 'recharts'
import { ChartNoDataMessage } from '../'
import {
  ISerializedApi,
  ISerializedJobs,
} from '../../@types/adapters.interface'

export const ChartDailyKpi = ({ type }: { type: 'success' | 'error' }) => {
  const { apis, jobs } = useDashboard()
  const [activeItem, setActiveItem] = useState('jobs')
  const [displayData, setDisplayData] = useState(
    {} as ISerializedApi | ISerializedJobs,
  )

  const handleToggleActiveItem = (item: string) => {
    setActiveItem(item)
  }

  useEffect(() => {
    if (activeItem === 'apis') {
      setDisplayData(apis)
    } else {
      setDisplayData(jobs)
    }
  }, [activeItem, apis, jobs])

  const config = {
    success: {
      title: 'Sucesso',
      description: 'Operações com sucesso',
      fill: '#22c9ae',
      state: {
        key: 'successes',
        total: 'totalSuccesses',
      },
    },
    error: {
      title: 'Erro',
      description: 'Operações com erro',
      fill: '#f72ea2',
      state: {
        key: 'errors',
        total: 'totalErrors',
      },
    },
  }

  return (
    <>
      <Container>
        <FlexBetween>
          <ChartLabel>{config[type].title}</ChartLabel>
          <Toggle>
            <ToggleItem
              isActive={activeItem === 'apis'}
              onClick={() => handleToggleActiveItem('apis')}
              data-testid="toggle-item-apis"
            >
              APIs
            </ToggleItem>
            <ToggleItem
              isActive={activeItem === 'jobs'}
              onClick={() => handleToggleActiveItem('jobs')}
              data-testid="toggle-item-jobs"
            >
              Jobs
            </ToggleItem>
          </Toggle>
        </FlexBetween>
        <ChartKpi>
          <ChartKpiDetails>
            <ChartKpiTotal data-testid="kpi-daily-amount">
              {(displayData && displayData[config[type]?.state?.total]) || 0}
            </ChartKpiTotal>
            <ChartKpiDescription>
              {config[type].description}
            </ChartKpiDescription>
          </ChartKpiDetails>
          {displayData?.dailyData?.total.length ? (
            <BarChart
              data={displayData.dailyData[config[type].state.key]}
              height={90}
              width={300}
            >
              <XAxis dataKey="date" tick={false} axisLine={false} />
              <YAxis tick={false} axisLine={false} />
              <Tooltip cursor={false} />
              <Bar
                dataKey="total"
                fill={config[type].fill}
                barSize={10}
                radius={10}
                legendType="circle"
              />
            </BarChart>
          ) : (
            <ChartNoDataMessage />
          )}
        </ChartKpi>
      </Container>
    </>
  )
}
