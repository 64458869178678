import { IFiltersState } from './../presentation/Jobs.interface'
import api from 'src/services/api'
import {
  IJobDetailsResponse,
  IJobsResponse,
} from '../@types/providers.interface'

export const findJobs = async (page?: number, filters?: IFiltersState) => {
  return api.get<IJobsResponse>('/jobs', {
    params: {
      limit: 20,
      page: page,
      ...(filters?.searchTerm && { search: filters.searchTerm }),
      ...(filters?.project && { automation_id: filters.project }),
      ...(filters?.tenant && { tenant_id: filters.tenant }),
      ...(filters?.orderBy && { orderby: filters.orderBy }),
    },
  })
}

export const findJobById = async (id: string) => {
  return api.get<IJobDetailsResponse>(`/jobs/${id}`)
}
