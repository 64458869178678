import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { NotificationContainer } from '@devapi/design-system'

import Routes from './routes'
import GlobalStyle from './assets/styles/global'
import AppProvider from './context'

const App: React.FC = () => {
  return (
    <Router>
      <AppProvider>
        <Routes />
        <GlobalStyle />
        <NotificationContainer />
      </AppProvider>
    </Router>
  )
}

export default App
