import { IFindAllAutomations } from './../@types/providers.interface'
import api from 'src/services/api'

export const findAll = async ({
  currentPage,
  orderBy,
  search,
}: IFindAllAutomations) => {
  const response = await api.get('/automations', {
    params: {
      limit: 20,
      page: currentPage,
      ...(search ? { search } : {}),
      ...(orderBy ? { orderby: orderBy } : {}),
    },
  })

  const { content, page } = response.data
  return { content, page }
}
