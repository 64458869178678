import { IGroups } from '../@types/shared.interface'
import { explodePath, isValidRoute } from './'

export const validateResource = (
  values: IGroups[],
  name: string,
  methods: string[],
  methodsPayload,
) => {
  let actualName = name
  let paths: string
  if (name.includes('/')) {
    const [left, ...right] = explodePath(name, '/')
    actualName = left
    paths = right.join('/')
  }
  const nameAlreadyExists = values.some(item => item.name === actualName)
  const checkedMethods = methods.map(item => methodsPayload[item])
  const resourceMethods = checkedMethods.map(method => {
    return {
      ...method,
      route: `${actualName}/`,
      concatRoute: `${paths || ''}${
        paths && method.concatRoute
          ? `/${method.concatRoute}`
          : method.concatRoute
      }`,
    }
  })
  if (nameAlreadyExists) {
    const findResource = values.find(item => item.name === actualName)
    if (findResource) {
      findResource.routes.push(...resourceMethods)
      const newRoutes = findResource.routes.filter(route =>
        isValidRoute(findResource, route),
      )
      findResource.routes = newRoutes
      const actualResources = values.filter(item => item.name !== actualName)
      actualResources.push(findResource)
      return actualResources
    }
  }
  return [
    ...values,
    {
      name: actualName,
      routes: resourceMethods,
    },
  ]
}
