import React, { useEffect, useState } from 'react'

import {
  Loading,
  Multistep,
  notificationError,
  notificationSuccess,
  DeleteAlert,
  InternalHeader,
} from '@devapi/design-system'
import { useHistory, useParams } from 'react-router-dom'

import {
  IAuthentication,
  IDefaultParameters,
  IInformationForm,
  IResource,
  IInfoConnector,
} from 'src/modules/Connectors/@types'
import {
  handleAuthorizationPayload,
  handleInformationPayload,
  handleResourcePayload,
} from 'src/modules/Connectors/adapters'
import { handleFormatAuthorization } from 'src/modules/Connectors/adapters/handleFormatAuthorization'
import { handleFormatDefaultParams } from 'src/modules/Connectors/adapters/handleFormatDefautlParams'
import { handleResourceParams } from 'src/modules/Connectors/adapters/handleResourceParams'
import {
  AutenticationForm,
  InformationForm,
  DefaultParametersForm,
  CardInfo,
  ResourceForm,
} from 'src/modules/Connectors/components'
import {
  findResourceById,
  findAuthorizationsById,
  findDefaultParametersById,
  findConnectorById,
  deleteConnector,
} from 'src/modules/Connectors/providers'
import { createDefaultParams } from 'src/modules/Connectors/providers/connectors.create'
import {
  updateConnectorAuthentication,
  updateConnectorDefaultParamters,
  updateConnectorInfo,
  updateConnectorResource,
} from 'src/modules/Connectors/providers/connectors.update'

import { IEditConnectorParams } from './EditConnector.interface'
import {
  Container,
  ContentContainer,
  FormContainer,
  StepContainer,
  Title,
} from './EditConnector.style'

const EditConnector = () => {
  const history = useHistory()
  const { id } = useParams<IEditConnectorParams>()
  const [step, setStep] = useState('information')
  const [isLoading, setIsLoading] = useState(false)
  const [isPassInformations, setIsPassInformations] = useState(false)
  const [isPassAutentication, setIsPassAutentication] = useState(false)
  const [isPassParameters, setIsPassParameters] = useState(false)
  const [isPassResource, setIsPassResource] = useState(false)
  const [privacy, setPrivacy] = useState('')
  const [deleteModal, setDeleteModal] = useState(false)
  const [informations, setInformations] = useState({
    privacy: 'PRIVATE',
    info: {
      publicName: '',
      internalName: '',
      type: 'REST',
      baseUrl: '',
      logoUrl: '',
      categoryId: '',
      description: '',
    },
  } as IInformationForm)
  const [autentication, setAutentication] = useState({} as IAuthentication)
  const [authorizationId, setAuthorizationId] = useState('')
  const [resourceId, setResourceId] = useState('')
  const [defaultParamtersId, setDefaultParamtersId] = useState('')
  const [connectorName, setConnectorName] = useState('Nome em exibição')
  const [urlBase, setUrlBase] = useState('')
  const [logoUrl, setLogoUrl] = useState('')
  const [defaultParameters, setDefaultParameters] = useState<
    IDefaultParameters[]
  >([])
  const [resources, setResources] = useState<IResource>({
    version: 'v1',
    groups: [],
  })

  const handleFindConnector = async () => {
    try {
      const response = await findConnectorById(id)

      setAuthorizationId(response.data.authorization_id)
      setDefaultParamtersId(response.data.default_params_id)
      setConnectorName(response.data.info.public_name)
      setUrlBase(response.data.info.base_url)
      setLogoUrl(logoUrl)
      setResourceId(response.data.resources_id)
      setIsPassInformations(true)
      setIsPassAutentication(true)
      setIsPassParameters(true)
      setIsPassResource(true)
      setPrivacy(response.data.privacy)
      setInformations({
        privacy: response.data.privacy,
        info: {
          publicName: response.data.info.public_name,
          internalName: response.data.info.internal_name,
          type: 'REST',
          baseUrl: response.data.info.base_url,
          logoUrl: response.data.info.logo_url,
          categoryId: response.data.info.category_id,
          description: response.data.info.description,
        },
      })
    } catch (error) {
      notificationError({
        position: 'top-right',
        message: 'Não foi possível carregar o conector',
      })
    }
  }

  const handleFindConnectorAuthorization = async () => {
    try {
      const response = await findAuthorizationsById(authorizationId, id)

      const params = handleFormatAuthorization(
        String(response.data.type).toLowerCase(),
        response.data.params,
      )
      setAutentication(params)
    } catch (error) {
      notificationError({
        position: 'top-right',
        message: 'Não foi possível carregar a autenticação',
      })
    }
  }

  const handleFindConnectorDefaultParameters = async () => {
    try {
      const response = await findDefaultParametersById(defaultParamtersId, id)
      const formattedParams = handleFormatDefaultParams(
        response.data.params,
        false,
      )
      setDefaultParameters(formattedParams)
    } catch (error) {
      notificationError({
        position: 'top-right',
        message: 'Não foi possível carregar os parâmetros',
      })
    }
  }

  const handleFindConnectorResource = async () => {
    try {
      const response = await findResourceById(id)
      const formattedGroups = handleResourceParams(response.data.resources)
      setResources(formattedGroups)
    } catch (error) {
      notificationError({
        position: 'top-right',
        message: 'Não foi possível carregar os recursos',
      })
    }
  }

  useEffect(() => {
    handleFindConnector()
    if (id) handleFindConnectorResource()
  }, [id])

  useEffect(() => {
    if (authorizationId) handleFindConnectorAuthorization()
  }, [authorizationId])

  useEffect(() => {
    if (defaultParamtersId) handleFindConnectorDefaultParameters()
  }, [defaultParamtersId])

  const crumbs = [
    {
      label: 'Conectores',
      value: '/connectors',
    },
    {
      label: informations.info.publicName,
      value: '/connectors/new-connector',
    },
  ]

  const steps = [
    {
      label: 'Informações',
      value: 'information',
      isPass: isPassInformations,
    },
    {
      label: 'Autenticação',
      value: 'autentication',
      isPass: isPassAutentication,
    },
    {
      label: 'Paramêtros Padrão',
      value: 'params',
      isPass: isPassParameters,
    },
    {
      label: 'Recursos',
      value: 'resource',
      isPass: isPassResource,
    },
  ]

  const handleDisplayInfos = (values: IInfoConnector) => {
    setConnectorName(values.publicName || 'Nome em exibição')
    setUrlBase(values.baseUrl)
    setLogoUrl(values.logoUrl)
  }

  const handleSetDefaultParameters = (values: IDefaultParameters[]) => {
    setDefaultParameters(values)
    setStep('resource')
    setIsPassParameters(true)
  }

  const handleCancel = () => {
    history.push('/connectors')
  }

  const handleChangeStep = (stepValue: string) => {
    setStep(stepValue)
  }

  const handleSetInformations = (values: IInfoConnector) => {
    setInformations({
      ...informations,
      info: { ...values, internalName: values.publicName },
    })
    setIsPassInformations(true)
    setStep('autentication')
  }

  const handleSetAuthType = (authType: string) => {
    setAutentication({ ...autentication, type: authType })
  }

  const handleSetAutentications = (values: IAuthentication) => {
    setAutentication(values)
    setIsPassAutentication(true)
    setStep('params')
  }

  const handleUpdate = async resources => {
    try {
      setIsLoading(true)
      await updateConnectorInfo(id, handleInformationPayload(informations))
      await updateConnectorAuthentication(
        id,
        authorizationId,
        handleAuthorizationPayload(autentication),
      )
      await updateConnectorResource(
        id,
        resourceId,
        handleResourcePayload(resources),
      )
      if (defaultParamtersId) {
        await updateConnectorDefaultParamters(id, defaultParamtersId, {
          params: handleFormatDefaultParams(defaultParameters, true),
        })
      } else {
        if (defaultParameters.length)
          await createDefaultParams(
            { params: handleFormatDefaultParams(defaultParameters, true) },
            id,
          )
      }
      history.push('/connectors')
      notificationSuccess({
        position: 'top-right',
        message: 'Conector atualizado com sucesso',
      })
    } catch (error) {
      notificationError({
        position: 'top-right',
        message: 'Não foi possível atualizar conector',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleSetResource = value => {
    setIsPassResource(true)
    handleUpdate({ ...resources, groups: value })
  }

  const handleOpenDeleteModal = () => {
    setDeleteModal(true)
  }

  const handleCloseDeleteModal = () => {
    setDeleteModal(false)
  }

  const handleDeleteConnector = async () => {
    try {
      await deleteConnector(id)
      history.push('/connectors')
      notificationSuccess({
        message: 'Conector deletado com sucesso',
        position: 'top-right',
      })
      setDeleteModal(false)
    } catch {
      notificationError({
        message: 'Não foi possivel deletar o conector',
        position: 'top-right',
      })
      history.push('/connectors')
    }
  }

  const formStep = {
    information: {
      title: 'Informação',
      component: (
        <InformationForm
          handleDisplayInfos={handleDisplayInfos}
          onSubmitInformations={handleSetInformations}
          formInitialValues={informations.info}
          privacy={privacy}
          handleDelete={handleOpenDeleteModal}
        />
      ),
    },
    autentication: {
      title: 'Autenticação',
      component: (
        <AutenticationForm
          formInitialValues={autentication}
          handleSetAuthType={handleSetAuthType}
          handleSetAutentications={handleSetAutentications}
          handleChangeStep={handleChangeStep}
          privacy={privacy}
        />
      ),
    },
    params: {
      title: 'Parâmetros padrão',
      component: (
        <DefaultParametersForm
          handleClose={handleCancel}
          handleChangeStep={handleChangeStep}
          defaultParametersValue={defaultParameters}
          handleSetDefaultParameters={handleSetDefaultParameters}
          privacy={privacy}
        />
      ),
    },
    resource: {
      title: 'Recursos',
      component: (
        <ResourceForm
          handleClose={handleCancel}
          handleChangeStep={handleChangeStep}
          initialValues={resources.groups}
          urlBase={informations.info.baseUrl}
          handleSetResource={handleSetResource}
          privacy={privacy}
        />
      ),
    },
  }

  const handleBreadCrumb = (value: string) => {
    history.replace(value)
  }

  return (
    <Container>
      <InternalHeader crumbs={crumbs} onClickBreadCrumbs={handleBreadCrumb} />
      <ContentContainer>
        <Loading isOpen={isLoading} text="Autalizando Conector" />
        {deleteModal && (
          <DeleteAlert
            onClickCancel={handleCloseDeleteModal}
            onClickDelete={handleDeleteConnector}
            language={'pt-br'}
          />
        )}
        <StepContainer>
          <Multistep items={steps} onClick={handleChangeStep} size={'small'} />
        </StepContainer>
        <FormContainer>
          <Title>{formStep[step].title}</Title>
          <CardInfo
            connectorName={connectorName}
            urlBase={urlBase}
            logoUrl={logoUrl}
            autentication={autentication.type}
          />
          {formStep[step].component}
        </FormContainer>
      </ContentContainer>
    </Container>
  )
}

export { EditConnector }
