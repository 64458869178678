import styled from 'styled-components'
import theme from 'src/assets/styles/theme'
import { ILoadingProps } from './ChartLoading.interface'

export const SpinnerContainer = styled.div<ILoadingProps>`
  display: ${props => (props.isLoading ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  width: 100%;
`

export const Spinner = styled.div`
  border: 5px solid ${theme.colors.gray[300]};
  border-radius: 50%;
  border-top: 5px solid ${theme.colors.blue[200]};
  width: 50px;
  height: 50px;
  animation: spin 1500ms linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
