import React, { useEffect, useState } from 'react'
import { Modal } from '@devapi/design-system'
import { EditApiForm } from './components/EditApiForm'
import { EditApiFormConnectors } from './components/EditApiFormConnectors'
import { IEdtiApiProps } from './EditApi.interface'
import { IUpdateApiPayload } from '../../@types/shared.interface'
import { formatUrl } from '../../adapters/formatDocUrl'

const EditApi = ({ isOpen, editingApi, onClose, onSuccess }: IEdtiApiProps) => {
  const [formData, setFormData] = useState({} as IUpdateApiPayload)
  const [activeStep, setActiveStep] = useState('step-form')

  useEffect(() => {
    setFormData(() => {
      return {
        id: editingApi.id,
        connectors: editingApi.connectors,
        name: editingApi.name,
        description: editingApi.description,
        url: formatUrl(editingApi.url),
      }
    })
  }, [editingApi, isOpen])

  const handleOnClose = () => {
    onClose()
    setActiveStep('step-form')
  }

  const handleChangeStep = (nextStep: string) => {
    setActiveStep(nextStep)
  }

  const handleSubmitStepOne = ({
    name,
    description,
    url,
  }: IUpdateApiPayload) => {
    setFormData(values => {
      return {
        ...values,
        name,
        description,
        url,
      }
    })
  }

  const handleSaveConnectors = ({ connectors }: IUpdateApiPayload) => {
    setFormData(values => {
      return { ...values, connectors }
    })
  }
  const steps = {
    'step-form': (
      <EditApiForm
        editingApi={formData}
        onClose={handleOnClose}
        handleNextStep={handleChangeStep}
        handleSubmit={handleSubmitStepOne}
      />
    ),
    'step-connectors': (
      <EditApiFormConnectors
        onSuccess={onSuccess}
        formData={formData}
        handleSaveConnectors={handleSaveConnectors}
        onClose={handleOnClose}
        handleNextStep={handleChangeStep}
      />
    ),
  }
  return (
    <Modal title="Atualizando API" isOpen={isOpen} onClick={handleOnClose}>
      {steps[activeStep]}
    </Modal>
  )
}

export { EditApi }
