import theme from 'src/assets/styles/theme'
import styled from 'styled-components'

import { Link } from 'react-router-dom'

export const ForgotPassword = styled(Link)`
  color: ${theme.colors.gray[400]};
  text-decoration: none;
  font-size: 1rem;
  margin-top: 20px;
  transition: filter 0.2s;
  :hover {
    filter: brightness(0.8);
  }
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`

export const Pipe = styled.div`
  width: 90px;
  height: 3px;
  margin-bottom: 3px;
  background-color: ${theme.colors.secondary};
`

export const Title = styled.p`
  margin: 0;
  font-size: 2rem;

  span {
    font-weight: 700;
  }
`

export const Form = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  label {
    display: inline-block;
  }

  button {
    height: 55px;
    width: 100%;

    span {
      font-weight: bold;
      font-size: 22px;
    }
  }
`
export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 60px;
  margin-bottom: 10px;

  svg {
    position: absolute;
    min-width: 40px;
    top: 18px;
  }

  & + div {
    margin-top: 20px;
  }

  input {
    flex: 1;
    height: 60px;
    padding-left: 35px;
  }
`

export const SwitchContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px;

  label {
    color: ${theme.colors.gray[400]};
    letter-spacing: 0.77px;
    font-size: 1rem;
    margin-right: 6px;
  }
`
