import { IUpdateUserStatus } from '../@types/users.interface'
import { updateUserStatus } from '../providers'
import { notificationError, notificationSuccess } from '@devapi/design-system'

export const handleUpdateUserStatus = async ({
  userId,
  actualStatus,
  onSuccess,
}: IUpdateUserStatus) => {
  try {
    await updateUserStatus(userId, actualStatus)
    const message = actualStatus === 'ACTIVE' ? 'bloqueado' : 'desbloqueado'
    notificationSuccess({
      message: `O usuário foi ${message} com sucesso!`,
      position: 'top-right',
    })
    onSuccess()
  } catch (error) {
    const { message } = error.response.data
    notificationError({
      message: String(message || 'Erro ao atualizar o status'),
      position: 'top-right',
    })
  }
}
