import api from 'src/services/api'
import { IFindPermissionsByApiIdResponse } from '../@types/providers.interface'

export const findPermissionsByApiId = async (
  id: string,
  page: number,
  search = '',
  orderby = '',
) => {
  const { data } = await api.get<IFindPermissionsByApiIdResponse>(
    `/apis/${id}/tenants`,
    {
      params: {
        limit: 20,
        page: page || 1,
        ...(search && { search }),
        ...(orderby && { orderby }),
      },
    },
  )
  return data
}
