import theme from 'src/assets/styles/theme'
import styled from 'styled-components'
import devapiMask from 'src/assets/images/devapi-mask.svg'

export const Container = styled.div`
  display: flex;
  height: 100vh;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 50%;

  background-color: ${theme.colors.secondary};
  background-image: url(${devapiMask});

  @media (max-width: 800px) {
    width: 100%;
    min-height: 40vh;
  }
`

export const Devapi = styled.img`
  width: 40vw;
  @media (max-width: 800px) {
    width: 60vw;
  }
`
export const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 4vw 10vw;
  width: 50%;

  @media (max-width: 800px) {
    width: 100%;
  }
`
