import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'

import fonts from './fonts'
import theme from './theme'

export default createGlobalStyle`
  ${reset}

  @font-face {
    font-family: 'TT Commons';
    src: local('TT Commons Black'), local('TT Commons-Black'), url(${fonts.black});
    font-weight: 900;
    font-style: normal;
  }

  @font-face {
    font-family: 'TT Commons';
    src: local('TT Commons Black Italic'), local('TT Commons-Black-Italic'), url(${fonts.blackItalic});
    font-weight: 900;
    font-style: italic;
  }

  @font-face {
    font-family: 'TT Commons';
    src: local('TT Commons Bold'), local('TT Commons-Bold'), url(${fonts.bold});
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'TT Commons';
    src: local('TT Commons Bold Italic'), local('TT Commons-BoldItalic'), url(${fonts.boldItalic});
    font-weight: bold;
    font-style: italic;
  }

  @font-face {
    font-family: 'TT Commons';
    src: local('TT Commons Demi Bold'), local('TT Commons-DemiBold'), url(${fonts.demiBold});
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'TT Commons-DemiBoldItalic';
    src: local('TT Commons Demi Bold Italic'), local('TT Commons-DemiBoldItalic'), url(${fonts.demiBoldItalic});
    font-weight: 600;
    font-style: italic;
  }

  @font-face {
    font-family: 'TT Commons';
    src: local('TT Commons Extra Bold'), local('TT Commons-ExtraBold'), url(${fonts.extraBold});
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'TT Commons';
    src: local('TT Commons Extra Bold Italic'), local('TT Commons-ExtraBoldItalic'), url(${fonts.extraBoldItalic});
    font-weight: 700;
    font-style: italic;
  }

  @font-face {
    font-family: 'TT Commons';
    src: local('TT Commons Extra Light'), local('TT Commons-ExtraLight'), url(${fonts.extraLight});
    font-weight: 200;
    font-style: normal;
  }

  @font-face {
    font-family: 'TT Commons';
    src: local('TT Commons Extra Light Italic'), local('TT Commons-ExtraLightItalic'), url(${fonts.extraLightItalic});
    font-weight: 200;
    font-style: italic;
  }

  @font-face {
    font-family: 'TT Commons';
    src: local('TT Commons Italic'), local('TT Commons-Italic'), url(${fonts.italic});
    font-weight: normal;
    font-style: italic;
  }

  @font-face {
    font-family: 'TT Commons';
    src: local('TT Commons Light'), local('TT Commons-Light'), url(${fonts.light});
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'TT Commons';
    src: local('TT Commons Light Italic'), local('TT Commons-LightItalic'), url(${fonts.lightItalic});
    font-weight: 300;
    font-style: italic;
  }

  @font-face {
    font-family: 'TT Commons';
    src: local('TT Commons Medium'), local('TT Commons-Medium'), url(${fonts.medium});
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'TT Commons';
    src: local('TT Commons Medium Italic'), local('TT Commons-MediumItalic'), url(${fonts.mediumItalic});
    font-weight: 500;
    font-style: italic;
  }

  @font-face {
    font-family: 'TT Commons';
    src: local('TT Commons'), local('TT Commons'), url(${fonts.regular});
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'TT Commons';
    src: local('TT Commons Thin'), local('TT Commons-Thin'), url(${fonts.thin});
    font-weight: 100;
    font-style: normal;
  }

  @font-face {
    font-family: 'TT Commons';
    src: local('TT Commons Thin Italic'), local('TT Commons-ThinItalic'), url(${fonts.thinItalic});
    font-weight: 100;
    font-style: italic;
  }

  * {
    box-sizing: border-box;
  }

  @media (max-width: 1080px) {
    html {
      font-size: 93.75%;
    }
  }

  @media (max-width: 720px) {
    html {
      font-size: 87.5%;
      font-family: "TT Commons";
    }
  }

  body, input, button, textarea {
    font: 500 1rem "TT Commons", sans-serif;
    color: ${theme.colors.primary}
  }

  h1 {
    font-size: 2rem
  }

  h2 {
    font-size: 1.5rem
  }
`
