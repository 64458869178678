import theme from 'src/assets/styles/theme'
import styled from 'styled-components'

export const Container = styled.div``

export const ContentContainer = styled.div`
  display: flex;

  min-height: 100%;
  min-width: 100%;
  padding: 30px;
`

export const StepContainer = styled.div`
  width: 20%;
  min-height: 100%;
`

export const FormContainer = styled.div`
  width: 80%;
  min-height: 100%;

  border: 1px solid ${theme.colors.gray[300]};
  border-radius: 4px;
  padding: 30px;
`
export const Title = styled.h1`
  font-size: 22px;
  font-weight: 700;

  margin-bottom: 30px;

  color: ${theme.colors.primary};
`
