/* eslint-disable react/jsx-key */
import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import { InternalHeader } from 'src/components/InternalHeader'
import {
  InputSearch,
  Table,
  Button,
  Pagination,
  Loading,
  notificationError,
} from '@devapi/design-system'
import theme from 'src/assets/styles/theme'
import { Container, AlignCenter } from './Permissions.style'
import { BsTrash, BsArrowClockwise } from 'react-icons/bs'
import {
  IPermissionsSearchFilters,
  IDeletePermissionIds,
  IPermissionsRouteParams,
} from './Permissions.interface'
import {
  IPermissionsContentSerialized,
  IPermissionsPageSerialized,
  ITenantOptions,
} from '../../@types/shared.interface'
import { findPermissionsByApiId, findTenants } from '../../providers'
import { permissionsSerializer, tenantsSerializer } from '../../adapters'
import { DeletePermission } from './delete'
import { CreatePermission } from './create'

export const Permissions = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isResponsive, setIsResponsive] = useState(false)
  const [isDeletePermissionOpen, setIsDeletePermissionOpen] = useState(false)
  const [isCreatePermissionOpen, setIsCreatePermissionOpen] = useState(false)
  const [pagination, setPagination] = useState({} as IPermissionsPageSerialized)
  const [permissions, setPermissions] = useState(
    {} as IPermissionsContentSerialized[],
  )
  const [searchFilters, setSearchFilters] = useState(
    {} as IPermissionsSearchFilters,
  )
  const [permissionIdsToDelete, setPermissionIdsToDelete] = useState(
    {} as IDeletePermissionIds,
  )
  const [tenantOptions, setTenantOptions] = useState([{} as ITenantOptions])

  const history = useHistory()
  const { id } = useParams<IPermissionsRouteParams>()
  if (!id) history.push('/apis')

  const handleFindPermissions = async () => {
    try {
      setIsLoading(true)
      const response = await findPermissionsByApiId(
        id,
        pagination.current,
        searchFilters.search,
        searchFilters.orderby,
      )
      const { content, page } = permissionsSerializer(response)
      setPermissions(content)
      setPagination(page)
    } catch (error) {
      notificationError({
        message: 'Erro ao buscar permissões',
        position: 'top-right',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleFindTenants = async () => {
    try {
      const response = await findTenants()
      setTenantOptions(tenantsSerializer(response))
    } catch (error) {
      notificationError({
        message: 'Erro ao buscar tenants',
        position: 'top-right',
      })
    }
  }

  const clearPaginationAndFilters = () => {
    setPagination({ ...pagination, current: 1 })
    setSearchFilters({ ...searchFilters, search: '' })
  }

  const handleResponsivity = () => {
    setIsResponsive(window.innerWidth <= 1366)
  }

  const handleCloseDeletePermission = () => {
    setPermissionIdsToDelete({} as IDeletePermissionIds)
    setIsDeletePermissionOpen(false)
  }

  const handleCreatePermissionModal = () => {
    setIsCreatePermissionOpen(!isCreatePermissionOpen)
  }

  const handleDeletePermissionById = itemRow => {
    const findPermission = permissions.find(item => item.id === itemRow.id)
    if (findPermission && findPermission.id && findPermission.tenantId) {
      const { apiId, tenantId } = findPermission
      setPermissionIdsToDelete({ apiId, tenantId })
      setIsDeletePermissionOpen(true)
    }
  }

  const handleOrderBy = (column: string) => {
    const validOrderBy = {
      name: 'name',
      createdAt: '-created_at',
    }
    setSearchFilters({ ...searchFilters, orderby: validOrderBy[column] })
  }

  useEffect(() => {
    handleFindTenants()
  }, [])

  useEffect(() => {
    handleFindPermissions()
  }, [pagination.current, searchFilters])

  useEffect(() => {
    handleResponsivity()
  }, [])

  const crumbs = [
    { label: 'API Management', value: 'apis' },
    { label: 'Permissões', value: 'permissions' },
  ]

  const handleBreadCrumbs = () => {
    history.push('/apis')
  }
  const columns = [
    { key: 'name', label: 'Tenant', isOrder: true, columnWidth: '300px' },
    { key: 'tokenAbbrev', label: 'Token', columnWidth: '600px' },
    { key: 'createdAt', label: 'Criado em', isOrder: true },
    { key: 'opt', label: 'Opções' },
  ]

  return (
    <>
      <Loading text="Carregando permissões" isOpen={isLoading} />
      <InternalHeader
        crumbs={crumbs}
        onClickBreadCrumbs={handleBreadCrumbs}
        buttonText="Nova permissão"
        onClick={handleCreatePermissionModal}
        filters={[
          {
            field: (
              <InputSearch
                label="Encontrar permissões"
                placeHolder="Pesquisa"
                fieldSize={isResponsive ? '250px' : '320px'}
                onChange={search => setSearchFilters({ search })}
              />
            ),
          },
        ]}
      />
      <Container>
        <Table
          tableData={permissions}
          tableHeaderOptions={columns}
          noContentText="Nenhuma permissão encontrada..."
          orderBy={['name', 'createdAt']}
          sortTableData={handleOrderBy}
          optionsActions={[
            <BsTrash
              {...theme.icon}
              color={theme.colors.primary}
              onClick={handleDeletePermissionById}
            />,
          ]}
          buttonReloadClick={clearPaginationAndFilters}
          buttonReload={
            <Button
              colorType="green"
              variant="outlined"
              text="Recarregar"
              icon={<BsArrowClockwise />}
            />
          }
        />
        <AlignCenter>
          {pagination.totalPages > 1 && (
            <Pagination
              size="default"
              totalPages={pagination.totalPages}
              page={pagination.current}
              onChange={value =>
                setPagination({ ...pagination, current: value })
              }
            />
          )}
        </AlignCenter>
      </Container>
      <DeletePermission
        isOpen={isDeletePermissionOpen}
        onClose={handleCloseDeletePermission}
        onSuccess={handleFindPermissions}
        permissionIds={permissionIdsToDelete}
      />
      <CreatePermission
        isOpen={isCreatePermissionOpen}
        onClose={handleCreatePermissionModal}
        onSuccess={handleFindPermissions}
        apiId={id}
        tenantOptions={tenantOptions}
      />
    </>
  )
}
