import styled from 'styled-components'

export const Content = styled.div`
  width: 800px;
  height: 180px;
`

export const Form = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px 0px 0px 0px;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`

export const Pipe = styled.div`
  width: 15px;
`
