import React, { useState } from 'react'
import { ICardPlanProps } from './CardPlan.interface'
import { HiOutlineCheck } from 'react-icons/hi'
import {
  Button,
  Loading,
  notificationError,
  notificationSuccess,
} from '@devapi/design-system'
import free from '../../../../assets/icons/free-icon.svg'
import devapi from '../../../../assets/icons/devapi-icon.svg'
import business from '../../../../assets/icons/business-icon.svg'
import enterprise from '../../../../assets/icons/enterprise-icon.svg'
import {
  Container,
  DetailItem,
  Icon,
  Name,
  Pipe,
  Plan,
  PlanDetails,
  PlanTime,
  PlanType,
  Value,
} from './CardPlan.style'
import { insertBRNumberMask } from 'src/utils/insertBRNumberMask'
import theme from 'src/assets/styles/theme'
import api from 'src/services/api'

const CardPlan = ({ plan }: ICardPlanProps) => {
  const [isLoading, setIsLoading] = useState(false)

  const planTimeAttributes = [
    { text: 'Período de contrato', value: `${plan.contractTime} meses` },
    // {
    //   text: 'Valor total do contrato',
    //   value: `R$ ${insertBRNumberMask(plan.contractValue)}`,
    // },
    // { text: 'Subtotal', value: `R$ ${insertBRNumberMask(plan.value)}` },
  ]

  const planDetails = [
    `${plan.automation} Automações`,
    `${insertBRNumberMask(plan.apiCalls, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    })} Chamadas de API`,
    `${plan.jobs} Jobs`,
    `${plan.logsDay} Dias de logs`,
    `Tenants ${plan.tenants}`,
    `Usuários ${plan.users}`,
  ]

  const iconVariation = {
    FREE: free,
    DEVAPI: devapi,
    BUSINESS: business,
    ENTERPRISE: enterprise,
  }

  const isFreePlan = plan.type === 'FREE'

  const updatePlan = async () => {
    setIsLoading(true)

    return api
      .post('/companies/user/updateplan')
      .then(() => {
        notificationSuccess({
          message: 'Recebemos sua solicitação, aguarde entrarmos em contato',
          position: 'top-right',
        })
      })
      .catch(error => {
        notificationError({
          message:
            error.response?.data?.message ||
            'Não foi possível atualizar o plano',
          position: 'top-right',
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <>
      <Loading isOpen={isLoading} text="Solicitando atualização..." />
      <Container>
        <PlanType>
          <Icon src={iconVariation[plan.type]} alt={plan.type} />
          <Plan>
            <Name>{plan.type}</Name>
            {isFreePlan ? (
              <Value>
                <span>Teste 14 dias</span>
              </Value>
            ) : (
              <Value>
                R$ <span>{insertBRNumberMask(plan.value)}</span> / mês
              </Value>
            )}
          </Plan>
        </PlanType>
        <PlanTime>
          {planTimeAttributes.map((item, index) => {
            return (
              <p key={index}>
                {item.text}: <span>{item.value}</span>{' '}
              </p>
            )
          })}
        </PlanTime>
        <Pipe />
        <PlanDetails>
          {planDetails.map((details, index) => {
            return (
              <DetailItem key={index}>
                <HiOutlineCheck color={theme.colors.green} size={22} />
                <span>{details}</span>
              </DetailItem>
            )
          })}
        </PlanDetails>
        <Button
          colorType="green"
          variant="default"
          text="Atualizar plano"
          onClick={() => updatePlan()}
        />
      </Container>
    </>
  )
}

export { CardPlan }
