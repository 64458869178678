import { IParamsData, IParametersPayload, IResource } from '../@types'

export const handleResourcePayload = (data: IResource) => {
  const { groups } = data

  const serializedGroups = groups.map(group => {
    const newRoutes = group.routes.map(routeMap => {
      const { body, formData, queryString, urlParams } = handleParams(
        routeMap.params,
      )
      return {
        title: routeMap.title,
        route: `${routeMap.route + routeMap.concatRoute}`,
        description: routeMap.description,
        method: routeMap.method,
        ...(body.length && { body }),
        ...(formData.length && { form_data: formData }),
        ...(queryString.length && { query_string: queryString }),
        ...(urlParams.length && { url_params: urlParams }),
      }
    })

    return { ...group, routes: newRoutes }
  })

  return {
    ...data,
    groups: serializedGroups,
  }
}

const handleParams = (params: IParamsData[]) => {
  const urlParams: IParametersPayload[] = []
  const queryString: IParametersPayload[] = []
  const formData: IParametersPayload[] = []
  const body: IParametersPayload[] = []

  params.forEach(param => {
    if (param.type === 'queryString') {
      queryString.push({
        field_name: param.fieldName,
        description: param.description,
        required: param.required,
        display_name: param.displayName,
      })
    }
    if (param.type === 'formData') {
      formData.push({
        field_name: param.fieldName,
        description: param.description,
        required: param.required,
        display_name: param.displayName,
      })
    }
    if (param.type === 'urlParam') {
      urlParams.push({
        field_name: param.fieldName,
        description: param.description,
        required: param.required,
        display_name: param.displayName,
      })
    }
    if (param.type === 'body') {
      body.push({
        field_name: param.fieldName,
        description: param.description,
        required: param.required,
        display_name: param.displayName,
      })
    }
  })
  return { urlParams, queryString, formData, body }
}
