import React, { useEffect, useState, createContext, useContext } from 'react'
import { ISerializedDashboardData } from '../@types/shared.interface'
import {
  IDashboardContextData,
  IDashboardProviderProps,
} from '../@types/contexts.interface'
import { findDashboard } from '../providers'
import { serializeDashboardData } from '../adapters/serializeDashboardData'
import { notificationError, Loading } from '@devapi/design-system'
import { useAuth } from 'src/context/Auth'
import { isExpiredPlan } from './../../../utils/isExpiredPlan'

const DashboardContext = createContext({} as IDashboardContextData)

export const DashboardProvider = ({ children }: IDashboardProviderProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [dashboardData, setDashboardData] = useState(
    {} as ISerializedDashboardData,
  )
  const { accessToken } = useAuth()

  const handleFindDashboardData = async (limit?: string) => {
    if (isExpiredPlan(accessToken)) {
      return
    }
    try {
      setIsLoading(true)
      const response = await findDashboard(limit)
      setDashboardData(serializeDashboardData(response))
    } catch (error) {
      notificationError({
        message:
          error?.response?.data?.message || 'Erro ao carregar indicadores',
        position: 'top-right',
      })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    handleFindDashboardData()
  }, [])

  return (
    <DashboardContext.Provider
      value={{
        ...dashboardData,
        isLoading,
        handleFindDashboardData,
      }}
    >
      {children}
      <Loading isOpen={isLoading} text="Carregando indicadores..." />
    </DashboardContext.Provider>
  )
}

export const useDashboard = () => {
  return useContext(DashboardContext)
}
