import styled from 'styled-components'

export const Container = styled.div`
  height: 460px;
  width: 780px;
`

export const InputContainer = styled.div`
  margin-top: 15px;
`

export const Form = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Buttoncontainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin: 3rem 0 0 0;
`
