import { AxiosResponse } from 'axios'
import api from 'src/services/api'
import {
  IImportConnector,
  IAuthorizationPayload,
  IPayloadInformation,
  IResponseConnector,
  IDefaultParamsPayload,
  IResourcePayload,
} from '../@types'

export const importConnector = (payload: IImportConnector) => {
  return api.post('/connectors/generate-connector', payload)
}

export const createConnectorInfo = (
  payload: IPayloadInformation,
): Promise<AxiosResponse<IResponseConnector>> => {
  return api.post('/connectors', payload)
}

export const createAuthorizations = (
  payload: IAuthorizationPayload,
  connectorId: string,
) => {
  return api.post(`/connectors/${connectorId}/authorizations`, payload)
}

export const createDefaultParams = (
  payload: IDefaultParamsPayload,
  connectorId: string,
) => {
  return api.post(`/connectors/${connectorId}/default-params/`, payload)
}

export const createResource = (
  payload: IResourcePayload,
  connectorId: string,
) => {
  return api.post(`/connectors/${connectorId}/resources`, payload)
}
