import styled from 'styled-components'
import theme from 'src/assets/styles/theme'
import { IToggleItemProps } from './ChartDailyKpi.interface'

export const Container = styled.div`
  width: 100%;
`

export const ChartLabel = styled.label``

export const ChartKpiDetails = styled.div`
  width: 100px;
`

export const ChartKpiTotal = styled.h1`
  font-size: 3rem;
  min-width: 100px;
`

export const ChartKpiDescription = styled.p``

export const ChartKpi = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 2rem;
  border: 1px solid ${theme.colors.gray[300]};
  border-radius: 5px;
`

export const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Toggle = styled.div`
  display: flex;
  gap: 0.5rem;
`

export const ToggleItem = styled.label<IToggleItemProps>`
  filter: ${props => (props.isActive ? 'opacity(1)' : 'opacity(0.6)')};
  cursor: pointer;
  font-weight: bold;
  transition: 300ms;
  &:hover {
    filter: opacity(1);
  }
`
