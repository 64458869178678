import { IConnectorApiData } from './../@types'

export const connectorSerializer = async (data: IConnectorApiData[]) => {
  return Promise.all(
    data.map(async connector => {
      const {
        info: {
          logo_url,
          public_name,
          base_url,
          category_id,
          description,
          internal_name,
          type,
        },
        __v,
        _id,
        authorization_id,
        company_id,
        created_at,
        default_params_id,
        privacy,
        resources_id,
        status,
        updated_at,
      } = connector

      return {
        info: {
          logoUrl: logo_url,
          publicName: public_name,
          internalName: internal_name,
          type: type,
          baseUrl: base_url,
          categoryId: category_id,
          description: description,
        },
        isPrivate: privacy === 'PRIVATE',
        status: status,
        companyId: company_id,
        authorizationId: authorization_id,
        default_paramsId: default_params_id,
        resourcesId: resources_id,
        id: _id,
        createdAt: created_at,
        updatedAt: updated_at,
        v: __v,
      }
    }),
  )
}
