import theme from 'src/assets/styles/theme'
import styled from 'styled-components'
import { IStautsTagStyleProps } from './StatusTag.interface'

const colorVariant = (color: string) => {
  const colors = {
    failed: theme.colors.red,
    success: theme.colors.green,
    pending: theme.colors.blue[200],
    running: theme.colors.blue[100],
    canceled: theme.colors.orange,
  }

  return colors[color] || colors.pending
}

export const Container = styled.div<IStautsTagStyleProps>`
  background-color: ${props => colorVariant(props.color)};
  color: #fff;
  padding: 5px;
  min-width: 100px;
  width: 100px;
  text-align: center;
`
