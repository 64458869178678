import React from 'react'

import { Breadcrumbs, Button } from '@devapi/design-system'
import { IInternalHeaderProps } from './InternalHeader.interface'
import { AiOutlinePlusCircle } from 'react-icons/ai'

import {
  Container,
  TitleContainer,
  FilterContainer,
  Filter,
  ButtonContainer,
  Filters,
} from './InternalHeader.style'

const InternalHeader = ({
  titleLineItem,
  filters,
  buttonText,
  crumbs,
  onClick,
  onClickBreadCrumbs,
}: IInternalHeaderProps) => {
  const isTwoItems = filters?.length === 2

  return (
    <Container>
      <TitleContainer>
        <Breadcrumbs crumbs={crumbs} onClick={onClickBreadCrumbs} />
        {titleLineItem}
      </TitleContainer>
      {filters && (
        <FilterContainer>
          <Filters isTwoItems={isTwoItems}>
            {filters?.map((filter, index) => {
              return <Filter key={index}>{filter.field}</Filter>
            })}
          </Filters>
          {buttonText && (
            <ButtonContainer>
              <Button
                colorType="green"
                variant="default"
                text={buttonText}
                icon={<AiOutlinePlusCircle size={20} />}
                onClick={onClick}
              />
            </ButtonContainer>
          )}
        </FilterContainer>
      )}
    </Container>
  )
}

export { InternalHeader }
