import React from 'react'
import { InternalHeader } from 'src/components/InternalHeader'
import { DashboardProvider } from '../contexts/DashboardContext'
import { Container, ChartsSection, FlexBetween } from './Dashboard.style'
import {
  ChartOverviewKpi,
  ChartTopTenants,
  ChartJobsConsumption,
  ChartApisConsumption,
  ChartDailyKpi,
  ChartFilter,
} from '../components'

export const Dashboard = () => {
  const crumbs = [{ label: 'Dashboard', value: 'dashboard' }]

  return (
    <DashboardProvider>
      <Container>
        <InternalHeader crumbs={crumbs} />
        <ChartsSection>
          <ChartOverviewKpi />
          <ChartFilter />
          <ChartTopTenants title="Top Tenants" />
          <FlexBetween>
            <ChartDailyKpi type="success" />
            <ChartDailyKpi type="error" />
          </FlexBetween>
          <ChartJobsConsumption title="Consumo de Jobs" />
          <ChartApisConsumption title="Consumo de Apis" />
        </ChartsSection>
      </Container>
    </DashboardProvider>
  )
}
