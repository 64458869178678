import theme from 'src/assets/styles/theme'
import styled from 'styled-components'

export const ContainerCard = styled.div`
  display: flex;

  min-width: 100%;
  min-height: 162px;

  border: 1px solid ${theme.colors.gray[200]};

  margin-bottom: 30px;
`

export const LogoContainer = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  width: 182px;
  height: 162px;

  border: 1px solid ${theme.colors.gray[300]};
  border-radius: 4px;
`
export const Logo = styled.img`
  max-width: 100%;
  max-height: auto;
  padding: 20px;

  object-fit: cover;
`

export const InfoContainer = styled.div`
  background-color: ${theme.colors.gray[200]};

  width: 100%;
`

export const ConnectorName = styled.h1`
  font-size: 22px;
  font-weight: 700;

  height: 25px;

  margin: 30px 0px 20px 50px;
`

export const TextInfo = styled.p`
  font-size: 16px;

  margin: 10px 0px 0px 50px;
`
