import styled from 'styled-components'
import theme from '../../assets/styles/theme'
import { IExpiredImageStyle } from './ExpiredModal.interface'
export const Content = styled.div`
  width: 500px;
  height: 280px;
`
export const Title = styled.div`
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
  padding-top: 23%;
`
export const SubTitle = styled.div`
  font-size: 20px;
  text-align: center;
  margin-bottom: 30px;
`
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-left: 50px;
  margin-right: 50px;
`

export const ExpiredImage = styled.div<IExpiredImageStyle>`
  background-image: url(${props => props.imageExpired});
  background-color: ${theme.colors.gray[100]};
  background-size: 100%;
  height: 132px;
  width: 132px;
  border-radius: 50%;
  position: absolute;
  left: 46%;
  bottom: 55%;
  @media screen and (max-width: 1366px) {
    height: 102px;
    width: 102px;
  }
`
