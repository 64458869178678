import React from 'react'
import {
  Container,
  CheckBox,
  IconContainer,
  TextContainer,
} from './AutomationCard.style'
import { IAutomationCardProps } from './AutomationCard.interface'

import { ImCheckmark } from 'react-icons/im'

export const AutomationCard = (props: IAutomationCardProps) => {
  const { isSelected, icon, text, onClick } = props

  return (
    <Container isSelected={isSelected} onClick={onClick}>
      <CheckBox isSelected={isSelected}>
        <ImCheckmark role="icon-checked" />
      </CheckBox>
      <IconContainer>{icon}</IconContainer>
      <TextContainer>{text}</TextContainer>
    </Container>
  )
}
