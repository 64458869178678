export const errors = {
  1003: 'Você ainda não possui conta na DevApi.',
  1004: 'A atualização do usuário falhou.',
  1005: 'Este email ja esta em uso.',
  1006: 'Email invalido.',
  1007: 'Senha inválida.',
  1008: 'Senha com dados sensiveis',
  1009: 'As senhas devem ser iguais.',
  1011: 'Nome já cadastrado.',
  1012: 'Página não encontrada.',
  1014: 'Não autorizado.',
  1015: 'Token expirado.',
  1016: 'Token invalido.',
  1018: 'Dados invalidos.',
  1019: 'Senha atual incorreta.',
  1020: 'API ou JOB não encontrado.',
  1021: 'Erro ao enviar email.',
  2001: 'Plano não encontrado.',
  2002: 'Plano expirado.',
  2003: 'Plano bloqueado ou desabilitado.',
  2004: 'Máximo de LOGS excedido.',
  3001: 'TAG não encontrada.',
  3002: 'Erro ao atualizar TAG.',
  3003: 'Erro ao deletar TAG.',
  4001: 'Tanant já existe.',
  4002: 'Não é permitido TAGS duplicadas.',
  4003: 'Tenant não encontrado.',
  5000: 'Documento inválido.',
  5001: 'Documento já esta em uso.',
  5002: 'Usuário desabilitado.',
  5003: 'Usuário tem mais de uma empresa.',
  5004: 'Usuário não confirmado.',
  5005: 'Usuário já cadastrado.',
  6001: 'Contato já cadastrado.',
  6002: 'Contato não encontrado.',
  6003: 'Falaha ao deletar contato.',
  7001: 'Documento da empresa já cadastrado.',
  7002: 'Nome já cadastrado.',
  7003: 'Empresa não encontrada.',
  7004: 'Empresa não confirmada.',
  7005: 'Sem permissão para realizar esta ação.',
  7006: 'Não foi possivel remover administrador.',
  7007: 'Token invalido',
  8000: 'Nome da automação ja cadastrado.',
  8001: 'Não é permitido conectores duplicados',
  8002: 'Automação não encontrada',
  9003: 'Email ou senha incorretos',
  9004: 'Token de recuperção expirado.',
  9005: 'Senha já utilizada',
  11000: 'Connector não encontrado.',
  11001: 'Nome do conector já cadastrado.',
  11002: 'Conector não existe.',
  11003: 'Não foi possivel atualizar o conector.',
  11004: 'Não foi possivel clonar o conector.',
  11005: 'Não foi possivel carregar autorizações.',
  11006: 'Não foi possivel carregar paginação',
  11007: 'Não foi possivel carregar parametro padrões.',
  11008: 'Não foi possivel carregar recursos.',
  11009: 'Não foi possivel deletar conector.',
  11010: 'Não foi possivel deletar conector.',
  11011: 'Não foi possivel trocar tipo do conector.',
  11012: 'Categoria não encontrada.',
  11013: 'Permissão negada.',
  11014: 'Não foi possivel criar o conector.',
  11015: 'Não foi possivel editar conector.',
  11016: 'Não foi possivel criar conector.',
  11017: 'Não foi possivel cadastrar autenticações.',
  11018: 'Não foi possivel deletar autenticação.',
  11019: 'Não foi possivel carregar categorias.',
  11020: 'Não foi possivel atualizar recursos.',
  12000: 'JOB não encontrado.',
  12001: 'JOB já cadastrado.',
  12002: 'Não foi possivel deletar o JOB.',
  14000: 'API não encontrada.',
  14001: 'Não foi possivel editar API.',
  14002: 'Não foi possivel deletar API.',
  14003: 'Nome da API já cadastrada.',
  14004: 'URL da API já cadastrada.',
  14005: 'URL invalida.',
  14006: 'Tenant já cadastrado na API.',
  14007: 'Tenant não encontrado.',
  15000: 'LOG não encontrado.',
}
