import React, { useState } from 'react'
import { AutomationCard } from 'src/components/AutomationCard'
import { InternalHeader } from 'src/components/InternalHeader'
import {
  Container,
  ActionContainer,
  ButtonsContainer,
  CardContainer,
  ContentContainer,
  Subtitle,
  Title,
  NextIcon,
  SwaggerIcon,
} from './CreateConnector.style'

import { Button } from '@devapi/design-system'
import { useHistory } from 'react-router'
import { IconPlus } from '../components'
import { SwaggerModal } from './swagger'

export const CreateConnector = () => {
  const [isSwagger, setIsSwagger] = useState(false)
  const [isCustom, setIsCustom] = useState(false)
  const [isOpenSwaggerModal, setIsOpenSwaggerModal] = useState(false)

  const crumbs = [
    {
      label: 'Connectores',
      value: '/connectors',
    },
    { label: 'Novo Conector', value: '/connectors/new-connector' },
  ]

  const handleSelectSwagger = () => {
    setIsSwagger(true)
    setIsCustom(false)
  }

  const handleSelectCustom = () => {
    setIsSwagger(false)
    setIsCustom(true)
  }

  const history = useHistory()
  const handleCancel = () => {
    history.push('/connectors')
  }

  const handleNext = () => {
    if (isCustom) history.push('/connectors/new-connector/constructor')
    if (isSwagger) setIsOpenSwaggerModal(true)
  }

  const handleCloseModal = () => {
    setIsOpenSwaggerModal(false)
  }

  const handleBreadCrumb = (value: string) => {
    history.replace(value)
  }

  return (
    <>
      <Container>
        <InternalHeader crumbs={crumbs} onClickBreadCrumbs={handleBreadCrumb} />
        <ContentContainer>
          <ActionContainer>
            <Title>Construa um conector personalizado</Title>
            <Subtitle>Selecione abaixo a opção que deseja realizar</Subtitle>
            <CardContainer>
              <AutomationCard
                icon={<IconPlus />}
                isSelected={isCustom}
                onClick={handleSelectCustom}
                text={'Construir Conector'}
              />
              <AutomationCard
                icon={<SwaggerIcon />}
                isSelected={isSwagger}
                onClick={handleSelectSwagger}
                text={'Importar Swagger'}
              />
            </CardContainer>
            <ButtonsContainer>
              <Button
                variant={'outlined'}
                colorType={'neutral'}
                text={'Cancelar'}
                onClick={handleCancel}
              />
              <Button
                variant={'default'}
                colorType={'green'}
                text={'Próximo'}
                icon={<NextIcon size={20} />}
                onClick={handleNext}
                iconPosition="right"
              />
            </ButtonsContainer>
          </ActionContainer>
        </ContentContainer>
      </Container>
      {isOpenSwaggerModal && (
        <SwaggerModal
          isOpen={isOpenSwaggerModal}
          handleCloseModal={handleCloseModal}
        />
      )}
    </>
  )
}
