import {
  IAutomationsContent,
  IConnectorData,
} from '../@types/adapters.interface'

export const automationsSerializer = (data: IAutomationsContent[]) => {
  return data.map(automation => {
    const { connectors, created_at, name, type, _id } = automation
    return {
      id: _id,
      name,
      type,
      connectors,
      connectorsId: connectors,
      createdAt: created_at,
    }
  })
}

export const handleConnectorResponse = (data: IConnectorData) => {
  const {
    info: { logo_url },
  } = data

  return {
    logoUrl: logo_url,
  }
}
