import { ICategoriesResponse } from './../@types'
import { notificationError } from '@devapi/design-system'
import api from 'src/services/api'

export const findAllCategories = async () => {
  try {
    const res: ICategoriesResponse = await api.get('/connectors/categories/all')
    const { data } = res
    return data
  } catch ({
    response: {
      data: { message },
    },
  }) {
    notificationError({
      message: String(message).toLowerCase(),
      position: 'top-right',
    })
    return undefined
  }
}
