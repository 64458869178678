export default {
  black: '#000000',
  white: '#ffffff',
  orange: '#f8761f',
  red: '#e53e3e',
  green: '#22c9ae',

  gray: {
    100: '#f4f2fd',
    200: '#f6f6ff',
    300: '#e2e8f0',
    350: '#d2d2d2',
    400: '#738099',
    800: '#45535e',
  },

  blue: {
    100: '#1474f5',
    200: '#5639fe',
    300: '#4628de',
    800: '#2c2d66',
  },

  primary: '#2c2d66',
  secondary: '#5F47ff',
}
