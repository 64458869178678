import api from 'src/services/api'
import {
  IFindAllConnectorsPayload,
  IFindAllConnectorsResponse,
} from '../@types/providers.interface'

export const findAllConnectors = async ({
  current,
  params,
}: IFindAllConnectorsPayload) => {
  const response = await api.get<IFindAllConnectorsResponse>('/connectors', {
    params: {
      page: current || 1,
      limit: 20,
      ...(params.search && { search: params.search }),
      ...(params.privacy ? { privacy: params.privacy } : {}),
      orderby: 'created_at',
    },
  })

  return response.data
}
