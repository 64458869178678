import React from 'react'
import { Switch } from '@devapi/design-system'
import { handleUpdateTenantStatus } from '../utils/handleUpdateTenantStatus'
import { IHandleTableProps } from '../@types/provider.interface'

export const handleTableTenant = ({
  tenants,
  onSuccess,
}: IHandleTableProps) => {
  return tenants.map(tenant => ({
    id: tenant._id,
    name: tenant.name,
    status: (
      <Switch
        isChecked={tenant.status === 'ACTIVE'}
        onChange={() =>
          handleUpdateTenantStatus({
            id: tenant._id,
            status: tenant.status,
            onSuccess: onSuccess,
          })
        }
      />
    ),
  }))
}
