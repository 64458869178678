import React, { ReactNode, useState } from 'react'
import { Header } from 'src/components/Header'
import { MenuNavigation } from 'src/components/MenuNavigation'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { ExpiredModal } from 'src/components/ExpiredModal'

interface IDefaultLayout {
  children: ReactNode
}

const Content = styled.div`
  width: 100vw;
  height: 100vh;
  padding: 40px 0px 0px 223px;
  overflow: auto;
`

const DefaultLayout = ({ children }: IDefaultLayout) => {
  const atualRote = window.location.pathname
  const history = useHistory()

  const [isActiveMascotSwitch, setIsActiveMascotSwitch] = useState(true)
  const [itemMenuActive, setItemMenuActive] = useState(atualRote)

  const onChangeSwitchHandler = () => {
    setIsActiveMascotSwitch(!isActiveMascotSwitch)
  }

  const redirectRoute = (route: string) => {
    history.replace(route)
    setItemMenuActive(route)
  }

  return (
    <>
      <Header redirectRoute={redirectRoute} />
      <MenuNavigation
        isActiveMascotSwitch={isActiveMascotSwitch}
        onChangeSwitch={onChangeSwitchHandler}
        redirectRoute={redirectRoute}
        activeItemMenu={itemMenuActive}
      />
      <Content>{children}</Content>
      <ExpiredModal />
    </>
  )
}

export default DefaultLayout
