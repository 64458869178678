import React, { useState } from 'react'
import {
  DeleteAlert,
  Loading,
  notificationError,
  notificationSuccess,
} from '@devapi/design-system'
import { DeleteJobProps } from './DeleteJob.interface'
import { deleteJob } from '../../providers/jobs.delete'

export const DeleteJob = ({
  jobId,
  isOpen,
  onClose,
  onSuccess,
}: DeleteJobProps) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleDeleteJob = async () => {
    try {
      setIsLoading(true)
      await deleteJob(jobId)
      onSuccess()
      notificationSuccess({
        message: 'Job deletado com sucesso!',
        position: 'top-right',
      })
    } catch (error) {
      notificationError({
        message: error.response?.data?.message || 'Erro ao deletar job',
        position: 'top-right',
      })
    } finally {
      setIsLoading(false)
      onClose()
    }
  }

  return (
    <>
      {isLoading && <Loading isOpen={isLoading} text="Deletando job..." />}
      {isOpen && (
        <DeleteAlert
          onClickCancel={onClose}
          language="pt-br"
          onClickDelete={handleDeleteJob}
        />
      )}
    </>
  )
}
