import styled from 'styled-components'
import theme from 'src/assets/styles/theme'

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Message = styled.h1`
  font-size: 2rem;
  color: ${theme.colors.gray[300]};
`
