import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import {
  InputText,
  Switch,
  Button,
  notificationError,
} from '@devapi/design-system'
import { HiOutlineMailOpen, HiLockOpen } from 'react-icons/hi'
import { FormikProvider, useFormik } from 'formik'

import {
  Form,
  Pipe,
  Title,
  TitleContainer,
  SwitchContainer,
  InputContainer,
  ForgotPassword,
} from './SignIn.style'
import { useAuth } from '../../../context/Auth'

const SignIn = () => {
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  const { signIn } = useAuth()

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: false,
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .required('E-mail obrigatório')
        .email('Entre com um e-mail válido'),
      password: Yup.string().required('Senha obrigatória'),
      remember: Yup.boolean(),
    }),
    onSubmit: async values => {
      try {
        setIsLoading(true)
        if (signIn)
          await signIn({
            email: values.email,
            password: values.password,
          })
        history.push('/dashboard')
      } catch (error) {
        if (error.response?.data?.message === 'Usuário não confirmado.') {
          history.push({
            pathname: '/unconfirmed-email',
            state: { email: values.email },
          })
          return
        }
        notificationError({
          message:
            error.response?.data?.message ||
            'Ocorreu um erro realizar o login.',
          position: 'top-right',
        })
      } finally {
        setIsLoading(false)
      }
    },
  })

  return (
    <>
      <TitleContainer>
        <Pipe />
        <Title>
          Acesse <span>sua conta</span>
        </Title>
      </TitleContainer>
      <FormikProvider value={formik}>
        <Form
          onSubmit={formik.handleSubmit}
          noValidate
          data-testid="signin-form"
        >
          <InputContainer>
            <HiOutlineMailOpen size="20px" color="#738099" />
            <InputText
              isFormik
              name="email"
              placeHolder="E-mail"
              onChange={formik.handleChange}
              value={formik.values.email}
              errorForm={!!formik.errors.email}
              errorMessage={formik.errors.email}
            />
          </InputContainer>
          <InputContainer>
            <HiLockOpen size="20px" color="#738099" />
            <InputText
              isFormik
              name="password"
              type="password"
              placeHolder="Senha"
              onChange={formik.handleChange}
              value={formik.values.password}
              errorForm={!!formik.errors.password}
              errorMessage={formik.errors.password}
            />
          </InputContainer>
          <SwitchContainer>
            <label>Lembre-me</label>
            <Switch
              isChecked={formik.values.remember}
              onChange={event =>
                formik.setFieldValue('remember', event.target.checked)
              }
            />
          </SwitchContainer>
          <Button
            variant="default"
            colorType="green"
            text={isLoading ? 'Entrando...' : 'Login'}
            isDisabled={isLoading}
            type="submit"
          />
        </Form>
      </FormikProvider>
      <ForgotPassword to="/forgot-password">Esqueci minha senha</ForgotPassword>
    </>
  )
}

export { SignIn }
