/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react'
import {
  Container,
  ContainerTab,
  PaginationContainer,
  TableContainer,
} from './Automations.style'
import {
  Button,
  InputSearch,
  Loading,
  notificationError,
  Pagination,
  // Tab,
  Table,
} from '@devapi/design-system'
import { InternalHeader } from 'src/components/InternalHeader'
import { IItemTab } from '@devapi/design-system/dist/components/Tab/Tab.interface'
import { BsArrowClockwise, BsPencil, BsTrash } from 'react-icons/bs'
import { ITableHeaderOptions } from '@devapi/design-system/dist/components/Table/Table.interface'
import theme from 'src/assets/styles/theme'
// import { IoPlay } from 'react-icons/io5'
import { DiVisualstudio } from 'react-icons/di'
import { findAll } from '../providers/Automations.find'
import { IAutomationTable, IPaginationResponse } from './Automations.interface'
import { automationsSerializer } from '../adapters/AutomationsSerializer'
import { handleConvertAutomations } from '../utils/handleConvertAutomations'
import { CreateAutomations } from './create'
import { AutomationEditForm } from './edit'
import { DeleteAutomations } from './DeleteAutomations'

export const Automations = () => {
  const [isOpenModalEdit, setIsOpenModalEdit] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [responsive, setResponsive] = useState(false)
  const [pagination, setPagination] = useState({
    current: 1,
  } as IPaginationResponse)
  const [isOpenModalCreate, setIsOpenModalCreate] = useState(false)

  const [automations, setAutomations] = useState<IAutomationTable[]>([])
  const [orderBy, setOrderBy] = useState('')
  const [search, setSearch] = useState('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedTab, setSelectedTab] = useState<IItemTab>({
    labelText: 'High Code',
    eventValue: 'High Code',
  })
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [automationEditing, setAutomationEditing] = useState<IAutomationTable>(
    {} as IAutomationTable,
  )

  const [itemForDelete, setItemForDelete] = useState<IAutomationTable>(
    {} as IAutomationTable,
  )

  const width = window.innerWidth
  const crumbs = [{ label: 'Automações', value: 'automations' }]
  const highCode = 'High Code'
  // const noCode = 'No Code'
  // const tabs: IItemTab[] = [
  //   { labelText: 'No Code', eventValue: noCode },
  //   { labelText: 'High Code', eventValue: highCode },
  // ]
  const columns: ITableHeaderOptions[] = [
    { key: 'name', label: 'Nome', isOrder: true, columnWidth: '200px' },
    { key: 'type', label: 'Tipo', isOrder: true, columnWidth: '100px' },
    { key: 'connectors', label: 'Conectores', columnWidth: '100px' },
    {
      key: 'createdAt',
      label: 'Criado em',
      isOrder: true,
      columnWidth: '100px',
    },
    { key: 'opt', label: 'Opções', columnWidth: '90px' },
  ]

  const handleFindAutomations = async () => {
    try {
      setIsLoading(true)
      const response = await findAll({
        currentPage: pagination.current,
        search: search,
        orderBy,
      })
      const { page, content } = response
      const automationsSerialized = automationsSerializer(content)
      const automationsForTable = await handleConvertAutomations(
        automationsSerialized,
      )

      setAutomations(automationsForTable)
      setPagination(page)
    } catch (error) {
      notificationError({
        message:
          error.response?.data?.message ||
          'Não foi possível carregar as automações',
        position: 'top-right',
      })
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    handleFindAutomations()
  }, [pagination.current, search, orderBy])

  const handleSearch = (value: string) => {
    setSearch(value)
  }

  const handleOrderBy = (colum: string) => {
    const validOrderBy = {
      name: 'name',
      createdAt: 'created_at',
    }

    setOrderBy(validOrderBy[colum])
  }

  useEffect(() => {
    if (width <= 1366) {
      setResponsive(true)
    } else {
      setResponsive(false)
    }
  }, [width])

  const filters = [
    {
      field: (
        <InputSearch
          label="Encontar automação"
          onChange={handleSearch}
          fieldSize={responsive ? '250px' : '320px'}
          placeHolder="Pesquisa"
          isLabelBold={false}
        />
      ),
    },
  ]

  // const handleTab = (tab: IItemTab) => {
  //   setSelectedTab(tab)
  // }

  const handleToggleModal = () => {
    setIsOpenModalCreate(!isOpenModalCreate)
  }

  const handleDelete = item => {
    setIsDeleteModalOpen(true)
    setItemForDelete(item)
  }

  const handleOpenDevStudio = item => {
    const { id } = item
    window.location.pathname = `/devstudio/ipaas/${id}`
  }

  const handleClose = () => {
    setIsDeleteModalOpen(false)
  }

  const updateAutomations = (automationDeletedId: string) => {
    setAutomations(
      automations.filter(automation => automation.id !== automationDeletedId),
    )
  }

  const handleUpdate = item => {
    setAutomationEditing(item)
    setIsOpenModalEdit(true)
  }

  const handleCloseEditModal = () => {
    setIsOpenModalEdit(false)
  }

  return (
    <Container>
      <InternalHeader
        filters={filters}
        crumbs={crumbs}
        buttonText={'Nova Automação'}
        onClick={handleToggleModal}
      />
      <Loading text={'Carregando automações...'} isOpen={isLoading} />
      {!isLoading && (
        <ContainerTab>
          {/* <Tab tabItems={tabs} isActiveTab={selectedTab} onClick={handleTab} /> */}
          {/* {selectedTab.eventValue === noCode && (
          <TableContainer> No Code</TableContainer>
        )} */}
          {selectedTab.eventValue === highCode && (
            <TableContainer>
              <Table
                noContentText="Nenhum resultado encontrado."
                tableData={automations}
                tableHeaderOptions={columns}
                orderBy={['name']}
                sortTableData={handleOrderBy}
                optionsActions={[
                  // <IoPlay {...theme.icon} color={theme.colors.primary} />,
                  <DiVisualstudio
                    {...theme.icon}
                    color={theme.colors.primary}
                    onClick={handleOpenDevStudio}
                  />,
                  <BsPencil
                    {...theme.icon}
                    color={theme.colors.primary}
                    onClick={item => handleUpdate(item)}
                  />,
                  <BsTrash
                    {...theme.icon}
                    data-testid="deleteButton"
                    color={theme.colors.primary}
                    onClick={item => handleDelete(item)}
                  />,
                ]}
                buttonReload={
                  <Button
                    colorType="green"
                    variant="outlined"
                    text="Recarregar"
                    onClick={handleFindAutomations}
                    icon={<BsArrowClockwise />}
                  />
                }
              />
            </TableContainer>
          )}
          {pagination.totalPages > 1 && (
            <PaginationContainer>
              <Pagination
                size="default"
                totalPages={pagination.totalPages}
                page={pagination.current}
                onChange={value =>
                  setPagination({ ...pagination, current: value })
                }
              />
            </PaginationContainer>
          )}
        </ContainerTab>
      )}
      <AutomationEditForm
        onSuccess={handleFindAutomations}
        isOpen={isOpenModalEdit}
        editingAutomation={automationEditing}
        onClose={handleCloseEditModal}
      />
      <DeleteAutomations
        AutomationId={itemForDelete?.id}
        isOpen={isDeleteModalOpen}
        handleClose={handleClose}
        onSuccess={updateAutomations}
      />

      <CreateAutomations
        isOpen={isOpenModalCreate}
        onClose={handleToggleModal}
      />
    </Container>
  )
}
