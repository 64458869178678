/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react'

import {
  Loading,
  Table,
  Button,
  Pagination,
  notificationError,
} from '@devapi/design-system'
import { BsEye, BsArrowClockwise } from 'react-icons/bs'
import theme from 'src/assets/styles/theme'
import { InternalHeader } from 'src/components/InternalHeader'
import { LogsModal } from 'src/components/LogsModal'
import { Container, Content, TableContainer } from './Logs.style'
import { IApiLogsRouteParams, IPaginationState } from './Logs.interface'
import { useHistory, useParams } from 'react-router-dom'
import { IErrorData } from 'src/components/LogsModal/LogsModal.interface'
import {
  findApiLogsById,
  findLogErrorResponse,
} from '../../providers/logs.find'
import { handleErrorData } from '../../adapters/handleErrorData'
import { handleTableLogs } from '../../adapters/handleTableLogs'
import { ITableLogApi } from '../../@types/shared.interface'

const ApiLogs = () => {
  const { id } = useParams<IApiLogsRouteParams>()
  const history = useHistory()

  if (!id) history.push('/apis')

  const [logPagination, setLogPagination] = useState({
    currentPage: 1,
    totalPages: 0,
  } as IPaginationState)
  const [logs, setLogs] = useState<ITableLogApi[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingLogs, setIsLoadingLogs] = useState(false)
  const [filter, setFilter] = useState('')
  const [isViewLog, setIsViewLog] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [apiName, setApiName] = useState('')
  const [errorData, setErrorData] = useState<IErrorData[]>([
    {
      message: '',
      error: '',
      status: '',
      cause: '',
      headers: {},
      url: '',
      method: '',
      params: '',
    },
  ])

  useEffect(() => {
    findApiLogs(id)
  }, [filter, currentPage])

  const findApiLogs = async (apiId: string) => {
    try {
      if (apiId) {
        setIsLoading(true)
        const { data } = await findApiLogsById(apiId, currentPage, filter)

        const { content, pagination, projectName } = handleTableLogs(data)

        setLogs(content)
        setApiName(projectName)
        if (pagination.totalPages > 0) {
          setLogPagination({
            ...logPagination,
            totalPages: pagination.totalPages,
          })
        }
      }
    } catch (error) {
      notificationError({
        message: 'Não foi possível carregar os logs',
        position: 'top-right',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const findLogError = async (id: string) => {
    try {
      setIsLoadingLogs(true)
      const { data } = await findLogErrorResponse(id)

      const logHandled = handleErrorData(data.content)

      await setErrorData(logHandled)
      setIsViewLog(true)
    } catch (error) {
      notificationError({
        message: 'Não foi possível carregar os logs',
        position: 'top-right',
      })
    } finally {
      setIsLoadingLogs(false)
    }
  }

  const crumbs = [
    { label: 'API Management', value: 'apis' },
    { label: 'Logs', value: 'logs' },
    { label: `${apiName}`, value: `${id}` },
  ]

  const crumbswithoutApi = [
    { label: 'API Management', value: 'apis' },
    { label: 'Logs', value: 'logs' },
  ]

  const handleClickCrumb = (crumb: string) => {
    if (crumb === 'apis') {
      history.push(`/apis`)
    }
  }

  const columns = [
    { key: 'project', label: 'Projeto', isOrder: true, columnWidth: '300px' },
    { key: 'tenant', label: 'Tenant', isOrder: true, columnWidth: '300px' },
    { key: 'success', label: 'Quantidade de sucesso' },
    { key: 'error', label: 'Quantidade de erros' },
    { key: 'opt', label: 'Opções' },
  ]

  const handleChangePage = (page: number) => {
    setCurrentPage(page)
  }

  const handleOrderBy = (colum: string) => {
    const validOrderBy = {
      project: 'automation_id',
      tenant: 'tenant_id',
    }

    setFilter(validOrderBy[colum])
  }

  const handleCloseModal = () => {
    setIsViewLog(false)
  }

  const handleViewLog = itemRow => {
    const findLogs = logs.find(item => item.id === itemRow.id)

    if (findLogs && findLogs.error) {
      findLogError(findLogs.id)
    }
  }

  const handleResearch = () => {
    findApiLogs(id)
  }

  return (
    <>
      <Loading isOpen={isLoading || isLoadingLogs} text="Carregando logs..." />
      <Container>
        <InternalHeader
          crumbs={logs.length ? crumbs : crumbswithoutApi}
          onClickBreadCrumbs={handleClickCrumb}
        />
        <Content>
          {!isLoading ? (
            <TableContainer>
              <Table
                noContentText="Nenhum resultado encontrado."
                tableData={logs}
                tableHeaderOptions={columns}
                orderBy={['project', 'tenant']}
                sortTableData={handleOrderBy}
                optionsActions={[
                  <BsEye {...theme.icon} onClick={handleViewLog} />,
                ]}
                buttonReload={
                  <Button
                    colorType="green"
                    variant="outlined"
                    text="Recarregar"
                    icon={<BsArrowClockwise />}
                    onClick={handleResearch}
                  />
                }
              />
            </TableContainer>
          ) : (
            ''
          )}
          {logPagination.totalPages > 1 && (
            <Pagination
              size="default"
              totalPages={logPagination.totalPages}
              page={currentPage}
              onChange={(page: number) => handleChangePage(page)}
            />
          )}
        </Content>
      </Container>
      <LogsModal
        isOpen={isViewLog}
        projectName={apiName}
        handleCloseModal={handleCloseModal}
        errorData={errorData}
      />
    </>
  )
}

export { ApiLogs }
