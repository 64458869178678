import styled, { css } from 'styled-components'

export const Container = styled.div`
  overflow: auto;
  height: 100%;
`

const ChartStyle = css`
  label {
    font-weight: bold;
    display: inline-block;
    margin: 2rem 0 0.5rem 0;
  }
`

export const ChartsSection = styled.section`
  ${ChartStyle}
  padding: 20px 50px 40px 50px;
  animation: animate 1500ms;
  @keyframes animate {
    from {
      opacity: 0;
      transform: translateY(100px);
    }
    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }
`

export const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 2rem;
  @media (max-width: 1280px) {
    flex-wrap: wrap;
  }
`
