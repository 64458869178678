import styled from 'styled-components'

export const Container = styled.div`
  height: 420px;
  width: 780px;
`

export const InputContainer = styled.div`
  margin-bottom: 45px;
`
export const ModalActions = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;

  button {
    margin-right: 15px;
  }
`
