import React from 'react'
import { ICardInfoProps } from './CardInfo.interface'
import {
  ContainerCard,
  LogoContainer,
  Logo,
  InfoContainer,
  ConnectorName,
  TextInfo,
} from './Cardinfo.style'
import logoDevapi from 'src/assets/images/generic-connector-logo.svg'

export const CardInfo = ({
  logoUrl,
  autentication,
  urlBase,
  connectorName,
}: ICardInfoProps) => {
  const logo = logoUrl || logoDevapi

  return (
    <ContainerCard>
      <LogoContainer>
        <Logo src={logo} />
      </LogoContainer>
      <InfoContainer>
        <ConnectorName>{connectorName}</ConnectorName>
        <TextInfo>
          URL Base: <span>{urlBase}</span>
        </TextInfo>
        <TextInfo>
          Autenticação: <span>{autentication || ''}</span>
        </TextInfo>
      </InfoContainer>
    </ContainerCard>
  )
}
