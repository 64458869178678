import { GrFormNextLink, GrFormPreviousLink } from 'react-icons/gr'
import styled from 'styled-components'

export const AutenticationContainer = styled.div`
  margin-top: 30px;
`
export const AuthSelectContainer = styled.div`
  width: 50%;
`

export const DinamicInputContainer = styled.div`
  width: 100%;

  display: flex;
  /* justify-content: space-between; */
  align-items: flex-end;

  margin-top: 30px;
`
export const InputContainer = styled.div`
  width: 40%;
  height: 76px;

  margin-right: 20px;
`

export const DeleteContainer = styled.div`
  height: 58px;

  width: 48px;

  display: flex;
  align-items: center;

  cursor: pointer;
`
export const SwitchContainer = styled.div`
  height: 58px;
  margin-bottom: 18px;

  display: flex;

  flex-direction: column;
  justify-content: space-between;

  margin-right: 20px;
`

export const SwitchLabel = styled.label`
  font-weight: 700;
  color: #2c2d66;
`

export const ButtonContainer = styled.div`
  margin-top: 30px;
`

export const FormButtons = styled.div`
  width: 100%;

  margin-top: 30px;

  display: flex;

  justify-content: space-between;
`

export const PrevNextButtons = styled.div`
  display: flex;
  button {
    margin-left: 15px;
  }
`

export const NextIcon = styled(GrFormNextLink)`
  path {
    stroke: #fff;
  }
`

export const PrevIcon = styled(GrFormPreviousLink)`
  path {
    stroke: #fff;
  }
`
