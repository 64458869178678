import api from 'src/services/api'
import { IConnectorData } from '../@types/adapters.interface'
import { ICreateAutomationPayload } from '../@types/providers.interface'

export const createAutomation = async ({
  name,
  connectors,
}: ICreateAutomationPayload) => {
  return await api.post<IConnectorData>('/automations', { name, connectors })
}
