import api from 'src/services/api'
import {
  IUserSearchFilters,
  IUserFindAllResponse,
} from '../@types/users.interface'

export const findAllUsers = async (
  currentPage = 1,
  searchFilters: IUserSearchFilters = { search: '' },
) => {
  const response = await api.get<IUserFindAllResponse>('/companies/users', {
    params: {
      limit: 20,
      page: currentPage,
      ...searchFilters,
    },
  })
  const { content, page } = response.data
  return {
    content,
    page,
  }
}
