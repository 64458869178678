import theme from 'src/assets/styles/theme'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  max-height: 36px;
`

export const IconContainer = styled.div`
  height: 36px;
  width: 36px;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid;
  border-color: ${theme.colors.gray[300]};
  border-radius: 4px;

  margin-right: 10px;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`
