import React from 'react'
import { ILimitJobProps } from './LimitJob.interface'
import { Container, Progress, LabelContainer } from './LimitJob.style'

const LimitJob = ({ value, total }: ILimitJobProps) => {
  const progress = (value * 100) / total

  return (
    <Container>
      <LabelContainer>
        <p>Limite de jobs</p>
        <span>{`${value}/${total}`}</span>
      </LabelContainer>
      <Progress progress={`${progress}%`}>
        <div />
      </Progress>
    </Container>
  )
}

export { LimitJob }
