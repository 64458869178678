import theme from 'src/assets/styles/theme'
import styled from 'styled-components'

export const Container = styled.div`
  button {
    width: 100%;
    height: 57px;
  }
`

export const Title = styled.h2`
  margin-bottom: 25px;
  text-align: center;
  font-weight: normal;
  color: ${theme.colors.blue[300]};
  font-size: 2rem;
`

export const Text = styled.p`
  margin-bottom: 20px;
  text-align: center;
  color: ${theme.colors.gray[700]};
  font-size: 1.125rem;
`

export const Icon = styled.div`
  display: block;
  width: 152px;
  height: 152px;
  background: ${theme.colors.blue[300]};
  -moz-border-radius: 152px;
  -webkit-border-radius: 152px;
  border-radius: 152px;
  margin: 0 auto 24px;
  padding: 44px;

  img {
    font-size: 65px;
    line-height: 65px;
    width: 65px;
    height: 65px;
    display: block;
    margin: 0 auto;
    color: #fff;
  }
`
