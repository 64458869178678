import React from 'react'
import { useDashboard } from '../../contexts/DashboardContext'
import { Box } from '@devapi/design-system'
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts'
import { ChartNoDataMessage } from '../'

export const ChartJobsConsumption = ({ title }: { title: string }) => {
  const { jobs } = useDashboard()

  return (
    <>
      <label>{title}</label>
      <Box>
        {jobs?.consumption.length ? (
          <ResponsiveContainer height={400}>
            <AreaChart data={jobs.consumption}>
              <defs>
                <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#5f47ff" stopOpacity={1} />
                  <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.1} />
                </linearGradient>
              </defs>
              <XAxis dataKey="date" tickLine={false} axisLine={false} />
              <YAxis tickLine={false} axisLine={false} />
              <Tooltip cursor={false} />
              <Area
                dataKey="total"
                type="monotone"
                fill="url(#gradient)"
                stroke="#5f47ff"
                strokeWidth={3}
                legendType="circle"
                dot
              />
            </AreaChart>
          </ResponsiveContainer>
        ) : (
          <ChartNoDataMessage />
        )}
      </Box>
    </>
  )
}
