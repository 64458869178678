import styled from 'styled-components'

export const Container = styled.div`
  margin: 2rem 0 0 0;
  width: 700px;
  height: 300px;
`

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 300px;
`

export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin: 1rem 0 0 0;
`

export const Form = styled.form``

export const FormActions = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-top: auto;
`
