import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;
  height: 100%;

  > div {
    padding: 30px;
  }
`

export const PlanContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 17vw;
  height: 100%;

  @media only screen and (max-width: 1400px) {
    width: 19vw;
  }
`
export const HistoryPaymentContainer = styled.div`
  width: 42vw;
  height: 100%;

  @media only screen and (max-width: 1400px) {
    width: 33vw;
  }
`
export const Title = styled.p`
  font-size: 1.375rem;
  font-weight: 700;
`
