import React from 'react'
import devapiIcon from '../../../assets/images/devapi-login.svg'

import {
  Container,
  Devapi,
  ImageContainer,
  FormContainer,
} from './AuthLayout.styles'
import { IAuthLayoutProps } from './AuthLayout.interface'

const AuthLayout = ({ children }: IAuthLayoutProps) => {
  return (
    <Container>
      <ImageContainer>
        <Devapi src={devapiIcon} alt="devapi-icon" />
      </ImageContainer>
      <FormContainer>{children}</FormContainer>
    </Container>
  )
}

export default AuthLayout
