import { IAuthentication, IParams } from '../@types'

export const handleAuthorizationPayload = (data: IAuthentication) => {
  const { type } = data

  const types = {
    basic: 'BASIC',
    header: 'HEADER',
    noAuth: 'NO_AUTH',
    oauth2: 'OAUTH2',
    querystring: 'QUERY_STRING',
  }

  const params = data[type].fields?.map((field: IParams) => {
    return {
      field_name: field.fieldName,
      display_name: field.displayName,
      required: field.required,
      fixed: field.fixed,
    }
  })

  return {
    type: types[type],
    params: params,
  }
}
