import styled, { css } from 'styled-components'

import theme from '../../assets/styles/theme'
import {
  IOptionsHeaderStyle,
  IMenuDropDownStyle,
  IAvatarIconStyle,
} from './Header.interface'

export const Container = styled.header`
  position: fixed;
  width: 100%;
  top: 0;
  background-color: ${theme.colors.secondary};
  display: grid;
  grid-template-columns: 4fr 4fr 1fr;
  grid-template-areas: 'white_space plan options';
  color: ${theme.colors.white};
  font-size: 0.9rem;
  padding: 5px 0;
  z-index: 1;
`

export const PlanAlert = styled.div`
  grid-area: plan;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-weight: bold;
  }
`

export const PlanUpdateButton = styled.button`
  background-color: ${theme.colors.green};
  font-size: 0.8rem;
  font-weight: bold;
  color: ${theme.colors.white};
  border: none;
  border-radius: 4px;
  height: 75%;
  margin-left: 20px;
  cursor: pointer;
`

export const OptionsHeaderContainer = styled.div`
  grid-area: options;
  grid-column: 4;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin-right: 1.5vw;
  cursor: pointer;
`

export const OptionsHeader = styled.div<IOptionsHeaderStyle>`
  height: 60%;
  display: flex;
  align-items: center;
  padding: 0 15px;

  ${props =>
    props.isBorderRight &&
    css`
      border-right: 1px solid ${theme.colors.white};
    `}
`

export const UseTermsLink = styled.a`
  color: inherit;
  text-decoration: none;
`

export const AvatarIcon = styled.div<IAvatarIconStyle>`
  background-image: url(${props => props.imageAvatarUrl});
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: 150%;
  border-radius: 30px;
  width: 30px;
  height: 30px;
`

export const MenuDropdow = styled.div<IMenuDropDownStyle>`
  border: 1px solid ${theme.colors.gray[200]};
  border-radius: 4px;
  background-color: ${theme.colors.white};
  position: fixed;
  right: ${props => (props.type === 'perfil' ? '3vw' : '120px')};
  margin-top: 45px;
  box-shadow: 0px 0px 20px #2c2d661a;
  font-size: 1rem;
  padding: 0 7px;
  z-index: 99;
`

export const LanguageOption = styled.div`
  font-size: 1rem;
  display: flex;
  height: 20px;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  padding: 5px 15px;
  cursor: pointer;
  width: 100%;

  :hover {
    background-color: ${theme.colors.gray[100]};
    border-radius: 4px;
  }
`

export const UserNameEmail = styled.div`
  margin-top: 10px;
  padding: 10px;
  border-bottom: 1px solid ${theme.colors.gray[200]};
`

export const UserName = styled.div`
  margin-bottom: 5px;
`

export const UserEmail = styled.div`
  color: ${theme.colors.gray[400]};
  margin-bottom: 10px;
`

export const OptionButtonPerfil = styled.div`
  height: 20px;
  display: flex;
  align-items: center;
  height: 30px;
  margin: 10px 0;
  padding: 10px;
  cursor: pointer;

  & > svg {
    margin-right: 10px;
  }

  :hover {
    background-color: ${theme.colors.gray[100]};
    border-radius: 4px;
  }
`

export const OptionButtonLogout = styled.div`
  color: ${theme.colors.red};
  display: flex;
  align-items: center;
  height: 30px;
  margin: 10px 0;
  padding: 10px;
  cursor: pointer;

  & > svg {
    margin-right: 10px;
  }

  :hover {
    background-color: ${theme.colors.gray[100]};
    border-radius: 4px;
  }
`
