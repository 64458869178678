import { IRepositoryResponse } from '../@types/providers.interface'

export const handleRepositorySelect = (repository: IRepositoryResponse) => {
  const automation = repository.groups[0].map(item => {
    return item.routes.map(subItem => ({
      name: subItem.name,
      id: subItem.id,
    }))
  })

  return automation[0].map(automation => ({
    value: automation.id,
    label: automation.name,
  }))
}
