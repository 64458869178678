import React, { useEffect } from 'react'
import * as Yup from 'yup'
import {
  Button,
  InputText,
  Modal,
  notificationError,
  notificationInfo,
  TextArea,
} from '@devapi/design-system'
import { FormikHelpers, FormikProvider, useFormik } from 'formik'
import { BsCheck } from 'react-icons/bs'
import { IEditTenantProps } from './EditTenant.interface'
import {
  FormContainer,
  InputContainer,
  ModalActions,
  ModalContent,
} from './EditTenant.style'
import { IUpdateTenantPayload } from '../../@types/provider.interface'
import { updateTenant } from '../../providers/tenant.update'

const EditTenant = ({
  editingTenant,
  handleToggleModal,
  isOpen,
  onSuccess,
}: IEditTenantProps) => {
  const onSubmit = async (
    values: IUpdateTenantPayload,
    { resetForm }: FormikHelpers<IUpdateTenantPayload>,
  ) => {
    try {
      await updateTenant(values)
      handleToggleModal()
      onSuccess()
      notificationInfo({
        message: 'Tenant atualizado com sucesso',
        position: 'top-right',
      })
      resetForm({ values: { id: '', name: '', description: '' } })
    } catch (error) {
      notificationError({
        message:
          error.response?.data?.message || 'Não foi possivel atualizar tenant.',
        position: 'top-right',
      })
    }
  }

  const schema = Yup.object().shape({
    name: Yup.string().required('Nome Obrigatório'),
    description: Yup.string().required('Descrição Obrigatório'),
  })

  const formik = useFormik({
    validateOnMount: false,
    initialValues: {
      id: '',
      name: '',
      description: '',
    },
    validationSchema: schema,
    onSubmit,
  })

  useEffect(() => {
    formik.setValues({
      id: editingTenant._id,
      name: editingTenant.name,
      description: editingTenant.description,
    })
  }, [editingTenant, isOpen])

  return (
    <Modal
      title="Editando Tenant"
      fontSize="26px"
      isOpen={isOpen}
      onClick={() => handleToggleModal()}
    >
      <ModalContent>
        <FormikProvider value={formik}>
          <FormContainer onSubmit={formik.handleSubmit}>
            <InputContainer>
              <InputText
                label="Nome"
                name="name"
                placeHolder="Nome"
                value={formik.values.name || ''}
                onChange={formik.handleChange}
                errorMessage={formik.errors.name}
                errorForm={!!formik.errors.name}
              />
            </InputContainer>
            <InputContainer>
              <TextArea
                name="description"
                label="Descrição"
                placeHolder="Descrição"
                columns={20}
                rows={10}
                onChange={formik.handleChange}
                value={formik.values.description || ''}
                errorMessage={formik.errors.description}
                isErrorForm={!!formik.errors.description}
              />
            </InputContainer>
            <ModalActions>
              <Button
                onClick={handleToggleModal}
                type="reset"
                colorType="neutral"
                variant="outlined"
                text="Cancelar"
              />
              <Button
                role="button-save"
                variant="default"
                colorType="green"
                type="submit"
                text="Salvar"
                icon={<BsCheck />}
              />
            </ModalActions>
          </FormContainer>
        </FormikProvider>
      </ModalContent>
    </Modal>
  )
}

export { EditTenant }
