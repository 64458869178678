import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
`

export const NewResourceControl = styled.div`
  display: flex;

  align-items: center;

  width: 100%;

  margin-bottom: 20px;

  button {
    margin-left: 2vw;
  }
`
export const InputContainer = styled.div`
  min-width: 5vw;

  margin-right: 2vw;
`

export const CheckBoxContainer = styled.div`
  display: flex;

  min-width: 31vw;

  flex-wrap: wrap;
  justify-content: space-between;
`

export const Title = styled.h1`
  width: 100%;

  font-size: 16px;
  font-weight: 700;

  margin-bottom: 10px;
`

export const FormContainer = styled.div`
  min-height: 40vh;
  display: flex;
  flex-direction: column;
`

export const FormActions = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
`

export const Actions = styled.div`
  display: flex;
  gap: 1rem;
`
