/* eslint-disable camelcase */
import React, { useState } from 'react'
import {
  Container,
  InputContainer,
  Form,
  Title,
  ButtonContainer,
} from './UpdatePassword.style'

import { InputText, Button } from '@devapi/design-system'

import { FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup'
import { IUpdatePassData } from '../@types/UpdatePass.interface'
import UseTokenData from 'src/hooks/UseTokenData/UseTokenData'
import { putPass } from '../providers'

export const UpdatePassword = () => {
  const [loadingForm, setLoadingForm] = useState(false)

  const tokenData = UseTokenData()

  const validationSchema = Yup.object().shape({
    atual_password: Yup.string()
      .min(8, 'Mínimo de 8 digitos')
      .required('Senha atual obrigatório'),
    new_password: Yup.string()
      .min(8, 'Mínimo de 8 digitos')
      .required('Nova senha obrigatório'),
    confirm_new_password: Yup.string()
      .min(8, 'Mínimo de 8 digitos')
      .required('Confirmar senha obrigatório')
      .oneOf([Yup.ref('new_password'), null], 'As senhas devem ser iguais'),
  })

  const onSubmit = async (values: IUpdatePassData, { resetForm }) => {
    setLoadingForm(true)
    await putPass(values, tokenData.user_id)
    setLoadingForm(false)
    resetForm()
  }

  const formik = useFormik({
    initialValues: {
      atual_password: '',
      new_password: '',
      confirm_new_password: '',
    },
    validationSchema,
    onSubmit,
  })

  return (
    <Container>
      <Title>
        Alterar Senha <p>Para alterar sua senha preencha os campos abaixo</p>
      </Title>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit} noValidate>
          <InputContainer>
            <InputText
              name={'atual_password'}
              label={'Senha Atual'}
              placeHolder={'Senha Atual'}
              type={'password'}
              disabled={!!loadingForm}
              errorForm={!!formik.errors.atual_password}
              errorMessage={formik.errors.atual_password}
              onChange={formik.handleChange}
              value={formik.values.atual_password}
            />
          </InputContainer>
          <InputContainer>
            <InputText
              name={'new_password'}
              label={'Nova Senha'}
              placeHolder={'Nova Senha'}
              type={'password'}
              disabled={!!loadingForm}
              errorForm={!!formik.errors.new_password}
              errorMessage={formik.errors.new_password}
              onChange={formik.handleChange}
              value={formik.values.new_password}
            />
          </InputContainer>
          <InputContainer>
            <InputText
              name={'confirm_new_password'}
              label={'Confirmar Nova Senha'}
              placeHolder={'Confirmar Nova Senha'}
              type={'password'}
              disabled={!!loadingForm}
              errorForm={!!formik.errors.confirm_new_password}
              errorMessage={formik.errors.confirm_new_password}
              onChange={formik.handleChange}
              value={formik.values.confirm_new_password}
            />
          </InputContainer>
          <ButtonContainer>
            <Button
              variant={'default'}
              text={loadingForm ? 'Enviando...' : 'Alterar senha'}
              isDisabled={!!loadingForm}
              colorType={'green'}
              type={'submit'}
            />
          </ButtonContainer>
        </Form>
      </FormikProvider>
    </Container>
  )
}
