import { IJobDetailsResponse } from '../@types/providers.interface'
import { IJobDetails } from '../presentation/edit/EditJob.interface'

export const handleJobDetails = (job: IJobDetailsResponse): IJobDetails => ({
  id: job._id,
  name: job.name,
  automationFile: job.deploy_path,
  codeParams: JSON.stringify(job.params),
  isNotification: job.allow_notification === 'ACTIVE',
  projectId: job.automation_id,
  tenantId: job.tenant_id,
  timeType: job.time_type,
  timeValue: job.time_value,
  hours: job.time_hour,
})
