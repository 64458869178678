import styled from 'styled-components'

export const ClipboardArea = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  padding: 5px;
  cursor: pointer;
  opacity: 1;
  :active {
    animation: animate 150ms ease-in-out;
  }
  @keyframes animate {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.05);
      opacity: 0.5;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
`

export const Text = styled.p``
