import React, { useState } from 'react'
import {
  Button,
  Checkbox,
  InputText,
  notificationWarning,
} from '@devapi/design-system'
import { Form, FormikProvider, useFormik } from 'formik'
import {
  AiOutlinePlusCircle,
  AiOutlineArrowLeft,
  AiOutlineCheck,
} from 'react-icons/ai'
import { NewResourceColapse } from '../NewResourceColapse'
import { IGroups, IResourceFormProps } from '../../@types'
import {
  Container,
  NewResourceControl,
  InputContainer,
  CheckBoxContainer,
  Title,
  FormContainer,
  FormActions,
  Actions,
} from './ResourceForm.style'
import { validateResource } from '../../utils'

export const ResourceForm = ({
  initialValues,
  handleSetResource,
  handleClose,
  handleChangeStep,
  privacy,
}: IResourceFormProps) => {
  const [name, setName] = useState('')
  const [methods, setMethods] = useState<string[]>([
    'get',
    'put',
    'getById',
    'post',
    'delete',
  ])
  const [isAllMethodsCheck, setIsAllMethodsCheck] = useState(true)

  const handleCheck = (value: string) => {
    if (!methods.includes(value)) {
      setMethods([...methods, value])
    } else {
      const updatedList = methods.filter(method => method !== value)
      setMethods(updatedList)
    }
  }

  const handleCheckAll = () => {
    if (!isAllMethodsCheck) {
      setMethods(['get', 'put', 'getById', 'post', 'delete'])
    } else {
      setMethods([])
    }
    setIsAllMethodsCheck(!isAllMethodsCheck)
  }

  const handleChangeName = (value: string) => {
    setName(value)
  }

  const methodsPayload = {
    get: {
      route: `${name}/`,
      method: 'GET',
      title: '',
      description: '',
      params: [],
      concatRoute: '',
    },
    getById: {
      route: `${name}/`,
      method: 'GET',
      title: '',
      description: '',
      params: [],
      concatRoute: '{id}',
    },
    post: {
      route: `${name}/`,
      method: 'POST',
      title: '',
      description: '',
      params: [],
      concatRoute: '',
    },
    put: {
      route: `${name}/`,
      method: 'PUT',
      title: '',
      description: '',
      params: [],
      concatRoute: '',
    },
    delete: {
      route: `${name}/`,
      method: 'DELETE',
      title: '',
      description: '',
      params: [],
      concatRoute: '',
    },
  }

  const handleAdd = () => {
    const formikValues = formik?.values || formik.initialValues
    if (methods.length && name !== '') {
      if (formikValues && formikValues.resources) {
        const newValues = validateResource(
          formikValues.resources,
          name,
          methods,
          methodsPayload,
        )
        formik.setValues({ resources: newValues })
      }
    }
  }

  const handleDeleteRoute = (resourceIdx: number, routeIdx: number) => {
    const formikValues = formik.values
    console.log(formikValues)
    if (formikValues && formikValues.resources) {
      formikValues.resources[resourceIdx].routes = formikValues.resources[
        resourceIdx
      ].routes.filter((route, idx) => idx !== routeIdx)
    }
    console.log(formikValues)

    formik.setValues(formikValues)
  }

  const handleSubmit = values => {
    if (values.resources.length) return handleSetResource(values.resources)
    notificationWarning({
      message: 'Inclua pelo menos um recurso',
      position: 'top-right',
    })
  }

  const isChecked = (value: string) => {
    return !!methods.find(method => method === value)
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { resources: initialValues },
    onSubmit: handleSubmit,
  })

  const handleAddParams = (resourceIdx: number, routeIdx: number) => {
    const { resources } = formik.values

    if (!resources[resourceIdx].routes[routeIdx].params) {
      resources[resourceIdx].routes[routeIdx].params = []
    }
    resources[resourceIdx].routes[routeIdx].params.push({
      type: '',
      fieldName: '',
      displayName: '',
      description: '',
      required: false,
    })
    formik.setFieldValue('resources', resources)
  }

  const handleDeleteParams = (
    resourceIdx: number,
    routeIdx: number,
    paramsIdx: number,
  ) => {
    const formikValues = formik.values
    if (methods.length && name !== '') {
      if (formikValues && formikValues.resources) {
        formikValues.resources[resourceIdx].routes[routeIdx].params =
          formikValues.resources[resourceIdx].routes[routeIdx].params.filter(
            (params, idx) => idx !== paramsIdx,
          )
      }
      formik.setValues(formikValues)
    }
  }

  const handleSelectParametersType = (
    resourceIdx: number,
    routeIdx: number,
    paramsIdx: number,
    paramType: string,
  ) => {
    const formikValues = formik.values
    formikValues.resources[resourceIdx].routes[routeIdx].params[
      paramsIdx
    ].type = paramType
    formik.setValues(formikValues)
  }

  const handleParametersRequired = (
    resourceIdx: number,
    routeIdx: number,
    paramsIdx: number,
  ) => {
    const formikValues = formik.values
    formikValues.resources[resourceIdx].routes[routeIdx].params[
      paramsIdx
    ].required =
      !formikValues.resources[resourceIdx].routes[routeIdx].params[paramsIdx]
        .required
    formik.setValues(formikValues)
  }

  const checkRoutesResource = (resource: IGroups) => {
    if (!resource.routes.length)
      formik.values.resources = formik.values.resources.filter(
        filteringResource => filteringResource.name !== resource.name,
      )
  }

  return (
    <Container>
      <Title>Novo Recurso</Title>
      <NewResourceControl>
        <InputContainer>
          <InputText
            name={'resourceName'}
            onChange={e => handleChangeName(e.target.value)}
            disabled={privacy === 'PUBLIC'}
          />
        </InputContainer>
        <CheckBoxContainer>
          <Checkbox
            isChecked={isChecked('get')}
            label={'GET'}
            isDisabled={privacy === 'PUBLIC'}
            value={'get'}
            onChange={e => handleCheck(e.target.value)}
          />
          <Checkbox
            isChecked={isChecked('getById')}
            label={'GET BY ID'}
            isDisabled={privacy === 'PUBLIC'}
            value={'getById'}
            onChange={e => handleCheck(e.target.value)}
          />
          <Checkbox
            isChecked={isChecked('post')}
            label={'POST'}
            isDisabled={privacy === 'PUBLIC'}
            value={'post'}
            onChange={e => handleCheck(e.target.value)}
          />
          <Checkbox
            isChecked={isChecked('put')}
            label={'PUT'}
            isDisabled={privacy === 'PUBLIC'}
            value={'put'}
            onChange={e => handleCheck(e.target.value)}
          />
          <Checkbox
            isChecked={isChecked('delete')}
            label={'DELETE'}
            isDisabled={privacy === 'PUBLIC'}
            value={'delete'}
            onChange={e => handleCheck(e.target.value)}
          />
          <Checkbox
            isChecked={isAllMethodsCheck}
            label={'TODOS'}
            isDisabled={privacy === 'PUBLIC'}
            value={'todos'}
            onChange={handleCheckAll}
          />
        </CheckBoxContainer>

        <Button
          type={'button'}
          colorType={'green'}
          variant={'default'}
          text={'Adicionar'}
          icon={<AiOutlinePlusCircle size={20} />}
          onClick={handleAdd}
          isDisabled={privacy === 'PUBLIC'}
        />
      </NewResourceControl>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <FormContainer>
            {formik.values.resources?.map((resource, idx) => {
              checkRoutesResource(resource)
              return (
                <NewResourceColapse
                  key={idx}
                  privacy={privacy}
                  resources={resource.routes}
                  name={resource.name}
                  resourceIdx={idx}
                  handleDeleteRoute={handleDeleteRoute}
                  handleAddParam={handleAddParams}
                  handleDeleteParams={handleDeleteParams}
                  handleParametersRequired={handleParametersRequired}
                  handleSelectParametersType={handleSelectParametersType}
                />
              )
            })}
          </FormContainer>
          <FormActions>
            <Button
              type="reset"
              text="Cancelar"
              colorType="neutral"
              variant="outlined"
              onClick={handleClose}
            />
            <Actions>
              <Button
                text="Anterior"
                colorType="green"
                variant="default"
                icon={<AiOutlineArrowLeft />}
                onClick={() => handleChangeStep('params')}
              />
              <Button
                type="submit"
                text="Salvar"
                colorType="green"
                variant="default"
                icon={<AiOutlineCheck />}
                isDisabled={privacy === 'PUBLIC'}
              />
            </Actions>
          </FormActions>
        </Form>
      </FormikProvider>
    </Container>
  )
}
