import { IAuthentication, IParamsPayload } from '../@types'
export const handleFormatAuthorization = (
  type: string,
  params: IParamsPayload[],
) => {
  let typeFormatted = type

  if (type.includes('_')) {
    typeFormatted = type.replace(/([-_][a-z])/gi, $1 => {
      return $1.toUpperCase().replace('-', '').replace('_', '')
    })
  }

  const authTypes: IAuthentication = {
    type: typeFormatted,
    noAuth: [],
    basic: {
      fields: [
        { fieldName: 'user', displayName: '', required: true },
        { fieldName: 'password', displayName: '', required: true },
      ],
    },
    header: {
      fields: [{ fieldName: '', displayName: '', required: true }],
    },
    querystring: {
      fields: [{ fieldName: '', displayName: '', required: false }],
    },
    oauth2: {
      fields: [
        {
          fieldName: 'redirect_uri',
          displayName: '',
          required: true,
          fixed: true,
        },
        {
          fieldName: 'auth_url',
          displayName: '',
          required: true,
          fixed: true,
        },
        {
          fieldName: 'access_token_url',
          displayName: '',
          required: true,
        },
        {
          fieldName: 'client_id',
          displayName: '',
          required: true,
        },
        {
          fieldName: 'client_secret',
          displayName: '',
          required: true,
        },
        {
          fieldName: 'scope',
          displayName: '',
          required: false,
        },
        {
          fieldName: 'state',
          displayName: '',
          required: false,
        },
        {
          fieldName: 'client_authentication',
          displayName: '',
          required: true,
        },
        {
          fieldName: 'authorization_data',
          displayName: '',
          required: true,
        },
      ],
    },
  }

  if (params.length && typeFormatted !== 'noAuth') {
    authTypes[type].fields = params.map(param => {
      return {
        displayName: param.display_name,
        fieldName: param.field_name,
        required: param.required,
        ...(param.fixed && { fixed: param.fixed }),
      }
    })
  }

  return authTypes
}
