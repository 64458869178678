import styled from 'styled-components'

export const Container = styled.div``

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 40px;

  > button {
    width: 200px;

    > span {
      font-size: 1rem;
    }
  }
`

export const ConnectorsListContainer = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  padding: 20px;
`

export const ConnectorContainer = styled.div`
  margin: 8px;
`
export const CheckBoxContainer = styled.div`
  height: 38px;

  display: flex;

  align-items: center;
`
