import React, { useState } from 'react'
import { IClipboardProps } from './Clipboard.interface'
import { ClipboardArea, Text } from './Clipboard.style'
import { FiCopy } from 'react-icons/fi'

export const Clipboard = ({ text, onClick }: IClipboardProps) => {
  const [isVisible, setIsVisible] = useState(false)

  const handleClipboardMessage = async (callBack: () => void) => {
    setIsVisible(true)
    callBack()
    setTimeout(() => {
      setIsVisible(false)
    }, 2000)
  }

  return (
    <ClipboardArea
      onClick={() => handleClipboardMessage(onClick)}
      data-testid="clipboard-container"
    >
      <Text>{text}</Text>
      {isVisible ? <Text>Copiado!</Text> : <FiCopy />}
    </ClipboardArea>
  )
}
