import api from 'src/services/api'

export const findDefaultParametersById = async (
  defaultParamtersId: string,
  connectorId: string,
) => {
  return await api.get(
    `/connectors/${connectorId}/default-params/${defaultParamtersId}`,
  )
}
