import theme from 'src/assets/styles/theme'
import styled from 'styled-components'
import { ILogModalProps, IStatusCardStyle } from './LogsModal.interface'

export const Container = styled.div<ILogModalProps>`
  display: ${props => (props.isOpen ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 9999;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
`

export const Content = styled.div`
  display: flex;

  background-color: ${theme.colors.white};
  border-top: 1px solid ${theme.colors.gray[300]};
`

export const Details = styled.div`
  overflow: auto;

  width: 700px;
  max-height: 50vh;
`

export const RequestDetails = styled.div`
  border-top: 1px solid ${theme.colors.gray[300]};
  padding-top: 25px;
  padding-bottom: 15px;
`

export const Text = styled.p`
  width: 100%;
  word-wrap: break-word;
  margin-bottom: 5px;
  font-weight: bold;

  span {
    font-weight: 400;
  }
`

export const ErrorDataContainer = styled.div`
  padding-top: 15px;
  border-top: 1px solid ${theme.colors.gray[300]};
`

export const Title = styled.p`
  margin-bottom: 10px;
  font-weight: bold;
`

export const HeadersContainer = styled.div`
  margin-top: 20px;
  padding-top: 15px;
  border-top: 1px solid ${theme.colors.gray[300]};
`

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-bottom: 20px;

  svg {
    color: ${theme.colors.primary};
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
`

export const HeaderTitle = styled.p`
  padding: 0;
  margin: 0;

  color: ${theme.colors.primary};
  font-size: 26px;
  font-weight: 700;
`

export const RequestListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100px;

  padding: 15px;

  background-color: ${theme.colors.gray[300]};
`

export const Log = styled.div`
  padding: 29px;
`

export const StatusCard = styled.div<IStatusCardStyle>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 36px;

  background-color: ${props => props.color};
  border-radius: 8px;
  margin-bottom: 20px;

  cursor: pointer;

  opacity: ${props => (props.isActive ? '1' : '0.5')};

  p {
    margin-bottom: -4px;
    font-weight: 600;
    color: ${theme.colors.white};
  }

  :hover {
    opacity: 1;
  }
`
