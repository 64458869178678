import api from 'src/services/api'
import { ICreatePermissionFormikData } from '../@types/shared.interface'

export const createPermission = async (
  apiId: string,
  values: ICreatePermissionFormikData,
) => {
  const { tenants, description } = values
  await api.post(`/apis/${apiId}/tenants`, {
    tenants: [tenants],
    description,
  })
}
