import React from 'react'
import { Clipboard } from '../components'
import { IFindPermissionsByApiIdResponse } from '../@types/providers.interface'
import { handleClipboardToken } from '../utils'

export const permissionsSerializer = (
  permissions: IFindPermissionsByApiIdResponse,
) => {
  const { content, page } = permissions
  return {
    content: content.map(item => ({
      id: item._id,
      name: item.name,
      token: item.token,
      tokenAbbrev: (
        <Clipboard
          text={`${item.token.substring(0, 50)}...`}
          onClick={() => handleClipboardToken(item.token)}
        />
      ),
      companyId: item.company_id,
      apiId: item.api_id,
      tenantId: item.tenant_id,
      createdAt: new Date(item.created_at).toLocaleDateString('pt-BR'),
    })),
    page,
  }
}
