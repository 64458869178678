import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { FormikProvider, useFormik } from 'formik'
import {
  Button,
  InputText,
  notificationError,
  notificationSuccess,
} from '@devapi/design-system'

import {
  Pipe,
  Title,
  TitleContainer,
  Form,
  InputContainer,
} from './RecoveryPassword.style'
import {
  IRecoveryPasswordFormikValues,
  IRecoveryPasswordParams,
} from './RecoveryPassword.interface'
import api from '../../../services/api'

const RecoveryPassword = () => {
  const { token } = useParams<IRecoveryPasswordParams>()
  const history = useHistory()

  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = async (values: IRecoveryPasswordFormikValues) => {
    try {
      setIsLoading(true)
      await api.put(`/auth/change-password/${token}`, {
        new_password: values.password,
        confirm_new_password: values.confirmPassword,
      })
      history.push('/')
      notificationSuccess({
        message: 'Senha atualizada com sucesso',
        position: 'top-right',
      })
    } catch (error) {
      notificationError({
        message: String(
          error.response?.data?.message ||
            'Ocorreu um erro para atualizar a senha.',
        ).toLowerCase(),
        position: 'top-right',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const schema = Yup.object().shape({
    password: Yup.string()
      .min(8, 'É necessário pelo menos 8 caracteres')
      .required('Senha Obrigatória'),
    confirmPassword: Yup.string()
      .min(8, 'É necessário pelo menos 8 caracteres')
      .when('password', (password, field) =>
        password
          ? field
              .required('Confirmação é obrigatória')
              .oneOf([Yup.ref('password')], 'As senhas não coincidem.')
          : field,
      )
      .required('Confirmação obrigatória'),
  })

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: schema,
    onSubmit,
  })

  return (
    <>
      <TitleContainer>
        <Pipe />
        <Title>
          Redefina <span>sua senha</span>
        </Title>
      </TitleContainer>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <InputContainer>
            <InputText
              name="password"
              label="Nova senha"
              placeHolder="Senha"
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              errorForm={!!formik.errors.password}
              errorMessage={formik.errors.password}
              isFormik
            />
          </InputContainer>
          <InputContainer>
            <InputText
              name="confirmPassword"
              label="Confirmar Senha"
              placeHolder="Confirmação"
              type="password"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              errorForm={!!formik.errors.confirmPassword}
              errorMessage={formik.errors.confirmPassword}
              isFormik
            />
          </InputContainer>
          <Button
            type="submit"
            colorType="green"
            text={isLoading ? 'Enviando...' : 'Salvar nova senha'}
            variant="default"
            isDisabled={isLoading}
          />
        </Form>
      </FormikProvider>
    </>
  )
}

export { RecoveryPassword }
