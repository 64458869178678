import { IContainerProps, ICheckBoxProps } from './AutomationCard.interface'
import theme from 'src/assets/styles/theme'
import styled, { css } from 'styled-components'

export const Container = styled.div<IContainerProps>`
  height: 312px;
  width: 296px;

  margin: 50px;

  border: 2px solid;
  border-color: ${theme.colors.gray[300]};
  border-radius: 5px;
  border-color: ${theme.colors.gray[300]};
  ${props =>
    props.isSelected &&
    css`
      border-color: ${theme.colors.green};
      border-width: 2px;
    `}

  cursor: pointer;

  background-color: ${theme.colors.gray[100]};
`
export const CheckBox = styled.div<ICheckBoxProps>`
  display: flex;

  align-items: center;
  justify-content: center;

  position: relative;

  border-radius: 50%;

  width: 31px;
  height: 31px;

  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${theme.colors.green};
    `};

  top: 20px;
  left: 245px;

  svg {
    color: ${theme.colors.gray[100]};

    ${({ isSelected }) =>
      isSelected &&
      css`
        color: #fff;
      `};

    font-size: 23px;
  }
`

export const IconContainer = styled.div`
  display: flex;

  align-items: center;
  justify-content: center;

  height: 100px;
  width: 100px;

  position: relative;

  top: 50px;
  left: 98px;
`
export const TextContainer = styled.p`
  display: flex;

  position: relative;

  top: 80px;

  justify-content: center;

  width: 100%;

  font-size: 22px;
`
