import { IDefaultParams } from '../@types'

export const handleFormatDefaultParams = (
  data: IDefaultParams[],
  isUpdate: boolean,
): IDefaultParams[] => {
  return data.map(param => {
    return {
      type: isUpdate ? param.type.toUpperCase() : param.type.toLowerCase(),
      name: param.name,
      value: param.value,
    }
  })
}
