import api from 'src/services/api'
import { IUpdateStatusTenant } from '../@types/provider.interface'

export const updateTenantStatus = async ({
  id,
  status,
}: IUpdateStatusTenant) => {
  return await api.patch(`/tenants/${id}/status`, {
    status: status === 'ACTIVE' ? 'DISABLED' : 'ACTIVE',
  })
}
