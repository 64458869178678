import React, { useState } from 'react'
import {
  DeleteAlert,
  Loading,
  notificationError,
  notificationSuccess,
} from '@devapi/design-system'
import { IDeleteApiManagementProps } from './DeleteApiManagement.interface'
import { deleteApi } from '../../providers'

const DeleteApiManagement = ({
  apiId,
  isOpen,
  onClose,
  onSuccess,
}: IDeleteApiManagementProps) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleDeleteApi = async () => {
    try {
      await deleteApi(apiId)
      setIsLoading(true)
      onSuccess()
      notificationSuccess({
        message: 'API deletada com sucesso',
        position: 'top-right',
      })
    } catch (error) {
      if (error.response) {
        const { message } = error.response.data
        notificationError({
          message: message || 'Erro ao deletar API',
          position: 'top-right',
        })
      }
    } finally {
      setIsLoading(false)
      onClose()
    }
  }
  return (
    <>
      {isLoading && <Loading isOpen={isLoading} text="Deletando API..." />}
      {isOpen && (
        <DeleteAlert
          onClickCancel={onClose}
          language="pt-br"
          onClickDelete={handleDeleteApi}
        />
      )}
    </>
  )
}

export { DeleteApiManagement }
