import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { HiOutlineMailOpen, HiArrowLeft } from 'react-icons/hi'
import { IForgotPasswordFormikValues } from './ForgotPassword.interface'
import { FormikProvider, useFormik } from 'formik'
import {
  InputText,
  Button,
  notificationError,
  notificationInfo,
} from '@devapi/design-system'
import {
  Form,
  Pipe,
  Title,
  TitleContainer,
  InputContainer,
  BackToHome,
} from './ForgotPassword.style'
import api from 'src/services/api'

const ForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()

  const onSubmit = async (values: IForgotPasswordFormikValues) => {
    try {
      setIsLoading(true)
      await api.post('/auth/forgot', values)
      notificationInfo({
        message: 'Link de redefinição enviado! Verifique seu e-mail.',
        position: 'top-right',
      })
      history.push('/')
    } catch (error) {
      notificationError({
        message: String(
          error.response?.data?.message || 'Não foi possivel enviar o link.',
        ).toLowerCase(),
        position: 'top-right',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required('E-mail obrigatório')
      .email('Entre com um e-mail válido'),
  })

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit,
  })

  return (
    <>
      <TitleContainer>
        <Pipe />
        <Title>
          Esqueci <span>minha senha</span>
        </Title>
      </TitleContainer>
      <FormikProvider value={formik}>
        <Form
          onSubmit={formik.handleSubmit}
          noValidate
          data-testid="forgot-password-form"
        >
          <InputContainer>
            <HiOutlineMailOpen size="20px" color="#738099" />
            <InputText
              name="email"
              placeHolder="E-mail"
              onChange={formik.handleChange}
              value={formik.values.email}
              errorForm={!!formik.errors.email}
              errorMessage={formik.errors.email}
              isFormik
            />
          </InputContainer>
          <Button
            variant="default"
            colorType="green"
            text={isLoading ? 'Enviando...' : 'Enviar nova senha'}
            isDisabled={isLoading}
            type="submit"
          />
        </Form>
      </FormikProvider>
      <BackToHome to="/">
        <span>
          <HiArrowLeft />
        </span>
        <p>Voltar</p>
      </BackToHome>
    </>
  )
}

export { ForgotPassword }
