import styled from 'styled-components'

export const Container = styled.div`
  padding: 50px;
  overflow: auto;
`

export const AlignCenter = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 3rem 0 0 0;
`
