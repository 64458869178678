import React, { useCallback, useEffect, useState } from 'react'
import * as Yup from 'yup'
import {
  Button,
  Checkbox,
  InputSearch,
  Loading,
  notificationError,
  notificationSuccess,
} from '@devapi/design-system'
import {
  IConnectorSerialized,
  IUpdateApiPayload,
} from 'src/modules/ApiManagement/@types/shared.interface'
import {
  IFindAllConnectorsParams,
  IPaginationResponse,
} from 'src/modules/Automations/@types/providers.interface'
import { FormikProvider, useFormik } from 'formik'

import { connectorsSerializer } from 'src/modules/ApiManagement/adapters/connectorsSerializer'
import { ConnectorsList } from 'src/components/ConnectorsList'
import {
  Container,
  FormContainer,
  ModalActions,
  SearchContainer,
  SearchItem,
  ConnectorsErrorMessage,
  RightContainer,
  CheckBoxContainer,
} from './EditApiFormConnectors.style'
import { BiCheck, BiLeftArrowAlt } from 'react-icons/bi'
import { IEditApiFormConnectorsProps } from './EditApiFormConnectors.interface'
import {
  updateApi,
  findAllConnectors,
} from 'src/modules/ApiManagement/providers'

const EditApiFormConnectors = ({
  formData,
  handleNextStep,
  handleSaveConnectors,
  onClose,
  onSuccess,
}: IEditApiFormConnectorsProps) => {
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const [isPrivateConnectors, setIsPrivateConnectors] = useState(false)
  const [isPublicConnectors, setIsPublicConnectors] = useState(false)
  const [connectorsSelected, setConnectorsSelected] = useState<string[]>([])
  const [connectors, setConnectors] = useState<IConnectorSerialized[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPage, setTotalPage] = useState<number>(1)
  const [pagination, setPagination] = useState({} as IPaginationResponse)
  const [params, setParams] = useState({} as IFindAllConnectorsParams)

  const schema = Yup.object().shape({
    connectors: Yup.array().min(1, 'Selecione pelo menos um conector'),
  })

  const onSubmit = async (values: IUpdateApiPayload) => {
    try {
      setIsSubmitLoading(true)
      await updateApi(values)
      onClose()
      onSuccess()
      notificationSuccess({
        message: 'API atualizada com sucesso',
        position: 'top-right',
      })
    } catch (error) {
      if (error.response) {
        const { message } = error.response.data
        notificationError({
          message: String(message).toLowerCase(),
          position: 'top-right',
        })
      }
    } finally {
      setIsSubmitLoading(false)
    }
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: formData.name,
      description: formData.description,
      url: formData.url,
      connectors: formData.connectors || [],
      id: formData.id,
    },
    validationSchema: schema,
    onSubmit,
  })

  const handleFindAllConnectors = async () => {
    try {
      const response = await findAllConnectors({
        current: pagination.current,
        params,
      })
      if (response.page.current > 1) {
        const formatedConnectors = await connectorsSerializer(response.content)
        formatedConnectors.forEach(connector => {
          setConnectors(values => [...values, connector])
        })
      } else {
        const formatedConnectors = await connectorsSerializer(response.content)

        setConnectors(formatedConnectors)
      }
      setPagination(response.page)
      setCurrentPage(response.page.current)
      setTotalPage(response.page.totalPages)
    } catch (error) {
      notificationError({
        message:
          error.response?.data?.message ||
          'Não foi possivel encontrar conectores.',
        position: 'top-right',
      })
    }
  }

  useEffect(() => {
    if (formData.connectors && formData.connectors.length) {
      setConnectorsSelected(formData.connectors)
    }
  }, [formData])

  useEffect(() => {
    handleFindAllConnectors()
  }, [pagination.current, params])

  const handleCheckConnectorSelected = useCallback(() => {
    if (connectorsSelected) {
      connectorsSelected.forEach(connectorId => {
        const connectorSelectedIndex = connectors.findIndex(
          connector => connector.id === connectorId,
        )
        if (connectorSelectedIndex !== -1)
          connectors[connectorSelectedIndex].isSelected = true
      })
    }
  }, [connectorsSelected, connectors])

  useEffect(() => {
    if (connectors.length) handleCheckConnectorSelected()
  }, [connectors])

  const handleConector = (isSelected: boolean, id: string) => {
    if (isSelected) {
      setConnectorsSelected(oldValue => [...oldValue, id])
      formik.setFieldValue('connectors', [...connectorsSelected, id])

      const connectorIndex = connectors.findIndex(
        connector => connector.id === id,
      )
      connectors[connectorIndex].isSelected = true
      return
    }
    const updateList = connectorsSelected.filter(connector => connector !== id)

    const connectorIndex = connectors.findIndex(
      connector => connector.id === id,
    )
    connectors[connectorIndex].isSelected = false
    setConnectorsSelected(updateList)
    formik.setFieldValue('connectors', updateList)
  }
  const handleNextPage = (newPage: number) => {
    setPagination(values => {
      return { ...values, current: newPage }
    })
  }

  const handleCheckPrivacyPrivate = () => {
    setIsPrivateConnectors(!isPrivateConnectors)
    setParams(values => {
      return { ...values, privacy: isPrivateConnectors ? '' : 'PRIVATE' }
    })
    setPagination(values => {
      return { ...values, current: 1 }
    })
    setIsPublicConnectors(false)
  }

  const handleCheckPrivacyPublic = () => {
    setIsPublicConnectors(!isPublicConnectors)
    setParams(values => {
      return { ...values, privacy: isPublicConnectors ? '' : 'PUBLIC' }
    })
    setPagination(values => {
      return { ...values, current: 1 }
    })
    setIsPrivateConnectors(false)
  }

  const handleSearch = (value: string) => {
    setConnectors([])
    setParams(values => {
      return { ...values, search: value }
    })
  }

  const handleStep = () => {
    handleNextStep('step-form')
    if (connectorsSelected.length) {
      handleSaveConnectors({ connectors: connectorsSelected })
      setConnectorsSelected(() => [])
    }
  }

  return (
    <Container>
      <Loading isOpen={isSubmitLoading} text="Criando API..." />
      <FormContainer>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <SearchContainer>
              <InputSearch
                label="Encontrar conector"
                placeHolder="Pesquisa"
                onChange={handleSearch}
                value={params.search}
              />
              <SearchItem>
                <CheckBoxContainer>
                  <Checkbox
                    label="Conectores privados"
                    value="private"
                    isChecked={isPrivateConnectors}
                    isDisabled={false}
                    onChange={handleCheckPrivacyPrivate}
                  />
                </CheckBoxContainer>
              </SearchItem>
              <SearchItem>
                <CheckBoxContainer>
                  <Checkbox
                    label="Conectores pre-built"
                    value="public"
                    isChecked={isPublicConnectors}
                    isDisabled={false}
                    onChange={handleCheckPrivacyPublic}
                  />
                </CheckBoxContainer>
              </SearchItem>
            </SearchContainer>
            {formik.errors.connectors && (
              <ConnectorsErrorMessage>
                {formik.errors.connectors}
              </ConnectorsErrorMessage>
            )}
            <ConnectorsList
              connectors={connectors}
              handleConector={handleConector}
              currentPage={currentPage}
              totalPages={totalPage}
              handleNextPage={handleNextPage}
            />
            <ModalActions>
              <Button
                type="reset"
                role="button-close"
                colorType="neutral"
                variant="outlined"
                text="Cancelar"
                onClick={onClose}
              />
              <RightContainer>
                <Button
                  role="button-prev"
                  variant="default"
                  colorType="green"
                  type="button"
                  onClick={handleStep}
                  text="Anterior"
                  iconPosition="left"
                  icon={<BiLeftArrowAlt />}
                />
                <Button
                  role="button-save"
                  variant="default"
                  colorType="green"
                  type="submit"
                  text="Salvar"
                  iconPosition="right"
                  icon={<BiCheck />}
                />
              </RightContainer>
            </ModalActions>
          </form>
        </FormikProvider>
      </FormContainer>
    </Container>
  )
}

export { EditApiFormConnectors }
