import React from 'react'
import { IJobLogsResponse } from '../@types/providers.interface'
import { ILogHandlerResponse } from '../@types/shared.interface'
import { Card } from '../presentation/logs/Logs.style'

export const handleTableLogs = (
  logs: IJobLogsResponse,
): ILogHandlerResponse => {
  let logNumber = 0

  if (!logs.content.length)
    return {
      content: [],
      pagination: {
        currentPage: 0,
        totalPages: 0,
      },
      logNumber: 0,
    }

  return {
    content: logs.content.map(log => {
      logNumber = log.errors
      return {
        id: log._id,
        project: log.project || '-',
        tenant: log.tenant || '-',
        success: <Card color="success">{log.success || 0}</Card>,
        error: <Card color="error">{log.errors || 0}</Card>,
      }
    }),
    pagination: {
      currentPage: logs.page.current,
      totalPages: logs.page.totalPages,
    },
    logNumber,
  }
}
