import React from 'react'
import * as Yup from 'yup'
import { FormikProvider, useFormik } from 'formik'
import { BiRightArrowAlt } from 'react-icons/bi'
import { Button, InputGroup, InputText, TextArea } from '@devapi/design-system'

import { Container, InputContainer, ModalActions } from './EditApiForm.style'
import { IDataPayload, IEditApiFormProps } from './EditApiForm.interface'

const EditApiForm = ({
  editingApi,
  onClose,
  handleNextStep,
  handleSubmit,
}: IEditApiFormProps) => {
  const onSubmit = (values: IDataPayload) => {
    handleSubmit(values)
    handleNextStep('step-connectors')
  }
  const schema = Yup.object().shape({
    url: Yup.string().required('URL é obrigatória'),
    name: Yup.string().required('Nome do projeto é obrigatório'),
    description: Yup.string().required('Descrição é obrigatória'),
  })
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: schema,
    initialValues: {
      name: editingApi.name,
      url: editingApi.url,
      description: editingApi.description,
    },
    onSubmit,
  })
  return (
    <Container>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <InputContainer>
            <InputText
              isFormik
              label="Nome do Projeto"
              name="name"
              placeHolder="Nome"
              value={formik.values.name || ''}
              onChange={formik.handleChange}
              errorForm={!!formik.errors.name}
              errorMessage={formik.errors.name}
            />
          </InputContainer>
          <InputContainer>
            <InputGroup
              isFormik
              isDisabled
              startGroup="https://"
              endGroup=".devapi.com.br/v1"
              label="URL"
              name="apiUrl"
              placeHolder="URL"
              value={formik.values.url || ''}
              onChange={formik.handleChange}
              isErrorForm={!!formik.errors.url}
              errorMessage={formik.errors.url}
            />
          </InputContainer>
          <InputContainer>
            <TextArea
              isFormik
              name="description"
              rows={5}
              columns={20}
              placeHolder="Descrição"
              label="Descrição"
              value={formik.values.description || ''}
              onChange={formik.handleChange}
              isErrorForm={!!formik.errors.description}
              errorMessage={formik.errors.description}
            />
          </InputContainer>
          <ModalActions>
            <Button
              type="reset"
              colorType="neutral"
              variant="outlined"
              text="Cancelar"
              onClick={onClose}
            />
            <Button
              role="button-save"
              variant="default"
              colorType="green"
              type="submit"
              text="Próximo"
              iconPosition="right"
              icon={<BiRightArrowAlt />}
            />
          </ModalActions>
        </form>
      </FormikProvider>
    </Container>
  )
}

export { EditApiForm }
