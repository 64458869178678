import React, { useState } from 'react'
import * as Yup from 'yup'
import {
  Button,
  Select,
  Modal,
  Loading,
  TextArea,
  notificationError,
  notificationSuccess,
} from '@devapi/design-system'
import { ICreatePermissionProps } from './CreatePermission.interface'
import { ICreatePermissionFormikData } from '../../../@types/shared.interface'
import { FiCheck } from 'react-icons/fi'
import {
  ModalContent,
  InputContainer,
  FormContainer,
  ModalActions,
} from './CreatePermission.style'
import { FormikProvider, useFormik } from 'formik'
import { createPermission } from '../../../providers'

export const CreatePermission = ({
  apiId,
  isOpen,
  onClose,
  onSuccess,
  tenantOptions,
}: ICreatePermissionProps) => {
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = async (values: ICreatePermissionFormikData) => {
    try {
      setIsLoading(true)
      await createPermission(apiId, values)
      onSuccess()
      handleClose()
      notificationSuccess({
        message: 'Permissão criada com sucesso!',
        position: 'top-right',
      })
    } catch (error) {
      notificationError({
        message: error?.response?.data?.message || 'Erro ao criar permissão',
        position: 'top-right',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const validationSchema = Yup.object().shape({
    tenants: Yup.string().required('Campo Obrigatório'),
    description: Yup.string(),
  })

  const formik = useFormik({
    initialValues: {
      tenants: '',
      description: '',
    },
    validationSchema,
    onSubmit,
  })

  const handleClose = () => {
    formik.resetForm()
    onClose()
  }

  return (
    <Modal
      title="Nova Permissão"
      fontSize="26px"
      isOpen={isOpen}
      onClick={handleClose}
    >
      <Loading isOpen={isLoading} text="Criando permissão..." />
      <ModalContent>
        <FormikProvider value={formik}>
          <FormContainer onSubmit={formik.handleSubmit}>
            <InputContainer>
              <Select
                isFormik
                name="tenants"
                label="Tenant"
                defaultValue="Nenhum"
                isLabelBold
                optionList={tenantOptions}
                isErrorForm={!!formik.errors.tenants}
                errorMessage={formik.errors.tenants}
              />
            </InputContainer>
            <InputContainer>
              <TextArea
                isFormik
                name="description"
                label="Descrição"
                placeHolder="Descrição"
                columns={20}
                rows={10}
                onChange={formik.handleChange}
                value={formik.values.description}
                errorMessage={formik.errors.description}
                isErrorForm={!!formik.errors.description}
              />
            </InputContainer>
            <ModalActions>
              <Button
                onClick={handleClose}
                type="reset"
                colorType="neutral"
                variant="outlined"
                text="Cancelar"
              />
              <Button
                role="button-save"
                variant="default"
                colorType="green"
                type="submit"
                text="Salvar permissão"
                icon={<FiCheck />}
              />
            </ModalActions>
          </FormContainer>
        </FormikProvider>
      </ModalContent>
    </Modal>
  )
}
