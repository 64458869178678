import styled from 'styled-components'
import { GrFormNextLink } from 'react-icons/gr'
import theme from 'src/assets/styles/theme'

import { SiSwagger } from 'react-icons/si'

export const Container = styled.div``

export const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
`

export const ActionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 500px;

  @media (max-width: 1400px) {
    height: 430px;
  }
`

export const Title = styled.h1`
  width: 100%;
  margin-top: 50px;
  text-align: center;
  font-size: 32px;
  font-weight: 900;
  line-height: 40px;
`

export const Subtitle = styled.p`
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 400;

  @media (max-width: 1400px) {
    margin: 0;
  }
`

export const CardContainer = styled.div`
  height: 312px;

  margin-top: 30px;

  display: flex;

  align-items: center;
  justify-content: space-around;
`

export const ButtonsContainer = styled.div`
  width: 300px;
  margin: 60px 0 0 15px;
  padding-bottom: 20px;

  display: flex;
  justify-content: space-between;

  > button {
    span {
      margin: 3px 10px 0px 10px;
    }
  }
`

export const NextIcon = styled(GrFormNextLink)`
  path {
    stroke: #fff;
  }
`

export const SwaggerIcon = styled(SiSwagger)`
  width: 100px;
  height: 100px;
  font-size: 80px;
  border-radius: 50%;
  background-color: #85ea2d;
  color: ${theme.colors.primary};
`
