import React, { useEffect, useState } from 'react'

import {
  Multistep,
  notificationError,
  notificationSuccess,
  InternalHeader,
} from '@devapi/design-system'
import { useHistory } from 'react-router'

import {
  IAuthentication,
  IDefaultParameters,
  IInfoConnector,
  IInformationForm,
  IResource,
} from 'src/modules/Connectors/@types'
import {
  handleAuthorizationPayload,
  handleInformationPayload,
  handleResourcePayload,
} from 'src/modules/Connectors/adapters'
import {
  CardInfo,
  AutenticationForm,
  InformationForm,
} from 'src/modules/Connectors/components'
import { DefaultParametersForm } from 'src/modules/Connectors/components/DefaultParametersForm'
import { ResourceForm } from 'src/modules/Connectors/components/ResourceForm'
import {
  createAuthorizations,
  createConnectorInfo,
  createDefaultParams,
  createResource,
} from 'src/modules/Connectors/providers/connectors.create'

import {
  Container,
  ContentContainer,
  StepContainer,
  FormContainer,
  Title,
} from './CreateConnectorForm.style'

export const CreateConnectorForm = () => {
  const history = useHistory()
  const [step, setStep] = useState('information')
  const [isPassInformations, setIsPassInformations] = useState(false)
  const [isPassAutentication, setIsPassAutentication] = useState(false)
  const [isPassParameters, setIsPassParameters] = useState(false)
  const [isPassResource, setIsPassResource] = useState(false)
  const [informations, setInformations] = useState({
    privacy: 'PRIVATE',
    info: {
      publicName: '',
      internalName: '',
      type: 'REST',
      baseUrl: '',
      logoUrl: '',
      categoryId: '',
      description: '',
    },
  } as IInformationForm)
  const [autentication, setAutentication] = useState({
    type: 'noAuth',
    noAuth: [],
    basic: {
      fields: [
        { fieldName: 'user', displayName: '', required: true },
        { fieldName: 'password', displayName: '', required: true },
      ],
    },
    header: {
      fields: [{ fieldName: '', displayName: '', required: true }],
    },
    querystring: {
      fields: [{ fieldName: '', displayName: '', required: false }],
    },
    oauth2: {
      fields: [
        {
          fieldName: 'redirect_uri',
          displayName: '',
          required: true,
          fixed: true,
        },
        {
          fieldName: 'auth_url',
          displayName: '',
          required: true,
          fixed: true,
        },
        {
          fieldName: 'access_token_url',
          displayName: '',
          required: true,
        },
        {
          fieldName: 'client_id',
          displayName: '',
          required: true,
        },
        {
          fieldName: 'client_secret',
          displayName: '',
          required: true,
        },
        {
          fieldName: 'scope',
          displayName: '',
          required: false,
        },
        {
          fieldName: 'state',
          displayName: '',
          required: false,
        },
        {
          fieldName: 'client_authentication',
          displayName: '',
          required: true,
        },
        {
          fieldName: 'authorization_data',
          displayName: '',
          required: true,
        },
      ],
    },
  })

  const [connectorName, setConnectorName] = useState(
    informations.info.internalName || 'Nome em exibição',
  )
  const [urlBase, setUrlBase] = useState('')
  const [logoUrl, setLogoUrl] = useState(informations.info.logoUrl || '')
  const [defaultParameters, setDefaultParameters] = useState<
    IDefaultParameters[]
  >([])
  const [resources, setResources] = useState<IResource>({
    version: 'v1',
    groups: [],
  })

  const handleSetInformations = async (values: IInfoConnector) => {
    setInformations({
      ...informations,
      info: { ...values, internalName: values.publicName },
    })
    setIsPassInformations(true)
    setStep('autentication')
  }

  const handleSetAuthType = (authType: string) => {
    setAutentication({ ...autentication, type: authType })
  }

  const handleSetAutentications = (values: IAuthentication) => {
    setAutentication(values)
    setIsPassAutentication(true)
    setStep('params')
  }

  const handleSetResource = value => {
    setResources({ ...resources, groups: value })
    setIsPassResource(true)
  }

  const handleCreateConnector = async () => {
    try {
      const {
        data: {
          connector: { _id },
        },
      } = await createConnectorInfo(handleInformationPayload(informations))

      await createAuthorizations(handleAuthorizationPayload(autentication), _id)

      if (defaultParameters.length)
        await createDefaultParams({ params: defaultParameters }, _id)

      await createResource(handleResourcePayload(resources), _id)

      notificationSuccess({
        message: 'Conector criado com sucesso',
        position: 'top-right',
      })
      history.push('/connectors')
    } catch (error) {
      if (error.response) {
        const {
          response: {
            data: { message },
          },
        } = error

        notificationError({ message: message, position: 'top-right' })
      }
      notificationError({
        message: JSON.stringify(error),
        position: 'top-right',
      })
    }
  }

  useEffect(() => {
    if (resources.groups.length) handleCreateConnector()
  }, [resources.groups])

  const crumbs = [
    {
      label: 'Conectores',
      value: '/connectors',
    },
    {
      label: 'Novo conector',
      value: '/connectors/new-connector',
    },
    {
      label: 'Construir conector',
      value: '/connectors/constructor',
    },
  ]

  const steps = [
    {
      label: 'Informações',
      value: 'information',
      isPass: isPassInformations,
    },
    {
      label: 'Autenticação',
      value: 'autentication',
      isPass: isPassAutentication,
    },
    {
      label: 'Paramêtros Padrão',
      value: 'params',
      isPass: isPassParameters,
    },
    {
      label: 'Recursos',
      value: 'resource',
      isPass: isPassResource,
    },
  ]

  const handleChangeStep = (stepValue: string) => {
    setStep(stepValue)
  }

  const handleDisplayInfos = (values: IInfoConnector) => {
    setConnectorName(values.publicName || 'Nome em exibição')
    setUrlBase(values.baseUrl)
    setLogoUrl(values.logoUrl)
  }

  const handleSetDefaultParameters = (values: IDefaultParameters[]) => {
    setDefaultParameters(values)
    setStep('resource')
    setIsPassParameters(true)
  }

  const handleCancel = () => {
    history.push('/connectors')
  }

  const formStep = {
    information: {
      title: 'Informação',
      component: (
        <InformationForm
          privacy={'PRIVATE'}
          handleDisplayInfos={handleDisplayInfos}
          onSubmitInformations={handleSetInformations}
          formInitialValues={informations.info}
        />
      ),
    },
    autentication: {
      title: 'Autenticação',
      component: (
        <AutenticationForm
          privacy={'PRIVATE'}
          formInitialValues={autentication}
          handleSetAuthType={handleSetAuthType}
          handleSetAutentications={handleSetAutentications}
          handleChangeStep={handleChangeStep}
        />
      ),
    },
    params: {
      title: 'Parâmetros padrão',
      component: (
        <DefaultParametersForm
          privacy={'PRIVATE'}
          handleClose={handleCancel}
          handleChangeStep={handleChangeStep}
          defaultParametersValue={defaultParameters}
          handleSetDefaultParameters={handleSetDefaultParameters}
        />
      ),
    },
    resource: {
      title: 'Recursos',
      component: (
        <ResourceForm
          privacy={'PRIVATE'}
          handleClose={handleCancel}
          handleChangeStep={handleChangeStep}
          initialValues={resources.groups}
          urlBase={informations.info.baseUrl}
          handleSetResource={handleSetResource}
        />
      ),
    },
  }

  const handleBreadCrumb = (value: string) => {
    history.replace(value)
  }
  return (
    <Container>
      <InternalHeader crumbs={crumbs} onClickBreadCrumbs={handleBreadCrumb} />
      <ContentContainer>
        <StepContainer>
          <Multistep items={steps} onClick={handleChangeStep} size={'small'} />
        </StepContainer>
        <FormContainer>
          <Title>{formStep[step].title}</Title>
          <CardInfo
            connectorName={connectorName}
            urlBase={urlBase}
            logoUrl={logoUrl}
            autentication={autentication.type}
          />
          {formStep[step].component}
        </FormContainer>
      </ContentContainer>
    </Container>
  )
}
