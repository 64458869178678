import api from 'src/services/api'
import {
  IErrorLogResponse,
  IApiLogsResponse,
} from '../@types/providers.interface'

export const findApiLogsById = (
  id: string,
  page?: number,
  orderBy?: string,
) => {
  return api.get<IApiLogsResponse>(`/apis/${id}/logs`, {
    params: {
      limit: 11,
      page: page,
      ...(orderBy && { orderby: orderBy }),
    },
  })
}

export const findLogErrorResponse = (id: string) => {
  return api.get<IErrorLogResponse>(`/logs/${id}/error-responses`)
}
