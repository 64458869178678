import React, { useState } from 'react'
import * as Yup from 'yup'

import {
  Button,
  InputText,
  Loading,
  Modal,
  notificationError,
  notificationSuccess,
} from '@devapi/design-system'
import { FormikHelpers, FormikProvider, useFormik } from 'formik'
import { IImportSwagger, ISwaggerModalProps } from './SwaggerModal.interface'

import { Content, Form, ButtonContainer, Pipe } from './SwaggerModal.style'
import { importConnector } from 'src/modules/Connectors/providers/connectors.create'
import { useHistory } from 'react-router'

const SwaggerModal = ({ isOpen, handleCloseModal }: ISwaggerModalProps) => {
  const [isLoading, setIsLoading] = useState(false)

  const history = useHistory()

  const onSubmit = async (
    values: IImportSwagger,
    { resetForm }: FormikHelpers<IImportSwagger>,
  ) => {
    try {
      setIsLoading(true)

      const payload = {
        documentation_url: values.url,
        category: '5ec3af1ca04b45c3c56986c4',
      }

      await importConnector(payload)

      resetForm()
      notificationSuccess({
        message: 'Conector importado com sucesso.',
        position: 'top-right',
      })
      history.push('/connectors')
    } catch (error) {
      notificationError({
        message: 'Não foi possível importar o conector.',
        position: 'top-right',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const schema = Yup.object().shape({
    url: Yup.string().required('Url obrigatória'),
  })

  const formik = useFormik({
    initialValues: {
      url: '',
    },
    validationSchema: schema,
    onSubmit,
  })

  const handleClickCancel = () => {
    handleCloseModal()
  }

  return (
    <>
      {isLoading ? (
        <Loading isOpen={isLoading} text="Importando conector..." />
      ) : (
        <Modal
          isOpen={isOpen}
          title="Importar Swagger"
          subTitle="Insira a URL Swagger para importar o conector."
          onClick={handleCloseModal}
        >
          <Content>
            <FormikProvider value={formik}>
              <Form onSubmit={formik.handleSubmit}>
                <InputText
                  isFormik
                  name="url"
                  placeHolder="Digite a url"
                  label="URL"
                  onChange={formik.handleChange}
                  value={formik.values.url}
                  errorForm={!!formik.errors.url}
                  errorMessage={formik.errors.url}
                />
                <ButtonContainer>
                  <Button
                    colorType="neutral"
                    variant="outlined"
                    text="Cancelar"
                    onClick={handleClickCancel}
                  />
                  <Pipe />
                  <Button
                    colorType="green"
                    variant="default"
                    text="Importar conector"
                    type="submit"
                    isDisabled={!!formik.errors.url}
                  />
                </ButtonContainer>
              </Form>
            </FormikProvider>
          </Content>
        </Modal>
      )}
    </>
  )
}

export { SwaggerModal }
