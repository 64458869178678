import { ICreateJob } from '../@types/providers.interface'
import { ICreateJobsPayload } from '../presentation/create/CreateJobs.interface'

export const handlePayload = (
  formValues: ICreateJobsPayload,
  fileId: string,
): ICreateJob => ({
  name: formValues.name,
  automation_id: formValues.project,
  tenant_id: formValues.tenant,
  time_value: formValues.time.toString(),
  time_type: formValues.timerType,
  time_hour: formValues.hours ? formValues.hours : null,
  status: 'ACTIVE',
  allow_notification: 'DISABLED',
  params: formValues.codeParams ? JSON.parse(formValues.codeParams) : null,
  deploy_path: formValues.automation,
  file_id: fileId,
})
