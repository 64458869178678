/* eslint-disable react/jsx-key */
import {
  Button,
  Loading,
  notificationError,
  Pagination,
  Table,
} from '@devapi/design-system'
import React, { useEffect, useState } from 'react'
import { BsArrowClockwise, BsEye } from 'react-icons/bs'
import { useHistory, useParams } from 'react-router'
import { InternalHeader } from 'src/components/InternalHeader'
import { ITableLogJob } from '../../@types/shared.interface'
import { handleTableLogs } from '../../adapters/handleTableLogs'
import { findJobsLogsById, findLogErrorResponse } from '../../providers'
import { findJobById } from '../../providers/jobs.find'
import { IJobLogsRouteParams, IPaginationState } from './Logs.interface'

import { Container, Content, TableContainer } from './Logs.style'
import theme from 'src/assets/styles/theme'
import { LogsModal } from '../../../../components/LogsModal'
import { IErrorData } from 'src/components/LogsModal/LogsModal.interface'
import { handleErrorData } from '../../adapters/handleErrorData'

const JobLogs = () => {
  const { id } = useParams<IJobLogsRouteParams>()
  const history = useHistory()

  if (!id) history.push('/ipaas/jobs')

  const [logPagination, setLogPagination] = useState({
    currentPage: 1,
    totalPages: 0,
  } as IPaginationState)
  const [logs, setLogs] = useState<ITableLogJob[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingLogs, setIsLoadingLogs] = useState(false)
  const [jobName, setJobName] = useState('')
  const [filter, setFilter] = useState('')
  const [isViewLog, setIsViewLog] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [errorData, setErrorData] = useState<IErrorData[]>([
    {
      message: '',
      error: '',
      status: '',
      cause: '',
      headers: {},
      url: '',
      method: '',
      params: '',
    },
  ])

  useEffect(() => {
    findJobDetails(id)
  }, [])

  useEffect(() => {
    findJobLogs(id)
  }, [filter, currentPage])

  const findJobDetails = async (jobId: string) => {
    try {
      if (jobId) {
        const job = await findJobById(jobId)
        setJobName(job.data.name)
      }
    } catch (error) {
      notificationError({
        message:
          error.response?.data?.message || 'Não foi possível carregar o job',
        position: 'top-right',
      })
    }
  }

  const findJobLogs = async (jobId: string) => {
    try {
      if (jobId) {
        setIsLoading(true)
        const { data } = await findJobsLogsById(jobId, currentPage, filter)

        const { content, pagination } = handleTableLogs(data)

        setLogs(content)

        if (pagination.totalPages > 0) {
          setLogPagination({
            ...logPagination,
            totalPages: pagination.totalPages,
          })
        }
      }
    } catch (error) {
      notificationError({
        message:
          error.response?.data?.message || 'Não foi possível carregar os logs',
        position: 'top-right',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const findLogError = async (id: string) => {
    try {
      setIsLoadingLogs(true)
      const { data } = await findLogErrorResponse(id)

      const logHandled = handleErrorData(data.content)

      await setErrorData(logHandled)
      setIsViewLog(true)
    } catch (error) {
      notificationError({
        message:
          error.response?.data?.message || 'Não foi possível carregar os logs',
        position: 'top-right',
      })
    } finally {
      setIsLoadingLogs(false)
    }
  }

  const crumbs = [
    { label: 'Jobs', value: 'jobs' },
    { label: 'Logs', value: 'logs' },
    { label: `${jobName}`, value: `${id}` },
  ]

  const crumbswithoutJob = [
    { label: 'Jobs', value: 'jobs' },
    { label: 'Logs', value: 'logs' },
  ]

  const handleClickCrumb = (crumb: string) => {
    if (crumb === 'jobs') {
      history.push(`/ipaas/${crumb}`)
    }
  }

  const columns = [
    { key: 'project', label: 'Projeto', isOrder: true, columnWidth: '300px' },
    { key: 'tenant', label: 'Tenant', isOrder: true, columnWidth: '300px' },
    { key: 'success', label: 'Quantidade de sucesso' },
    { key: 'error', label: 'Quantidade de erros' },
    { key: 'opt', label: 'Opções' },
  ]

  const handleChangePage = (page: number) => {
    setCurrentPage(page)
  }

  const handleOrderBy = (colum: string) => {
    const validOrderBy = {
      project: 'automation_id',
      tenant: 'tenant_id',
    }

    setFilter(validOrderBy[colum])
  }

  const handleResearch = () => {
    findJobLogs(id)
  }

  const handleViewLog = itemRow => {
    const findLogs = logs.find(item => item.id === itemRow.id)

    if (findLogs && findLogs.error) {
      findLogError(findLogs.id)
    }
  }

  const handleCloseModal = () => {
    setIsViewLog(false)
  }

  return (
    <>
      <Loading isOpen={isLoading || isLoadingLogs} text="Carregando logs..." />
      <Container>
        <InternalHeader
          crumbs={logs.length ? crumbs : crumbswithoutJob}
          onClickBreadCrumbs={handleClickCrumb}
        />
        <Content>
          {!isLoading ? (
            <TableContainer>
              <Table
                noContentText="Nenhum resultado encontrado."
                tableData={logs}
                tableHeaderOptions={columns}
                orderBy={['project', 'tenant']}
                sortTableData={handleOrderBy}
                optionsActions={[
                  <BsEye {...theme.icon} onClick={handleViewLog} />,
                ]}
                buttonReload={
                  <Button
                    colorType="green"
                    variant="default"
                    text="Recarregar"
                    icon={<BsArrowClockwise />}
                    onClick={handleResearch}
                  />
                }
              />
            </TableContainer>
          ) : (
            ''
          )}

          {logPagination.totalPages > 1 && (
            <Pagination
              size="default"
              totalPages={logPagination.totalPages}
              page={currentPage}
              onChange={(page: number) => handleChangePage(page)}
            />
          )}
        </Content>
      </Container>
      <LogsModal
        isOpen={isViewLog}
        projectName={jobName}
        handleCloseModal={handleCloseModal}
        errorData={errorData}
      />
    </>
  )
}

export { JobLogs }
