import React, { useCallback, useEffect, useState } from 'react'
import { Box, Loading, notificationError } from '@devapi/design-system'
import {
  Container,
  PlanContainer,
  // HistoryPaymentContainer,
  Title,
} from './Plan.style'
import { CardPlan } from './CardPlan'
import api from 'src/services/api'
import { IPlanDetails } from './CardPlan/CardPlan.interface'
import { IUserPlanDetails } from './Plan.interface'

const Plan = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [plan, setPlan] = useState<IPlanDetails>({
    type: 'FREE',
    value: 0,
    contractTime: '',
    contractValue: 0,
    automation: 0,
    apiCalls: 0,
    jobs: 0,
    logsDay: 0,
    tenants: 'Ilimitado',
    users: 'Ilimitado',
  })

  const getPlanDetails = useCallback(async () => {
    const planDetails = await api
      .get(`/companies/user/myplan`)
      .then(({ data }) => {
        return data
      })
      .catch(error => {
        notificationError({
          message:
            error.response?.data?.message ||
            'Não foi possível carregar os detalhes do plano',
          position: 'top-right',
        })
      })
      .finally(() => setIsLoading(false))

    setPlan(handlePlanDetails(planDetails))
  }, [])

  const handlePlanDetails = (userPlan: IUserPlanDetails): IPlanDetails => {
    console.log(userPlan)

    const extra = {
      apiCalls: userPlan.additionals?.calls || 0,
      automations: userPlan.additionals?.automations || 0,
      jobs: userPlan.additionals?.jobs || 0,
      tenants: userPlan.additionals?.tenants || 0,
      users: userPlan.additionals?.users || 0,
    }

    return {
      type: userPlan.type || 'FREE',
      value: 0,
      contractTime: '12',
      contractValue: 0,
      automation: userPlan.automations + extra.automations || 0,
      apiCalls: userPlan.calls + extra.apiCalls || 0,
      jobs: userPlan.jobs + extra.jobs || 0,
      logsDay: userPlan.log_limit,
      tenants: 'Ilimitados',
      users: 'Ilimitados',
    }
  }

  useEffect(() => {
    setIsLoading(true)
    getPlanDetails()
  }, [getPlanDetails])

  return (
    <>
      <Loading isOpen={isLoading} text="Carregando o seu plano..." />
      <Container>
        <Box>
          <PlanContainer>
            <Title>Meu plano</Title>
            <CardPlan plan={plan} />
          </PlanContainer>
        </Box>
        {/* <Box>
          <HistoryPaymentContainer>
            <Title>Histórico de pagamento</Title>
          </HistoryPaymentContainer>
        </Box> */}
      </Container>
    </>
  )
}

export { Plan }
