import { Button, InputText, Select, Switch } from '@devapi/design-system'
import { FormikProvider, useFormik, Form } from 'formik'
import React from 'react'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { FiTrash2 } from 'react-icons/fi'
import { useHistory } from 'react-router'
import theme from 'src/assets/styles/theme'
import { IAutenticationFormProps } from '../../@types'
import {
  AutenticationContainer,
  AuthSelectContainer,
  DinamicInputContainer,
  InputContainer,
  DeleteContainer,
  SwitchContainer,
  SwitchLabel,
  ButtonContainer,
  FormButtons,
  PrevNextButtons,
  NextIcon,
  PrevIcon,
} from './AutenticationForm.style'
import * as Yup from 'yup'

export const AutenticationForm = ({
  formInitialValues,
  handleSetAuthType,
  handleSetAutentications,
  handleChangeStep,
  privacy,
}: IAutenticationFormProps) => {
  const authOptions = [
    {
      label: 'No Auth',
      value: 'noAuth',
    },
    {
      label: 'Basic',
      value: 'basic',
    },
    {
      label: 'Header',
      value: 'header',
    },
    {
      label: 'QueryString',
      value: 'querystring',
    },
    {
      label: 'OAuth2',
      value: 'oauth2',
    },
  ]

  const schema = Yup.object().shape({
    type: Yup.string().required(),

    basic: Yup.object().when('type', (type, _schema) => {
      if (type === 'basic') {
        return _schema.shape({
          fields: Yup.array().of(
            Yup.object().shape({
              fieldName: Yup.string().when('required', {
                is: value => value === true,
                then: Yup.string().required('Campo Obrigatório'),
              }),
              displayName: Yup.string().when('required', {
                is: value => value === true,
                then: Yup.string().required('Campo Obrigatório'),
              }),
            }),
          ),
        })
      }
      return _schema
    }),

    header: Yup.object().when('type', (type, _schema) => {
      if (type === 'header') {
        return _schema.shape({
          fields: Yup.array().of(
            Yup.object().shape({
              fieldName: Yup.string().when('required', {
                is: value => value === true,
                then: Yup.string().required('Campo Obrigatório'),
              }),
              displayName: Yup.string().when('required', {
                is: value => value === true,
                then: Yup.string().required('Campo Obrigatório'),
              }),
            }),
          ),
        })
      }
      return _schema
    }),

    querystring: Yup.object().when('type', (type, _schema) => {
      if (type === 'querystring') {
        return _schema.shape({
          fields: Yup.array().of(
            Yup.object().shape({
              fieldName: Yup.string().when('required', {
                is: value => value === true,
                then: Yup.string().required('Campo Obrigatório'),
              }),
              displayName: Yup.string().when('required', {
                is: value => value === true,
                then: Yup.string().required('Campo Obrigatório'),
              }),
            }),
          ),
        })
      }
      return _schema
    }),

    oauth2: Yup.object().when(['type'], (type, _schema) => {
      if (type === 'oauth2') {
        return _schema.shape({
          fields: Yup.array().of(
            Yup.object().shape({
              fieldName: Yup.string().when('required', {
                is: value => value === true,
                then: Yup.string().required('Campo Obrigatório'),
              }),
              displayName: Yup.string().when('required', {
                is: value => value === true,
                then: Yup.string().required('Campo Obrigatório'),
              }),
            }),
          ),
        })
      }
      return _schema
    }),
  })

  const onSubmit = values => {
    handleSetAutentications(values)
  }

  const validate = values => {
    handleSetAuthType(values.type)
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formInitialValues,
    validationSchema: schema,
    validate,
    onSubmit,
  })

  const fields = formik.values[formik.values.type].fields

  const handleAddInput = () => {
    const formikValues = formik.values
    formikValues[formik.values.type].fields.push({
      fieldName: '',
      displayName: '',
      required: formik.values.type === 'header',
    })
    formik.setValues(formikValues)
  }

  const handleDelete = (idx: number) => {
    const formikValues = formik.values
    formikValues[formik.values.type].fields.splice(idx, 1)
    formik.setValues(formikValues)
  }

  const handleSwitch = idx => {
    const formikValues = formik.values
    formikValues[formik.values.type].fields[idx].required =
      !formikValues[formik.values.type].fields[idx].required

    formik.setValues(formikValues)
  }

  const button = {
    header: (
      <ButtonContainer>
        <Button
          type={'button'}
          colorType={'green'}
          variant={'default'}
          onClick={handleAddInput}
          text={'Adicionar campo'}
          icon={<AiOutlinePlusCircle size={20} />}
          isDisabled={privacy === 'PUBLIC'}
        />
      </ButtonContainer>
    ),

    querystring: (
      <ButtonContainer>
        <Button
          type={'button'}
          colorType={'green'}
          variant={'default'}
          onClick={handleAddInput}
          text={'Adicionar campo'}
          icon={<AiOutlinePlusCircle size={20} />}
          isDisabled={privacy === 'PUBLIC'}
        />
      </ButtonContainer>
    ),
  }

  const history = useHistory()
  const handleCancel = () => {
    history.push('/connectors')
  }

  return (
    <AutenticationContainer>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <AuthSelectContainer>
            <Select
              isFormik
              name={'type'}
              optionList={authOptions}
              defaultValue={'Selecionar'}
              value={formik.values.type}
              isDisable={privacy === 'PUBLIC'}
            />
          </AuthSelectContainer>

          {button[formik.values.type]}

          {fields?.map((field, index) => {
            const _prefix = `[${formik.values.type}][fields][${index}]`
            return (
              <DinamicInputContainer key={index}>
                <InputContainer>
                  <InputText
                    isFormik
                    name={`${_prefix}[fieldName]`}
                    label={`Nome do Campo*`}
                    value={field.fieldName}
                    onChange={formik.handleChange}
                    disabled={privacy === 'PUBLIC'}
                  />
                </InputContainer>
                <InputContainer>
                  <InputText
                    isFormik
                    name={`${_prefix}[displayName]`}
                    label={`Nome em Exibição*`}
                    value={field.displayName}
                    onChange={formik.handleChange}
                    disabled={privacy === 'PUBLIC'}
                  />
                </InputContainer>
                <SwitchContainer>
                  <SwitchLabel>Obrigatório</SwitchLabel>
                  <Switch
                    isChecked={field.required}
                    onChange={() => handleSwitch(index)}
                    isDisabled={privacy === 'PUBLIC'}
                  />
                </SwitchContainer>
                {privacy === 'PRIVATE' && (
                  <>
                    {(formik.values.type === 'header' ||
                      formik.values.type === 'querystring') && (
                      <DeleteContainer
                        onClick={() => handleDelete(index)}
                        data-testid={'button-delete'}
                      >
                        <FiTrash2 size="16px" color={theme.colors.red} />
                      </DeleteContainer>
                    )}
                  </>
                )}
              </DinamicInputContainer>
            )
          })}
          <FormButtons>
            <Button
              type={'button'}
              colorType={'neutral'}
              variant={'outlined'}
              text={'Cancelar'}
              onClick={handleCancel}
            />

            <PrevNextButtons>
              <Button
                type={'button'}
                variant={'default'}
                colorType={'green'}
                text={'Anterior'}
                icon={<PrevIcon />}
                onClick={() => handleChangeStep('information')}
              />
              <Button
                type={'submit'}
                variant={'default'}
                colorType={'green'}
                text={'Próximo'}
                iconPosition={'right'}
                icon={<NextIcon />}
              />
            </PrevNextButtons>
          </FormButtons>
        </Form>
      </FormikProvider>
    </AutenticationContainer>
  )
}
