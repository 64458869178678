import { notificationError, notificationSuccess } from '@devapi/design-system'
import { IHandleUpdateStatus } from '../@types/provider.interface'
import { updateTenantStatus } from '../providers/status.update'

export const handleUpdateTenantStatus = async ({
  id,
  status,
  onSuccess,
}: IHandleUpdateStatus) => {
  try {
    await updateTenantStatus({ id, status })
    const message = status === 'ACTIVE' ? 'bloqueado' : 'desbloqueado'
    notificationSuccess({
      message: `O tenant foi ${message} com sucesso!`,
      position: 'top-right',
    })
    onSuccess()
  } catch (error) {
    const { message } = error.response.data
    notificationError({
      message: String(message).toLocaleLowerCase(),
      position: 'top-right',
    })
  }
}
