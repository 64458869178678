import theme from 'src/assets/styles/theme'
import styled, { css } from 'styled-components'
import { IColapse, IMethodColor } from '../../@types'

export const TabContainer = styled.div`
  margin-bottom: 2rem;
  margin-top: 20px;
`

export const TitleContainer = styled.div`
  height: 56px;
  width: 100%;

  display: flex;

  justify-content: space-between;
  align-items: center;

  background-color: ${theme.colors.gray[200]};

  border: 1px solid ${theme.colors.gray[200]};

  p {
    margin-left: 2vw;
  }

  cursor: pointer;
`

export const Title = styled.p``

export const IconContainer = styled.div`
  margin-right: 2vw;
`

export const Colapse = styled.div<IColapse>`
  height: 0;
  overflow: hidden;
  transition: 0.5s;

  ${({ isOpen }) => {
    return (
      isOpen &&
      css`
        padding: 10px;

        border: 1px solid ${theme.colors.gray[200]};

        height: auto;
      `
    )
  }};
`

export const InputsResourceContainer = styled.div`
  display: flex;

  align-items: center;

  padding-bottom: 30px;
  margin-top: 10px;
`

export const MethodCard = styled.p<IMethodColor>`
  display: flex;

  min-width: 82px;
  height: 26px;

  align-items: center;
  justify-content: center;

  border-radius: 3px;
  ${({ color }) => {
    return css`
      background-color: ${color};
    `
  }}
  color: #fff;
  font-weight: 700;
  font-size: 1rem;

  > p {
    margin-top: 4px;
  }
`

export const RouteContainer = styled.div`
  display: flex;

  height: 26px;
  min-width: 10vw;

  justify-content: center;
  align-items: center;

  margin-left: 1vw;
  margin-right: 1vw;
`

export const IconsContainer = styled.p`
  display: flex;

  min-width: 4vw;

  padding: 8px;

  cursor: pointer;

  justify-self: flex-end;
  justify-content: space-around;
  align-items: center;
`

export const Container = styled.div`
  margin-top: 20px;
`

export const NoParamsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 70px;
  margin-bottom: 25px;
`

export const NoParamsText = styled.h2`
  color: ${theme.colors.gray[300]};
  font-size: 26px;
  margin-bottom: 25px;
`

export const SelectContainer = styled.div`
  margin: 30px 30px 0 0;
  max-width: 21%;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
`

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const InputContainer = styled.div`
  margin: 30px 30px 30px 0;
  max-width: 30%;
`

export const SwitchContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 58px;
  margin-right: 20px;
`

export const SwitchLabel = styled.label`
  font-weight: 700;
  color: #2c2d66;
`
export const DeleteContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 58px;
  width: 48px;
  padding-top: 12px;
`
export const TextAreaGeneralContainer = styled.div`
  margin: 30px 30px 30px 0;
`

export const TextAreaParametersContainer = styled.div`
  margin: 30px 30px 30px 0;
  max-width: 25%;
  width: 100%;

  textArea {
    height: 40px;
  }
`

export const InputRow = styled.div`
  display: flex;
  align-items: center;
`
