import React, { createContext, useState } from 'react'
import api from 'src/services/api'
import { IContextProps } from '../interfaces/Context.interface'
import { IAuthContextState } from './Auth.interface'
import jwt from 'jsonwebtoken'

const AuthContext = createContext<IAuthContextState>({} as IAuthContextState)

const AuthProvider: React.FC<IContextProps> = ({ children }: IContextProps) => {
  const [accessToken, setAccessToken] = useState(() => {
    const token = localStorage.getItem('accessToken')

    if (token) {
      api.defaults.headers.authorization = `Bearer ${token}`
      try {
        const decoded = jwt.decode(token)
        if (decoded) return token
        return ''
      } catch {
        return ''
      }
    }

    return ''
  })

  const signIn = async ({ email, password }) => {
    const response = await api.post('/auth/login', { email, password })

    // eslint-disable-next-line camelcase
    const { access_token } = response.data

    localStorage.setItem('accessToken', access_token)
    setAccessToken(access_token)

    // eslint-disable-next-line camelcase
    api.defaults.headers.authorization = `Bearer ${access_token}`
  }

  const refreshToken = (newToken: string) => {
    localStorage.setItem('accessToken', newToken)
    setAccessToken(newToken)
  }

  return (
    <AuthContext.Provider value={{ signIn, accessToken, refreshToken }}>
      {children}
    </AuthContext.Provider>
  )
}

export { AuthProvider, AuthContext }
