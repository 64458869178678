import styled, { css } from 'styled-components'
import theme from 'src/assets/styles/theme'

import mask from '../../assets/icons/header-mask.svg'
import { IFilterStyle } from './InternalHeader.interface'

export const Container = styled.div`
  padding: 25px 50px 25px 50px;

  background-image: url(${mask});
  background-color: ${theme.colors.gray[200]};
`

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const FilterContainer = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  margin-top: 40px;
`

export const Filters = styled.div<IFilterStyle>`
  width: 55vw;

  display: flex;
  align-items: flex-end;

  ${props =>
    props.isTwoItems
      ? css`
          justify-content: flex-start;

          > div {
            margin-right: 45px;
          }
        `
      : css`
          justify-content: space-between;
        `}

  @media (max-width: 1366px) {
    width: 60vw;
  }
`

export const Filter = styled.div``

export const ButtonContainer = styled.div``
