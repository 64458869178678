import React from 'react'
import { useDashboard } from '../../contexts/DashboardContext'
import { Box } from '@devapi/design-system'
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts'
import { ChartNoDataMessage } from '../'

export const ChartTopTenants = ({ title }: { title: string }) => {
  const { tenants } = useDashboard()

  return (
    <>
      <label>{title}</label>
      <Box>
        {tenants?.topTenants.length ? (
          <ResponsiveContainer height={400}>
            <BarChart data={tenants.topTenants}>
              <XAxis dataKey="name" tickLine={false} axisLine={false} />
              <YAxis tickLine={false} axisLine={false} />
              <Tooltip cursor={false} />
              <Bar
                dataKey="total"
                fill="#5f47ff"
                barSize={10}
                radius={10}
                legendType="circle"
              />
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <ChartNoDataMessage data-testid="no-data-top-tenants" />
        )}
      </Box>
    </>
  )
}
