import styled from 'styled-components'
import {
  IColumFormStyle,
  IEditJobParams,
  IEditJobStyle,
} from './EditJob.interface'
import theme from 'src/assets/styles/theme'

export const Container = styled.div`
  width: 700px;
`

export const Form = styled.form`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const InputContainer = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
`

export const ColumForm = styled.div<IColumFormStyle>`
  width: 50%;

  display: flex;
  flex-direction: column;

  padding: ${props => props.padding};
`

export const LineForm = styled.div<IEditJobStyle>`
  width: 100%;
  height: ${props => props.height};

  display: flex;

  margin-bottom: 10px;
`

export const JobTimer = styled.div`
  display: flex;
`

export const ButtonContainer = styled.div<IEditJobParams>`
  width: 100%;

  display: flex;
  justify-content: ${props => (props.isOpenAce ? 'space-between' : 'flex-end')};

  margin: ${props => props.margin};
`

export const NotificationContainer = styled.div`
  margin: 10px 0px 20px 0px;
`
export const Label = styled.label`
  position: relative;
  line-height: 1;
  font-weight: 700;
`
export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;

  > div {
    > div {
      display: flex;
    }
  }
`

export const ParamsContainer = styled.div``

export const AceButton = styled.div`
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 2px solid ${theme.colors.secondary};
  border-radius: 40px;

  cursor: pointer;

  span {
    color: ${theme.colors.secondary};
    font-weight: 600;
  }
`
