import React, { useState } from 'react'
import {
  Loading,
  DeleteAlert,
  notificationError,
  notificationSuccess,
} from '@devapi/design-system'
import { IDeleteAutomationsProps } from './DeleteAutomations.interface'
import { deleteAutomation } from '../../providers/Automations.delete'

export const DeleteAutomations = (props: IDeleteAutomationsProps) => {
  const { AutomationId, handleClose, onSuccess, isOpen } = props

  const [loading, setLoading] = useState(false)

  const handleDelete = async () => {
    setLoading(true)
    try {
      if (AutomationId) {
        await deleteAutomation(AutomationId)
        onSuccess(AutomationId)
        notificationSuccess({
          message: 'Automação deletada com sucesso',
          position: 'top-right',
        })
      }
    } catch (error) {
      notificationError({
        message:
          error.response?.data?.message ||
          'Não foi possivel deletar automação.',
        position: 'top-right',
      })
    } finally {
      setLoading(false)
      handleClose()
    }
  }

  return (
    <>
      {loading && <Loading isOpen={loading} text={'Deletando automação...'} />}
      {isOpen && (
        <DeleteAlert
          onClickCancel={handleClose}
          onClickDelete={handleDelete}
          language={'pt-br'}
        />
      )}
    </>
  )
}
