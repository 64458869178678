import React, { useState } from 'react'
import { FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup'
import {
  ICreateUserFormikValues,
  ICreateUserProps,
} from './CreateUser.interface'
import {
  Modal,
  InputText,
  InputPhone,
  Button,
  notificationError,
  notificationSuccess,
  Loading,
} from '@devapi/design-system'
import { BsCheck } from 'react-icons/bs'
import {
  Container,
  InputContainer,
  Form,
  FormContainer,
  FormActions,
} from './CreateUser.style'
import { phoneExtractor } from '../../utils'
import { createUser } from '../../providers'

export const CreateUser = ({
  isOpen,
  onClose,
  onSuccess,
}: ICreateUserProps) => {
  const [isLoading, setIsLoading] = useState(false)

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Nome obrigatório'),
    surname: Yup.string().required('Sobrenome obrigatório'),
    email: Yup.string()
      .required('E-mail obrigatório')
      .email('Entre com um e-mail válido'),
    phone: Yup.string()
      .required('Telefone obrigatório')
      .matches(/^\d{13}$/, { message: 'Deve conter 13 dígitos' }),
  })

  const onSubmit = async (values: ICreateUserFormikValues) => {
    try {
      setIsLoading(true)
      const { name, surname, email, phone } = values
      const { areaCode, countryCode, phoneNumber } = phoneExtractor(phone)
      await createUser({
        name,
        surname,
        email,
        countryCode,
        areaCode,
        phoneNumber,
      })
      notificationSuccess({
        message: 'Usuário cadastrado com sucesso!',
        position: 'top-right',
      })
      handleCloseModal()
      onSuccess()
    } catch (error) {
      notificationError({
        message: error.response?.data?.message || 'Erro ao finalizar cadastro',
        position: 'top-right',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handlePhoneValue = (e: string) => {
    formik.setFieldValue('phone', e)
  }

  const handleCloseModal = () => {
    formik.resetForm()
    onClose()
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      surname: '',
      email: '',
      phone: '',
    },
    validationSchema,
    onSubmit,
    validateOnBlur: true,
  })

  return (
    <Modal title="Novo usuário" isOpen={isOpen} onClick={handleCloseModal}>
      <Loading isOpen={isLoading} text="Enviando dados..." />
      <Container>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit} noValidate>
            <FormContainer>
              <InputContainer>
                <InputText
                  isFormik
                  name="name"
                  label="Nome"
                  placeHolder="Nome"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                />
                <InputText
                  isFormik
                  name="surname"
                  label="Sobrenome"
                  placeHolder="Sobrenome"
                  onChange={formik.handleChange}
                  value={formik.values.surname}
                />
              </InputContainer>
              <InputContainer>
                <InputText
                  isFormik
                  name="email"
                  label="E-mail"
                  placeHolder="E-mail"
                  value={formik.values.email}
                />
                <InputPhone
                  onChange={handlePhoneValue}
                  label="Telefone"
                  placeHolder="Telefone"
                  value={formik.values.phone}
                  errorForm={!!formik.errors.phone}
                  errorMessage={formik.errors.phone || ''}
                />
              </InputContainer>
              <FormActions>
                <Button
                  onClick={handleCloseModal}
                  type="reset"
                  colorType="neutral"
                  variant="outlined"
                  text="Cancelar"
                />
                <Button
                  type="submit"
                  colorType="green"
                  variant="default"
                  text="Salvar usuário"
                  isDisabled={isLoading}
                  icon={<BsCheck />}
                  role="btn-submit"
                />
              </FormActions>
            </FormContainer>
          </Form>
        </FormikProvider>
      </Container>
    </Modal>
  )
}
