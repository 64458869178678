import theme from 'src/assets/styles/theme'
import styled from 'styled-components'

import cardPlan from '../../../../assets/icons/card-plan-bg.svg'

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  width: 100%;
  height: 100%;

  margin-top: 30px;

  border: 1px solid ${theme.colors.gray[300]};
  border-radius: 5px;

  @media only screen and (max-width: 1400px) {
    padding: 0 0 20px 0;
  }
`

export const PlanType = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;
  height: 135px;

  padding: 25px;

  background-image: url(${cardPlan});
  background-repeat: no-repeat;
  background-position-y: 100%;

  @media only screen and (max-width: 1400px) {
    padding: 20px;
    background-size: 100%;
    background-position-y: -30%;
  }
`

export const Icon = styled.img`
  width: 58px;
  height: 58px;

  margin-right: 20px;
`

export const Plan = styled.div``

export const Name = styled.p`
  letter-spacing: 5.02px;
  font-size: 22px;
  font-weight: 700;
  color: ${theme.colors.secondary};

  @media only screen and (max-width: 1400px) {
    font-size: 16px;
  }
`

export const Value = styled.p`
  margin-top: 10px;

  span {
    font-weight: 700;
    font-size: 23px;

    @media only screen and (max-width: 1400px) {
      font-size: 16px;
    }
  }
`

export const PlanTime = styled.div`
  width: 100%;

  padding: 25px;

  line-height: 1.5;

  p {
    font-weight: bold;

    @media only screen and (max-width: 1400px) {
      font-size: 16px;
    }

    span {
      font-weight: 500;
    }
  }

  @media only screen and (max-width: 1400px) {
    padding: 20px;
    font-size: 16px;
  }
`

export const Pipe = styled.div`
  width: 85%;
  height: 2px;

  margin: 0 25px;

  background-color: ${theme.colors.gray[100]};
`

export const PlanDetails = styled.div`
  width: 100%;
  padding: 25px 25px 35px 25px;

  @media only screen and (max-width: 1400px) {
    padding: 20px;
  }
`

export const DetailItem = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 2px;
  line-height: 1;
`
