import React from 'react'
import { IconContainer, ConnectorIcon } from './IconConnector.style'

const IconConnector = () => {
  return (
    <IconContainer>
      <ConnectorIcon />
    </IconContainer>
  )
}

export { IconConnector }
