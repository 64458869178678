import React from 'react'
import { IconContainer, HighCodeIcon } from './IconHighCode.style'

const IconHighCode = () => {
  return (
    <IconContainer>
      <HighCodeIcon />
    </IconContainer>
  )
}
export { IconHighCode }
