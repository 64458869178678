import styled from 'styled-components'

export const Container = styled.div``

export const ContainerTab = styled.div`
  padding: 50px;
`
export const TableContainer = styled.div`
  width: 100%;
  padding: 0 0 20px 0;
`
export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;

  margin-top: 16px;
`
