import api from 'src/services/api'
import { IUpdateUserPaylod } from '../@types/users.interface'

export const updateUser = async (id: string, payload: IUpdateUserPaylod) => {
  const { name, surname, email, countryCode, areaCode, phoneNumber } = payload
  await api.put(`/companies/users/${id}`, {
    name: name,
    surname: surname,
    email: email,
    country_code: countryCode,
    area_code: areaCode,
    phone: phoneNumber,
  })
}
