import React from 'react'
import { Container, Message } from './ChartNoDataMessage.style'

export const ChartNoDataMessage = () => {
  return (
    <Container>
      <Message>SEM DADOS</Message>
    </Container>
  )
}
