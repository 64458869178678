import React, { useEffect, useState } from 'react'
import { InternalHeader } from 'src/components/InternalHeader'
import {
  ButtonContainer,
  ConnectorsListContainer,
  Container,
  ConnectorContainer,
  CheckBoxContainer,
} from './Connectors.style'
import {
  Button,
  Checkbox,
  InputSearch,
  Loading,
  Select,
} from '@devapi/design-system'
import theme from 'src/assets/styles/theme'
import { findAllConnectors } from 'src/modules/Connectors/providers'
import { IConnectorParams } from '../@types/shared.interface'
import { connectorSerializer } from '../adapters'
import { ICategoryOption, IConnectorData } from './Connectors.interface'
import { findAllCategories } from '../providers/categories.find'
import { ConnectorRedirectCard } from 'src/components/ConnectorRedirectCard/ConnectorRedirectCard'
import { useHistory } from 'react-router'

export const Connectors = () => {
  const [isPrivateConnectors, setIsPrivateConnectors] = useState(false)
  const [isPublicConnectors, setIsPublicConnectors] = useState(false)
  const [responsive, setResponsive] = useState(false)
  const [connectorParams, setConnectorParams] = useState<IConnectorParams>({
    currentPage: 1,
  })
  const [allConnectors, setAllConnectors] = useState<IConnectorData[]>([])
  const [haveIsMorePage, setHaveIsMorePage] = useState<boolean | undefined>()
  const [categoriesList, setCategoriesList] = useState<ICategoryOption[]>([
    { label: 'Select' },
  ])
  const [loading, setLoading] = useState(false)

  const crumbs = [{ label: 'Conectores', value: 'connectors' }]
  const width = window.innerWidth

  const filterPrivateConnectors = () => {
    setIsPrivateConnectors(!isPrivateConnectors)
    setAllConnectors([])
    setConnectorParams({
      ...connectorParams,
      currentPage: 1,
      privacy: isPrivateConnectors ? '' : 'PRIVATE',
    })

    setIsPublicConnectors(false)
  }

  const filterPublicConnectors = () => {
    setIsPublicConnectors(!isPublicConnectors)
    setAllConnectors([])
    setConnectorParams({
      ...connectorParams,
      currentPage: 1,
      privacy: isPublicConnectors ? '' : 'PUBLIC',
    })

    setIsPrivateConnectors(false)
  }

  const selectCategory = (option: ICategoryOption) => {
    setAllConnectors([])
    setConnectorParams({
      ...connectorParams,
      currentPage: 1,
      category: option.value,
    })
  }

  const searchValue = (value: string) => {
    setAllConnectors([])
    setConnectorParams({ ...connectorParams, search: value })
  }

  useEffect(() => {
    if (width <= 1440) {
      setResponsive(true)
    } else {
      setResponsive(false)
    }
  }, [width])

  const filters = [
    {
      field: (
        <InputSearch
          fieldSize={responsive ? '200px' : '320px'}
          label={'Encontrar conectores'}
          placeHolder={'Pesquisa'}
          onChange={searchValue}
          isLabelBold={false}
        />
      ),
    },
    {
      field: (
        <Select
          fieldSize={responsive ? '200px' : '320px'}
          label={'Filtrar por categoria'}
          name={'categoryFilter'}
          optionList={categoriesList}
          backgroundColor={theme.colors.secondary}
          onClick={selectCategory}
          isLabelBold={false}
        />
      ),
    },
    {
      field: (
        <CheckBoxContainer>
          <Checkbox
            label={'Conectores privados'}
            value={'privateConnectors'}
            isChecked={isPrivateConnectors}
            isDisabled={false}
            onChange={filterPrivateConnectors}
          />
        </CheckBoxContainer>
      ),
    },
    {
      field: (
        <CheckBoxContainer>
          <Checkbox
            label={'Conectores pre-built'}
            value={'publicConnectors'}
            isChecked={isPublicConnectors}
            isDisabled={false}
            onChange={filterPublicConnectors}
          />
        </CheckBoxContainer>
      ),
    },
  ]

  const getAllConnectors = async () => {
    const connectors = await findAllConnectors(connectorParams)
    if (connectors) {
      const { content, page } = connectors
      const connectorsSerialized = await connectorSerializer(content)
      if (page.totalPages >= page.current) {
        setAllConnectors([...allConnectors, ...connectorsSerialized])
      } else {
        setAllConnectors(connectorsSerialized)
      }

      setHaveIsMorePage(page.current < page.totalPages)
    }
    setLoading(false)
  }

  const getAllCategories = async () => {
    const categories = await findAllCategories()
    setLoading(false)
    if (categories?.length) {
      const listedCategories = categories.map(category => ({
        label: category.name,
        value: category._id,
      }))
      setCategoriesList([
        { label: 'Todos' },
        ...listedCategories.sort((category1, category2) => {
          if (category1.label > category2.label) {
            return 1
          }
          return -1
        }),
      ])
    }
  }

  useEffect(() => {
    setLoading(true)
    getAllConnectors()
    getAllCategories()
  }, [connectorParams])

  const handleLoadMore = () => {
    setConnectorParams({
      ...connectorParams,
      currentPage: connectorParams.currentPage + 1,
    })
  }

  const history = useHistory()
  const hadleNewConnector = () => {
    history.push('/connectors/new-connector')
  }

  return (
    <Container>
      <Loading isOpen={loading} text={'Carregando conectores...'} />
      <InternalHeader
        buttonText={'Construir conector'}
        crumbs={crumbs}
        filters={filters}
        onClick={hadleNewConnector}
      />
      <ConnectorsListContainer>
        {allConnectors?.map((connector, idx) => {
          return (
            <ConnectorContainer key={idx}>
              <ConnectorRedirectCard
                isPrivate={connector.isPrivate}
                redirectPath={`/connectors/${connector.id}`}
                imageUrl={connector.info.logoUrl}
                label={connector.info.publicName}
              />
            </ConnectorContainer>
          )
        })}
      </ConnectorsListContainer>

      <ButtonContainer>
        {haveIsMorePage && (
          <Button
            colorType="green"
            text="Carregar mais"
            variant="default"
            onClick={handleLoadMore}
          />
        )}
      </ButtonContainer>
    </Container>
  )
}
