import api from 'src/services/api'
import { IFindResponse, IFindTenantPayload } from '../@types/provider.interface'

export const findTenants = async ({
  currentPage,
  orderBy,
  search,
}: IFindTenantPayload) => {
  const response = await api.get<IFindResponse>('/tenants', {
    params: {
      limit: 20,
      page: currentPage,
      ...(search ? { search } : {}),
      ...(orderBy ? { orderby: orderBy } : {}),
    },
  })
  const { content, page } = response.data

  return {
    content,
    page,
  }
}
