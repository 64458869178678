import styled, { css } from 'styled-components'

import theme from '../../assets/styles/theme'
import LogoDevapi from '../../assets/images/logodevapi.svg'

export const Container = styled.div`
  font-size: 0.85rem;
  background-color: ${theme.colors.gray[200]};
  height: 100%;
  width: 223px;
  position: fixed;
  left: 0;
  top: 0;
  border-right: 1px solid ${theme.colors.gray[300]};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 2;
`

export const MenuLogoContainer = styled.div`
  background-image: url(${LogoDevapi});
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  background-size: 60%;
  height: 116px;
  width: 100%;
  border-bottom: 1px solid ${theme.colors.gray[300]};
`

export const IconContainer = styled.div`
  margin-right: 15px;
  margin-top: 0;
  display: flex;
  justify-content: center;
`

export const MenuItemsContainer = styled.div`
  padding: 0 10px;
`

export const Item = styled.div<{ isActive?: boolean }>`
  position: relative;
  margin: 20px 0;
  height: 40px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;

  :hover {
    background-color: ${theme.colors.white};
    border-radius: 5px;
    box-shadow: 0 0 1em ${theme.colors.gray[200]};
    cursor: pointer;
    box-shadow: 0 0 1em ${theme.colors.gray[300]};
  }

  ${props =>
    props.isActive &&
    css`
      background-color: ${theme.colors.white};
      border-radius: 5px;
      box-shadow: 0 0 1em ${theme.colors.gray[300]};
      cursor: pointer;
    `}
`

export const ItemText = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  > span {
    font-size: 1rem;
    color: #2c2d66;
  }
`

export const MascotContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  height: 6%;
  font-weight: 500;
  border-top: 1px solid ${theme.colors.gray[300]};
  bottom: 0;
`

export const SubMenu = styled.div`
  display: none;
  position: absolute;
  width: 200px;
  gap: 10px;
  left: 200px;
  top: -20px;
  padding: 10px;
  margin-left: 10px;
  border: 1px solid ${theme.colors.gray[300]};
  border-radius: 5px;
  box-shadow: 0 2px 10px 0 ${theme.colors.gray[300]};
  background-color: ${theme.colors.gray[200]};

  ::before {
    content: '';
    position: absolute;
    left: -10px;
    top: 25px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid ${theme.colors.gray[200]};
  }
`

export const SubMenuItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 5px 15px 5px 15px;

  :hover {
    background-color: ${theme.colors.white};
    border-radius: 5px;
    cursor: pointer;
  }

  > h3 {
    font-size: 1rem;
  }
`

export const SubMenuWrapper = styled.div`
  position: absolute;
  width: 110%;
  height: 100%;
  cursor: default;

  &:hover ${SubMenu} {
    display: flex;
    flex-direction: column;
    animation: animateIn 200ms;

    @keyframes animateIn {
      0% {
        transform: translateX(-20px);
        opacity: 0;
      }
      100% {
        transform: translateX(0px);
        opacity: 1;
      }
    }
  }
`
