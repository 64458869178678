import React from 'react'
import { Button } from '@devapi/design-system'
import { BiRightArrowAlt } from 'react-icons/bi'
import { AutomationCard } from 'src/components/AutomationCard'
import { ModalContent, ModalActions } from './SelectApiType.style'
import { ISelectApiTypeProps } from './SelectApiType.interface'
import { IconConnector } from './components/IconConnector'
// import { IconDatabase } from './components/IconDatabase'

const SelectApiType = ({ handleNextStep, onClose }: ISelectApiTypeProps) => {
  const handleStep = () => handleNextStep('step2')

  return (
    <>
      <ModalContent>
        {/* <AutomationCard
          icon={<IconDatabase />}
          text="Database"
          isSelected={false}
        /> */}
        <AutomationCard icon={<IconConnector />} text="Connector" isSelected />
      </ModalContent>
      <ModalActions>
        <Button
          type="button"
          variant="outlined"
          text="Cancelar"
          colorType="neutral"
          onClick={onClose}
        />
        <Button
          role="next-button"
          type="button"
          variant="default"
          text="Próximo"
          colorType="green"
          icon={<BiRightArrowAlt />}
          iconPosition="right"
          onClick={handleStep}
        />
      </ModalActions>
    </>
  )
}

export { SelectApiType }
