import api from 'src/services/api'
import { IUpdateAutomationPayload } from '../@types/providers.interface'

export const updateAutomation = async ({
  connectors,
  id,
  name,
}: IUpdateAutomationPayload) => {
  const response = await api.put(`/automations/${id}`, { name, connectors })

  return response.data
}
