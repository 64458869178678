/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react'

import {
  Button,
  InputSearch,
  Loading,
  notificationError,
  notificationSuccess,
  Pagination,
  Select,
  Table,
} from '@devapi/design-system'
import { InternalHeader } from 'src/components/InternalHeader'
import theme from 'src/assets/styles/theme'
import {
  BsArrowClockwise,
  BsPencil,
  BsTrash,
  BsClock,
  BsPlayFill,
} from 'react-icons/bs'
import {
  IFiltersState,
  IPaginationState,
  ISelectOptions,
  ITableJobDetails,
} from './Jobs.interface'
import { DeleteJob } from '../presentation/delete'
import {
  Container,
  Content,
  TableContainer,
  PaginationContainer,
} from './Jobs.style'
import { LimitJob } from '../components/LimitJob'
import { findJobById, findJobs } from '../providers/jobs.find'
import { findAutomation } from '../providers/automation.find'
import { runJob } from '../providers'
import { handleTableJob } from '../adapters/handleTableJob'
import { handleAutomationsSelect } from '../adapters/handleAutomationsSelect'
import { findTenants } from '../providers/tenants.find'
import { handleTenantsSelect } from '../adapters/handleTenants'
import { CreateJobs } from './create/CreateJobs'
import { EditJobs } from './edit'
import { IJobDetails } from './edit/EditJob.interface'
import { handleJobDetails } from '../adapters/handleJobDetails'
import { useHistory } from 'react-router-dom'

const Jobs = () => {
  const [isSearchJob, setIsSearchJob] = useState(false)
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false)
  const [isOpenEditModal, setIsOpenEditModal] = useState(false)
  const [responsive, setResponsive] = useState(false)
  const [jobs, setJobs] = useState<ITableJobDetails[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingAutomation, setIsLoadingAutomation] = useState(false)
  const [isDeleteJobOpen, setIsDeleteJobOpen] = useState(false)
  const [jobIdToDelete, setJobIdToDelete] = useState('')
  const [jobDetails, setJobDetails] = useState({} as IJobDetails)
  const [totalJobs, setTotalJobs] = useState(0)
  const [currentJobs, setCurrentJobs] = useState(0)
  const [pagination, setPagination] = useState({} as IPaginationState)
  const [currentPage, setCurrentPage] = useState(1)
  const [filters, setFilters] = useState({} as IFiltersState)
  const [projectSelect, setProjectSelect] = useState([
    {
      label: '',
      value: '',
    },
  ])

  const [tenantSelect, setTenantSelect] = useState([
    {
      label: '',
      value: '',
    },
  ])

  const [automationSelect, setAutomationSelect] = useState([
    {
      label: '',
      value: '',
    },
  ])

  const history = useHistory()

  const width = window.innerWidth

  const crumbs = [{ label: 'Jobs', value: 'jobs' }]
  useEffect(() => {
    if (width <= 1366) {
      setResponsive(true)
    } else {
      setResponsive(false)
    }
  }, [width])

  const columns = [
    { key: 'project', label: 'Projeto', isOrder: true, columnWidth: '300px' },
    { key: 'name', label: 'Nome', isOrder: true },
    { key: 'automation', label: 'Automação' },
    { key: 'tenant', label: 'Tenant', isOrder: true },
    { key: 'type', label: 'Tipo' },
    { key: 'opt', label: 'Opções', columnWidth: '90px' },
  ]

  useEffect(() => {
    listAutomations()
    listTenants()
  }, [])

  useEffect(() => {
    listJobs()
  }, [currentPage, filters])

  const listJobs = async () => {
    try {
      setIsLoading(true)
      const { data } = await findJobs(currentPage, filters)

      const { tableDetails, automationSelect } = handleTableJob(data)

      setJobs(tableDetails)
      setAutomationSelect(automationSelect)
      setCurrentJobs(data.content.length)
      setPagination({ ...pagination, totalPages: data.page.totalPages })
      setTotalJobs(data.metrics.limit)
      setCurrentJobs(data.metrics.current)
    } catch (error) {
      notificationError({
        message:
          error.response?.data?.message || 'Não foi possível carregar os jobs',
        position: 'top-right',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const listAutomations = async () => {
    try {
      const { data } = await findAutomation()
      const selectProjectOptions = handleAutomationsSelect(data)
      setProjectSelect(selectProjectOptions)
    } catch (error) {
      notificationError({
        message:
          error.response?.data?.message ||
          'Não foi possível carregar as automações',
        position: 'top-right',
      })
    }
  }

  const listTenants = async () => {
    try {
      const { data } = await findTenants()
      const selectTenantOptions = handleTenantsSelect(data)
      setTenantSelect(selectTenantOptions)
    } catch (error) {
      notificationError({
        message:
          error.response?.data?.message ||
          'Não foi possível carregar os tenants',
        position: 'top-right',
      })
    }
  }

  const deleteJobById = itemRow => {
    const findJob = jobs.find(item => item.id === itemRow.id)
    if (findJob && findJob.id) {
      setJobIdToDelete(findJob.id)
      setIsDeleteJobOpen(true)
    }
  }

  const runJobById = async itemRow => {
    const findJob = jobs.find(item => item.id === itemRow.id)
    if (findJob && findJob.id) {
      try {
        setIsLoadingAutomation(true)
        await runJob(findJob.id)

        notificationSuccess({
          message: 'Automação executada com sucesso!',
          position: 'top-right',
        })
      } catch (error) {
        notificationError({
          message:
            error.response?.data?.message ||
            'Não foi possível executar a automação',
          position: 'top-right',
        })
      } finally {
        setIsLoadingAutomation(false)
      }
    }
  }

  const editJobById = async itemRow => {
    const findJob = jobs.find(item => item.id === itemRow.id)
    if (findJob && findJob.id) {
      try {
        setIsOpenEditModal(true)
        setIsSearchJob(true)
        const { data } = await findJobById(findJob.id)

        setJobDetails(handleJobDetails(data))
      } catch (error) {
        setIsOpenEditModal(false)
        notificationError({
          message:
            error.response?.data?.message || 'Não foi possível carregar o job.',
          position: 'top-right',
        })
      } finally {
        setIsSearchJob(false)
      }
    }
  }

  const viewLogs = itemRow => {
    const { id } = itemRow
    history.push(`/ipaas/jobs/${id}/logs`)
  }

  const handleCloseDeleteJob = () => {
    setJobIdToDelete('')
    setIsDeleteJobOpen(false)
  }

  const handleChangePage = (page: number) => {
    setCurrentPage(page)
  }

  const handleSearchTerm = (searchTerm: string) => {
    setFilters({ ...filters, searchTerm: searchTerm })
  }

  const handleSelectTenant = (tenant: ISelectOptions) => {
    setFilters({ ...filters, tenant: tenant.value })
  }

  const handleSelectProject = (project: ISelectOptions) => {
    setFilters({ ...filters, project: project.value })
  }

  const handleOrderBy = (colum: string) => {
    const validOrderBy = {
      name: 'name',
      project: 'automation_id',
      tenant: 'tenant_id',
    }

    setFilters({ ...filters, orderBy: validOrderBy[colum] })
  }

  const headerFilters = [
    {
      field: (
        <InputSearch
          label="Encontrar job"
          fieldSize={responsive ? '250px' : '320px'}
          onChange={handleSearchTerm}
          placeHolder="Pesquisa"
          isLabelBold={false}
        />
      ),
    },
    {
      field: (
        <Select
          name="projectFilter"
          label="Projeto"
          defaultValue="Selecione"
          optionList={projectSelect}
          fieldSize={responsive ? '250px' : '320px'}
          backgroundColor={theme.colors.secondary}
          onClick={handleSelectProject}
          isLabelBold={false}
        />
      ),
    },
    {
      field: (
        <Select
          name="projectTenants"
          label="Tenants"
          defaultValue="Selecione"
          optionList={tenantSelect}
          fieldSize={responsive ? '250px' : '320px'}
          backgroundColor={theme.colors.secondary}
          onClick={handleSelectTenant}
          isLabelBold={false}
        />
      ),
    },
  ]

  const handleOpenCreateModal = () => {
    setIsOpenCreateModal(!isOpenCreateModal)
  }

  const handleOpenEditModal = () => {
    setIsOpenEditModal(!isOpenEditModal)
  }

  return (
    <>
      <Loading isOpen={isLoadingAutomation} text="Executando automação..." />

      <Loading isOpen={isLoading} text="Buscando jobs..." />

      <Container>
        <InternalHeader
          filters={headerFilters}
          crumbs={crumbs}
          buttonText="Novo job"
          titleLineItem={<LimitJob total={totalJobs} value={currentJobs} />}
          onClick={handleOpenCreateModal}
        />
        <Content>
          {!isLoading ? (
            <TableContainer>
              <Table
                noContentText="Nenhum resultado encontrado."
                tableData={jobs}
                tableHeaderOptions={columns}
                orderBy={['project', 'name', 'tenant']}
                sortTableData={handleOrderBy}
                optionsActions={[
                  <BsClock
                    {...theme.icon}
                    color={theme.colors.green}
                    onClick={viewLogs}
                  />,
                  <BsPlayFill
                    {...theme.icon}
                    color={theme.colors.gray[800]}
                    onClick={runJobById}
                  />,
                  <BsPencil
                    {...theme.icon}
                    color={theme.colors.primary}
                    onClick={editJobById}
                  />,
                  <BsTrash
                    {...theme.icon}
                    color={theme.colors.primary}
                    onClick={deleteJobById}
                  />,
                ]}
                buttonReload={
                  <Button
                    colorType="green"
                    variant="outlined"
                    text="Recarregar"
                    icon={<BsArrowClockwise />}
                  />
                }
              />
            </TableContainer>
          ) : (
            ''
          )}
        </Content>
        {pagination.totalPages > 1 && (
          <PaginationContainer>
            <Pagination
              size="default"
              totalPages={pagination.totalPages}
              page={currentPage}
              onChange={(page: number) => handleChangePage(page)}
            />
          </PaginationContainer>
        )}
      </Container>

      <CreateJobs
        isOpen={isOpenCreateModal}
        onClickCloseModal={handleOpenCreateModal}
        tenantOptions={tenantSelect}
        projectOptions={projectSelect}
        onSuccess={listJobs}
      />

      <EditJobs
        jobDetails={jobDetails}
        isSearchJob={isSearchJob}
        isOpen={isOpenEditModal}
        onClickCloseModal={handleOpenEditModal}
        automationOptions={automationSelect}
        tenantOptions={tenantSelect}
        projectOptions={projectSelect}
        onSuccess={listJobs}
      />

      <DeleteJob
        isOpen={isDeleteJobOpen}
        onClose={handleCloseDeleteJob}
        onSuccess={listJobs}
        jobId={jobIdToDelete}
      />
    </>
  )
}

export { Jobs }
