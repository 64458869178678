import theme from 'src/assets/styles/theme'
import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
`

export const TableContainer = styled.div`
  width: 100%;
  height: 77vh;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  padding: 50px 50px 0px 50px;
  margin-bottom: 30px;

  overflow: auto;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: auto;
`

const colorVariation = {
  success: theme.colors.green,
  error: theme.colors.red,
}

export const Card = styled.div<{ color }>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 33px;
  height: 33px;

  background-color: ${props => colorVariation[props.color]};

  color: white;
  border-radius: 3px;
  padding-top: 3px;
`
