import React from 'react'
import { useDashboard } from '../../contexts/DashboardContext'
import { Select } from '@devapi/design-system'
import { Container, InputContainer, Title } from './ChartFilter.style'

export const ChartFilter = () => {
  const { handleFindDashboardData } = useDashboard()
  const optionList = [
    { label: '7 dias', value: '7' },
    { label: '15 dias', value: '15' },
    { label: '30 dias', value: '30' },
  ]

  return (
    <Container>
      <Title>Visão Geral</Title>
      <InputContainer>
        <Select
          name="limit"
          label="Limite de dias"
          optionList={optionList}
          onClick={e => handleFindDashboardData(String(e.value))}
          isLabelBold={false}
        />
      </InputContainer>
    </Container>
  )
}
