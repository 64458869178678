import api from '../../../services/api'
import { IFindAllAPIResponse } from '../@types/providers.interface'

import { IFiltersState } from '../presentation/ApiManagement.interface'

export const findAllApi = async (page: number, filters?: IFiltersState) => {
  return await api.get<IFindAllAPIResponse>('/apis', {
    params: {
      limit: 20,
      page: page,
      ...(filters?.searchTerm && { search: filters.searchTerm }),
      ...(filters?.orderBy && { orderby: filters.orderBy }),
    },
  })
}
