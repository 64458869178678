import React from 'react'
import { IconContainer, PlusIcon } from './IconPlus.style'

export const IconPlus = () => {
  return (
    <IconContainer>
      <PlusIcon />
    </IconContainer>
  )
}
