import {
  IAuthorizationPayload,
  IDefaultParamsPayload,
  IPayloadInformation,
  IResourcePayload,
} from '../@types'
import api from 'src/services/api'

export const updateConnectorInfo = (
  id: string,
  payload: IPayloadInformation,
) => {
  const { info } = payload
  return api.put(`/connectors/${id}`, { info })
}

export const updateConnectorAuthentication = (
  connectorId: string,
  authorizationId: string,
  payload: IAuthorizationPayload,
) => {
  return api.put(
    `/connectors/${connectorId}/authorizations/${authorizationId}`,
    payload,
  )
}

export const updateConnectorDefaultParamters = (
  connectorId: string,
  defaultParamtersId: string,
  payload: IDefaultParamsPayload,
) => {
  return api.put(
    `/connectors/${connectorId}/default-params/${defaultParamtersId}`,
    payload,
  )
}

export const updateConnectorResource = (
  connectorId: string,
  resourceId: string,
  payload: IResourcePayload,
) => {
  return api.put(`/connectors/${connectorId}/resources/${resourceId}`, payload)
}
