import { Form } from 'formik'
import styled, { css } from 'styled-components'
import { IInputContainerProps } from '../../@types'

export const InformationContainer = styled.div`
  padding: 30px;
`

export const InputContainer = styled.div<IInputContainerProps>`
  margin-bottom: 30px;

  ${({ size }) => css`
    width: ${size};
  `}
`
export const FormContainer = styled(Form)`
  display: flex;

  flex-wrap: wrap;
  justify-content: space-between;
`
export const ButtonsContainer = styled.div`
  width: 100%;

  display: flex;

  justify-content: space-between;
`
export const DeleteAndNextContainer = styled.div`
  display: flex;
  button {
    margin-left: 15px;
  }
`
