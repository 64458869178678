import styled from 'styled-components'
import { Form } from 'formik'
export const Content = styled.div`
  width: 470px;
  height: 220px;
`

export const SubTitle = styled.div`
  font-size: 15px;
`

export const FormContainer = styled(Form)`
  margin-top: 10px;
  height: 170px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px 0px 0px 0px;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
