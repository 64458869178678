import { IInformationForm } from '../@types'

export const handleInformationPayload = (data: IInformationForm) => {
  const {
    info: {
      baseUrl,
      categoryId,
      description,
      internalName,
      logoUrl,
      publicName,
      type,
    },
  } = data

  return {
    ...data,
    info: {
      public_name: publicName,
      internal_name: internalName,
      type: type,
      base_url: baseUrl,
      logo_url: logoUrl,
      category_id: categoryId,
      description: description,
    },
  }
}
