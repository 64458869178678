import React, { useState } from 'react'
import {
  Button,
  InputText,
  Select,
  Switch,
  Tab,
  TextArea,
} from '@devapi/design-system'
import { BsGear } from 'react-icons/bs'
import { FiPlusCircle, FiTrash2 } from 'react-icons/fi'
import { IoChevronDownOutline, IoChevronUpOutline } from 'react-icons/io5'
import theme from 'src/assets/styles/theme'
import {
  IItemTab,
  IMethodStyles,
  INewResourceColapseProps,
  ISelectOptions,
} from '../../@types'
import {
  TabContainer,
  Container,
  TitleContainer,
  Title,
  IconContainer,
  Colapse,
  InputsResourceContainer,
  MethodCard,
  RouteContainer,
  IconsContainer,
  InputContainer,
  InputRow,
  NoParamsContainer,
  NoParamsText,
  SelectContainer,
  FormContainer,
  SwitchContainer,
  SwitchLabel,
  DeleteContainer,
  TextAreaGeneralContainer,
  TextAreaParametersContainer,
} from './NewResourceColapse.style'

export const NewResourceColapse = ({
  resources,
  name,
  resourceIdx,
  handleDeleteRoute,
  handleAddParam,
  handleDeleteParams,
  handleParametersRequired,
  handleSelectParametersType,
  privacy,
}: INewResourceColapseProps) => {
  const general = 'general'
  const parameters = 'parameters'
  const [isOpenColapse, setIsOpenColapse] = useState(false)
  const [isOpenResourceTab, setIsOpenResourceTab] = useState('')
  const [selectedTab, setSelectedTab] = useState<IItemTab>({
    labelText: 'Geral',
    eventValue: 'general',
  })

  const handleOpen = () => {
    setIsOpenColapse(!isOpenColapse)
  }

  const handleOpenResourceTab = (resourceIndex: string) => {
    if (isOpenResourceTab === resourceIndex) {
      setIsOpenResourceTab('')
      setSelectedTab({
        labelText: 'Geral',
        eventValue: 'general',
      })
      return
    }
    setIsOpenResourceTab(resourceIndex)
  }

  const methodStyle: IMethodStyles = {
    GET: { color: '#3168FF', type: 'get' },
    POST: { color: '#52C174 ', type: 'post' },
    PUT: { color: '#F7AA2B ', type: 'put' },
    DELETE: { color: '#F14640 ', type: 'delete' },
  }

  const tabs: IItemTab[] = [
    { labelText: 'Geral', eventValue: general },
    { labelText: 'Parâmetros', eventValue: parameters },
  ]

  const paramsOptions = [
    {
      label: 'QueryString',
      value: 'queryString',
    },
    {
      label: 'URL Param',
      value: 'urlParam',
    },
    {
      label: 'Body',
      value: 'body',
    },
    {
      label: 'Form Data',
      value: 'formData',
    },
  ]

  const handleTab = (tab: IItemTab) => {
    setSelectedTab(tab)
  }

  return (
    <Container>
      <TitleContainer onClick={handleOpen}>
        <Title>{name}</Title>
        <IconContainer>
          {isOpenColapse ? <IoChevronUpOutline /> : <IoChevronDownOutline />}
        </IconContainer>
      </TitleContainer>
      <Colapse isOpen={isOpenColapse}>
        {resources.map((resource, idx) => {
          return (
            <>
              <InputsResourceContainer key={idx}>
                <MethodCard
                  color={methodStyle[resource.method.toLocaleUpperCase()].color}
                >
                  <p>{resource.method}</p>
                </MethodCard>
                <RouteContainer>{resource.route}</RouteContainer>
                <InputText
                  isFormik
                  name={`[resources][${resourceIdx}][routes][${idx}][concatRoute]`}
                  value={resource.concatRoute}
                  requestStyle={methodStyle[resource.method].type}
                  disabled={privacy === 'PUBLIC'}
                />
                <IconsContainer>
                  <BsGear
                    size={'16px'}
                    color={theme.colors.primary}
                    onClick={() => handleOpenResourceTab(String(idx))}
                  />
                  {privacy === 'PRIVATE' && (
                    <FiTrash2
                      size="16px"
                      color={theme.colors.primary}
                      onClick={() => handleDeleteRoute(resourceIdx, idx)}
                    />
                  )}
                </IconsContainer>
              </InputsResourceContainer>
              <Colapse isOpen={isOpenResourceTab === String(idx)}>
                <div>
                  <Tab
                    tabItems={tabs}
                    isActiveTab={selectedTab}
                    onClick={handleTab}
                  />
                  {selectedTab.eventValue === general && (
                    <div>
                      <InputContainer>
                        <InputText
                          isFormik
                          label="Título"
                          name={`[resources][${resourceIdx}][routes][${idx}][title]`}
                          value={resource.title}
                          disabled={privacy === 'PUBLIC'}
                        />
                      </InputContainer>
                      <TextAreaGeneralContainer>
                        <TextArea
                          isFormik
                          name={`[resources][${resourceIdx}][routes][${idx}][description]`}
                          label="Descrição"
                          fieldWidth="70%"
                          rows={4}
                          value={resource.description}
                          isDisabled={privacy === 'PUBLIC'}
                        />
                      </TextAreaGeneralContainer>
                    </div>
                  )}
                  {selectedTab.eventValue === parameters && (
                    <>
                      {!resource.params.length ? (
                        <NoParamsContainer>
                          <NoParamsText>
                            Não há parâmetros padrão cadastrado.
                          </NoParamsText>
                          <Button
                            colorType="green"
                            type="button"
                            variant="default"
                            text="Adicionar parâmetros padrão"
                            icon={<FiPlusCircle />}
                            iconPosition="left"
                            onClick={() => handleAddParam(resourceIdx, idx)}
                            isDisabled={privacy === 'PUBLIC'}
                          />
                        </NoParamsContainer>
                      ) : (
                        <TabContainer>
                          <Button
                            colorType="green"
                            type="button"
                            variant="default"
                            text="Adicionar parâmetros padrão"
                            icon={<FiPlusCircle />}
                            iconPosition="left"
                            onClick={() => handleAddParam(resourceIdx, idx)}
                            isDisabled={privacy === 'PUBLIC'}
                          />

                          {resource.params.map((field, index) => {
                            const _prefix = `[resources][${resourceIdx}][routes][${idx}][params][${index}]`
                            return (
                              <FormContainer key={index}>
                                <SelectContainer>
                                  <Select
                                    isFormik
                                    label="Tipo"
                                    name={`${_prefix}[type]`}
                                    value={field.type}
                                    optionList={paramsOptions}
                                    onClick={(element: ISelectOptions) =>
                                      handleSelectParametersType(
                                        resourceIdx,
                                        idx,
                                        index,
                                        element.value,
                                      )
                                    }
                                    isDisable={privacy === 'PUBLIC'}
                                    isLabelBold={true}
                                  />
                                </SelectContainer>
                                <InputRow>
                                  <InputContainer>
                                    <InputText
                                      isFormik
                                      name={`${_prefix}[fieldName]`}
                                      label="Nome do campo"
                                      value={field.fieldName}
                                      disabled={privacy === 'PUBLIC'}
                                    />
                                  </InputContainer>
                                  <InputContainer>
                                    <InputText
                                      isFormik
                                      name={`${_prefix}[displayName]`}
                                      label="Nome em exibição"
                                      value={field.displayName}
                                      disabled={privacy === 'PUBLIC'}
                                    />
                                  </InputContainer>
                                  <TextAreaParametersContainer>
                                    <TextArea
                                      isFormik
                                      label="Descrição"
                                      fieldWidth="100%"
                                      name={`${_prefix}[description]`}
                                      value={field.description}
                                      isDisabled={privacy === 'PUBLIC'}
                                    />
                                  </TextAreaParametersContainer>
                                  <SwitchContainer>
                                    <SwitchLabel>Obrigatório</SwitchLabel>
                                    <Switch
                                      isChecked={field.required}
                                      onChange={() =>
                                        handleParametersRequired(
                                          resourceIdx,
                                          idx,
                                          index,
                                        )
                                      }
                                      isDisabled={privacy === 'PUBLIC'}
                                    />
                                  </SwitchContainer>
                                  {privacy === 'PRIVATE' && (
                                    <DeleteContainer
                                      data-testid="button-delete"
                                      onClick={() =>
                                        handleDeleteParams(
                                          resourceIdx,
                                          idx,
                                          index,
                                        )
                                      }
                                    >
                                      <FiTrash2
                                        size="16px"
                                        color={theme.colors.red}
                                      />
                                    </DeleteContainer>
                                  )}
                                </InputRow>
                              </FormContainer>
                            )
                          })}
                        </TabContainer>
                      )}
                    </>
                  )}
                </div>
              </Colapse>
            </>
          )
        })}
      </Colapse>
    </Container>
  )
}
