import React from 'react'
import { IUserResponse } from '../@types/users.interface'
import { Switch } from '@devapi/design-system'
import { handleUpdateUserStatus } from '../utils'

export const serializeUsersForTable = (
  users: IUserResponse[],
  onSuccess: () => void,
) => {
  if (!users.length) return []
  return users.map(user => ({
    id: user._id,
    completeName: `${user.name} ${user.surname}`,
    email: user.email,
    completePhone: `${user.country_code} ${user.area_code} ${user.phone}`,
    name: user.name,
    surname: user.surname,
    countryCode: user.country_code,
    areaCode: user.area_code,
    phone: user.phone,
    status: (
      <Switch
        isChecked={user.status === 'DISABLED'}
        onChange={() =>
          handleUpdateUserStatus({
            userId: user._id,
            actualStatus: user.status,
            onSuccess: onSuccess,
          })
        }
      />
    ),
  }))
}
