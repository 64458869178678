import api from 'src/services/api'
import { IUserCreateBody } from '../@types/users.interface'

export const createUser = async (user: IUserCreateBody) => {
  const { name, surname, email, countryCode, areaCode, phoneNumber } = user
  await api.post('/companies/users', {
    name: name,
    surname: surname,
    email: email,
    country_code: countryCode,
    area_code: areaCode,
    phone: phoneNumber,
  })
}
