import { notificationError, notificationSuccess } from '@devapi/design-system'
import api from 'src/services/api'
import { IUpdatePassData } from '../@types/UpdatePass.interface'

export const putPass = (
  formPass: IUpdatePassData,
  userId: string,
): Promise<void> => {
  return api
    .put(`/auth/change-password/user/${userId}`, formPass, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(() => {
      notificationSuccess({
        message: 'Senha alterada com sucesso',
        position: 'top-right',
      })
    })
    .catch(
      ({
        response: {
          data: { message },
        },
      }) => {
        notificationError({ message, position: 'top-right' })
      },
    )
}
