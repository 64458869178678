import React, { useState } from 'react'
import { Modal } from '@devapi/design-system'
import { SelectApiType } from './components/SelectApiType'
import { ApiForm } from './components/ApiForm'
import { ApiConnectorsForm } from './components/ApiConnectorsForm'
import { ICreateApiProps } from './CreateApi.interface'
import { ICreateApiPayload } from '../../@types/shared.interface'

const CreateApi = ({ isOpen, onClose }: ICreateApiProps) => {
  const [stepActive, setStepActive] = useState('step1')
  const [formData, setFormData] = useState<ICreateApiPayload>(
    {} as ICreateApiPayload,
  )

  const handleCloseModal = () => {
    onClose()
    setStepActive('step1')
    setFormData({} as ICreateApiPayload)
  }

  const handleNextStep = (nextStep: string) => {
    setStepActive(nextStep)
  }

  const handleOnSubmitStepOne = ({
    name,
    description,
    url,
  }: ICreateApiPayload) => {
    setFormData(values => {
      return { ...values, name, description, url }
    })
  }

  const handleSaveConnectors = ({ connectors }: ICreateApiPayload) => {
    setFormData(values => {
      return { ...values, connectors }
    })
  }

  const steps = {
    step1: (
      <SelectApiType
        onClose={handleCloseModal}
        handleNextStep={handleNextStep}
      />
    ),
    step2: (
      <ApiForm
        formData={formData}
        onClose={handleCloseModal}
        handleNextStep={handleNextStep}
        handleOnSubmit={handleOnSubmitStepOne}
      />
    ),
    step3: (
      <ApiConnectorsForm
        formData={formData}
        handleSaveConnectors={handleSaveConnectors}
        onClose={handleCloseModal}
        handleNextStep={handleNextStep}
      />
    ),
  }

  return (
    <Modal
      isOpen={isOpen}
      title="Nova API"
      subTitle={
        stepActive === 'step1'
          ? 'Selecione abaixo a partir de qual opção deseja continuar'
          : ''
      }
      onClick={handleCloseModal}
    >
      {steps[stepActive]}
    </Modal>
  )
}

export { CreateApi }
