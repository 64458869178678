import styled from 'styled-components'
import theme from 'src/assets/styles/theme'

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  width: 100%;
  height: 100%;

  border: 1px solid ${theme.colors.gray[300]};
  border-radius: 5px;

  @media only screen and (max-width: 1400px) {
    padding: 0 0 20px 0;
  }
`

export const InputContainer = styled.div`
  width: 446px;
  height: 76px;

  margin-bottom: 20px;
`

export const Form = styled.form`
  display: flex;
  justify-content: flex-start;
  align-content: space-between;
  flex-direction: column;

  margin: 50px 0px 0px 30px;
`
export const Title = styled.h1`
  font-size: 22px;
  font-weight: bold;

  margin: 30px 0px 0px 30px;
  p {
    margin-top: 10px;
    font-weight: normal;
    font-size: 16px;
  }
`

export const ButtonContainer = styled.div`
  width: 156px;
  height: 40px;
`
