import styled from 'styled-components'

export const ModalContent = styled.div`
  display: flex;
  justify-content: center;
`

export const ModalActions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    margin-right: 15px;
  }
`
