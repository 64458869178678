import React, { useState } from 'react'
import {
  DeleteAlert,
  Loading,
  notificationError,
  notificationSuccess,
} from '@devapi/design-system'
import { DeletePermissionProps } from './DeletePermission.interface'
import { deletePermission } from '../../../providers'

export const DeletePermission = ({
  permissionIds,
  isOpen,
  onClose,
  onSuccess,
}: DeletePermissionProps) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleDeletePermission = async () => {
    try {
      setIsLoading(true)
      const { apiId, tenantId } = permissionIds
      await deletePermission(apiId, tenantId)
      onSuccess()
      notificationSuccess({
        message: 'Permissão deletada com sucesso!',
        position: 'top-right',
      })
    } catch (error) {
      const { message } = error.response.data
      notificationError({
        message: message || 'Erro ao deletar permissão',
        position: 'top-right',
      })
    } finally {
      setIsLoading(false)
      onClose()
    }
  }

  return (
    <>
      {isLoading && (
        <Loading isOpen={isLoading} text="Deletando permissão..." />
      )}
      {isOpen && (
        <DeleteAlert
          onClickCancel={onClose}
          language="pt-br"
          onClickDelete={handleDeletePermission}
        />
      )}
    </>
  )
}
