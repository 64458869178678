import TTCommonsBlack from './../fonts/TTCommons/TTCommons-Black.ttf'
import TTCommonsBlackItalic from './../fonts/TTCommons/TTCommons-BlackItalic.ttf'
import TTCommonsBold from './../fonts/TTCommons/TTCommons-Bold.ttf'
import TTCommonsBoldItalic from './../fonts/TTCommons/TTCommons-BoldItalic.ttf'
import TTCommonsDemiBold from './../fonts/TTCommons/TTCommons-DemiBold.ttf'
import TTCommonsDemiBoldItalic from './../fonts/TTCommons/TTCommons-DemiBoldItalic.ttf'
import TTCommonsExtraBold from './../fonts/TTCommons/TTCommons-ExtraBold.ttf'
import TTCommonsExtraBoldItalic from './../fonts/TTCommons/TTCommons-ExtraBoldItalic.ttf'
import TTCommonsExtraLight from './../fonts/TTCommons/TTCommons-ExtraLight.ttf'
import TTCommonsExtraLightItalic from './../fonts/TTCommons/TTCommons-ExtraLightItalic.ttf'
import TTCommonsItalic from './../fonts/TTCommons/TTCommons-Italic.ttf'
import TTCommonsLight from './../fonts/TTCommons/TTCommons-Light.ttf'
import TTCommonsLightItalic from './../fonts/TTCommons/TTCommons-LightItalic.ttf'
import TTCommonsMedium from './../fonts/TTCommons/TTCommons-Medium.ttf'
import TTCommonsMediumItalic from './../fonts/TTCommons/TTCommons-MediumItalic.ttf'
import TTCommonsRegular from './../fonts/TTCommons/TTCommons-Regular.ttf'
import TTCommonsThin from './../fonts/TTCommons/TTCommons-Thin.ttf'
import TTCommonsThinItalic from './../fonts/TTCommons/TTCommons-ThinItalic.ttf'

export default {
  black: TTCommonsBlack,
  blackItalic: TTCommonsBlackItalic,
  bold: TTCommonsBold,
  boldItalic: TTCommonsBoldItalic,
  demiBold: TTCommonsDemiBold,
  demiBoldItalic: TTCommonsDemiBoldItalic,
  extraBold: TTCommonsExtraBold,
  extraBoldItalic: TTCommonsExtraBoldItalic,
  extraLight: TTCommonsExtraLight,
  extraLightItalic: TTCommonsExtraLightItalic,
  italic: TTCommonsItalic,
  light: TTCommonsLight,
  lightItalic: TTCommonsLightItalic,
  medium: TTCommonsMedium,
  mediumItalic: TTCommonsMediumItalic,
  regular: TTCommonsRegular,
  thin: TTCommonsThin,
  thinItalic: TTCommonsThinItalic,
}
