import api from 'src/services/api'

export const updateUserStatus = async (
  userId: string,
  actualStatus: 'ACTIVE' | 'DISABLED',
) => {
  await api.patch(`/companies/users/${userId}/update-status`, {
    status: actualStatus === 'ACTIVE' ? 'DISABLED' : 'ACTIVE',
  })
}
