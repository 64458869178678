import { IJobsResponse } from '../@types/providers.interface'
import { IHandlerResponse } from '../presentation/Jobs.interface'

export const handleTableJob = (jobDetails: IJobsResponse): IHandlerResponse => {
  if (!jobDetails.content.length)
    return {
      tableDetails: [],
      automationSelect: [
        {
          label: '',
          value: '',
        },
      ],
    }

  return {
    tableDetails: jobDetails.content.map(item => ({
      project: item.automation_id.name || '-',
      name: item.name || '-',
      automation: item.deploy_handler || '-',
      tenant: item.tenant_id.name || '-',
      type: item.automation_id.type || '-',
      id: item._id,
    })),
    automationSelect: jobDetails.content.map(item => ({
      label: item.deploy_handler,
      value: item.deploy_path,
    })),
  }
}
