import { useContext } from 'react'
import { IAuthContextState } from './Auth.interface'
import { AuthContext, AuthProvider } from './Auth'

const useAuth = (): IAuthContextState => {
  const context = useContext(AuthContext)

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }

  return context
}
export { AuthProvider, useAuth }
