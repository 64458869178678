import styled, { css } from 'styled-components'

import { IOptionProfileStyle } from './Profile.interface'
import theme from '../../assets/styles/theme'

export const Container = styled.div`
  width: 100%;
  height: 91%;
`

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  padding: 25px;
  height: 100%;
`

export const OptionsProfileContainer = styled.div`
  width: 25%;
  padding: 15px 50px 15px 26px;
  height: 100%;
`

export const OptionsProfile = styled.div<IOptionProfileStyle>`
  display: flex;
  align-items: center;
  padding: 0 1vw;
  margin-bottom: 7px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 0.9rem;
  height: 70px;
  cursor: pointer;

  ${props =>
    props.isActiveItem &&
    css`
      background-color: ${theme.colors.gray[200]};
    `};

  @media screen and (max-width: 1366px) {
    height: 60px;
  }
`

export const ProfileContentContainer = styled.div`
  width: 95.5%;
  padding: 15px 0;
`

export const PageProfileContent = styled.div`
  border: 1px solid ${theme.colors.gray[200]};
  border-radius: 10px;
  font-weight: bold;
  padding: 23px 1vw;

  @media screen and (max-width: 1366px) {
    padding: 20px 1vw;
  }
`
