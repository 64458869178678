import { IParamsData, IResource, IRoutesPayload } from '../@types'

export const handleResourceParams = (data: IResource) => {
  const { groups } = data

  const serializedGroups = groups.map(group => {
    const newRoutes = group.routes.map(routeMap => {
      const params = handleParams(routeMap)
      const [route, ...rest] = routeMap.route.split('/')
      return {
        title: routeMap.title,
        route: `${route}/`,
        description: routeMap.description,
        method: routeMap.method,
        params,
        formData: [],
        concatRoute: rest.join('/'),
      }
    })

    return { name: group.name, routes: newRoutes }
  })

  return {
    version: data.version,
    groups: serializedGroups,
  }
}

const handleParams = (data: IRoutesPayload) => {
  const params: IParamsData[] = []

  if (data.body) {
    data.body.forEach(item => {
      params.push({
        description: item.description,
        fieldName: item.field_name,
        required: item.required,
        type: 'body',
        displayName: item.display_name,
      })
    })
  }
  if (data.form_data) {
    data.form_data.forEach(item => {
      params.push({
        description: item.description,
        fieldName: item.field_name,
        required: item.required,
        type: 'formData',
        displayName: item.display_name,
      })
    })
  }
  if (data.query_string) {
    data.query_string.forEach(item => {
      params.push({
        description: item.description,
        fieldName: item.field_name,
        required: item.required,
        type: 'queryString',
        displayName: item.display_name,
      })
    })
  }
  if (data.url_params) {
    data.url_params.forEach(item => {
      params.push({
        description: item.description,
        fieldName: item.field_name,
        required: item.required,
        type: 'urlParam',
        displayName: item.display_name,
      })
    })
  }
  return params
}
