import React, { useCallback, useRef } from 'react'
import { ConnectorCard } from '@devapi/design-system'
import { ConnectorsWrapper, ListConnectors } from './ConnectorsList.style'
import { IConnectorsListProps } from './ConnectorsList.interface'
import logo from 'src/assets/images/generic-connector-logo.svg'

const ConnectorsList = ({
  connectors,
  currentPage,
  totalPages,
  handleConector,
  handleNextPage,
}: IConnectorsListProps) => {
  const ref = useRef<IntersectionObserver>()
  const lastConnectorElementRef = useCallback(
    node => {
      if (ref && ref.current) ref?.current?.disconnect()
      ref.current = new IntersectionObserver(entries => {
        if (currentPage && totalPages) {
          if (entries[0].isIntersecting && currentPage < totalPages) {
            handleNextPage(currentPage + 1)
          }
        }
      })
      if (node) ref.current.observe(node)
    },
    [currentPage, totalPages],
  )
  return (
    <>
      <ConnectorsWrapper>
        <ListConnectors>
          {connectors.map((connector, index) => {
            if (connectors.length === index + 1) {
              return (
                <div key={connector.id} ref={lastConnectorElementRef}>
                  <ConnectorCard
                    key={Math.random()}
                    isPrivate={connector.privacy === 'PRIVATE'}
                    isSelected={connector.isSelected}
                    id={connector.id}
                    imageUrl={connector.info.logoUrl}
                    label={connector.info.publicName}
                    onSelect={handleConector}
                  />
                </div>
              )
            }
            return (
              <ConnectorCard
                key={Math.random()}
                isPrivate={connector.privacy === 'PRIVATE'}
                isSelected={connector.isSelected}
                id={connector.id}
                imageUrl={connector.info.logoUrl || logo}
                label={connector.info.publicName}
                onSelect={handleConector}
              />
            )
          })}
        </ListConnectors>
      </ConnectorsWrapper>
    </>
  )
}

export { ConnectorsList }
