import React, { useState, useEffect } from 'react'
import { useDashboard } from '../../contexts/DashboardContext'
import {
  Container,
  ChartLabel,
  ChartContainer,
  ChartKpi,
  ProgressBar,
  FlexBetween,
  ContainerBar,
  BackgroundBar,
  Text,
  Amount,
  Align,
} from './ChartOverviewKpi.style'
import {
  FiActivity,
  FiZap,
  FiUsers,
  FiBriefcase,
  FiCloud,
} from 'react-icons/fi'
import { insertBRNumberMask } from 'src/utils/insertBRNumberMask'

export const ChartOverviewKpi = () => {
  const [planProgress, setPlanProgress] = useState(100)
  const { automations, tenants, jobs, apis, plan } = useDashboard()

  const colors = {
    automations: '#F72EA2',
    tenants: '#E0CF50',
    jobs: '#22C9AE',
    apis: '#5639FE',
  }

  useEffect(() => {
    const progress = Math.round(plan?.current / plan?.max)
    setPlanProgress(progress === 0 ? 1 : progress)
  }, [plan])

  return (
    <Container>
      <ChartContainer width={400}>
        <ChartLabel>Consumo</ChartLabel>
        <ChartKpi>
          <FiActivity size={30} />
          <ContainerBar>
            <BackgroundBar />
            <ProgressBar progress={planProgress} />
          </ContainerBar>
          <FlexBetween>
            <Align position="left">
              <Amount data-testid="overview-current">
                {insertBRNumberMask(plan?.current, {
                  maximumFractionDigits: 0,
                }) || 0}
              </Amount>
              <Text>Atual</Text>
            </Align>
            <Align position="right">
              <Amount data-testid="overview-max">
                {insertBRNumberMask(plan?.max, { maximumFractionDigits: 0 }) ||
                  0}
              </Amount>
              <Text>Limite do plano</Text>
            </Align>
          </FlexBetween>
        </ChartKpi>
      </ChartContainer>
      <ChartContainer>
        <ChartLabel>Automações</ChartLabel>
        <ChartKpi>
          <FiZap size={30} color={colors.automations} />
          <Amount
            size={30}
            color={colors.automations}
            data-testid="overview-amount-automations"
          >
            {automations?.total || 0}
          </Amount>
          <Align position="center">
            <Text>Total de Automações</Text>
          </Align>
        </ChartKpi>
      </ChartContainer>
      <ChartContainer>
        <ChartLabel>Tenants</ChartLabel>
        <ChartKpi>
          <FiUsers size={30} color={colors.tenants} />
          <Amount
            size={30}
            color={colors.tenants}
            data-testid="overview-amount-tenants"
          >
            {tenants?.total || 0}
          </Amount>
          <Align position="center">
            <Text>Total de Tenants</Text>
          </Align>
        </ChartKpi>
      </ChartContainer>
      <ChartContainer>
        <ChartLabel>Jobs</ChartLabel>
        <ChartKpi>
          <FiBriefcase size={30} color={colors.jobs} />
          <Amount
            size={30}
            color={colors.jobs}
            data-testid="overview-amount-jobs"
          >
            {jobs?.total || 0}
          </Amount>
          <Align position="center">
            <Text>Total de Jobs</Text>
          </Align>
        </ChartKpi>
      </ChartContainer>
      <ChartContainer>
        <ChartLabel>APIs</ChartLabel>
        <ChartKpi>
          <FiCloud size={30} color={colors.apis} />
          <Amount
            size={30}
            color={colors.apis}
            data-testid="overview-amount-apis"
          >
            {apis?.total || 0}
          </Amount>
          <Align position="center">
            <Text>Total de APIs</Text>
          </Align>
        </ChartKpi>
      </ChartContainer>
    </Container>
  )
}
