import React, { useCallback, useEffect, useState } from 'react'
import { FormikProvider, useFormik } from 'formik'
import {
  Button,
  InputSearch,
  InputText,
  Checkbox,
  notificationError,
  Loading,
  Modal,
  notificationSuccess,
} from '@devapi/design-system'
import { FiCheck } from 'react-icons/fi'
import * as Yup from 'yup'

import {
  IFindAllConnectorsParams,
  IPaginationResponse,
  IUpdateAutomationPayload,
} from '../../@types/providers.interface'
import {
  ConnectorsErrorMessage,
  Container,
  FormContainer,
  InputContainer,
  ModalActions,
  SearchContainer,
  SearchItem,
  CheckBoxContainer,
} from './AutomationEditForm.style'
import {
  IConnectorSerialized,
  IEditAutomationProps,
} from './AutomationEditForm.interface'
import { updateAutomation } from '../../providers/Automations.edit'
import { connectorsSerializer } from '../../adapters/ConnectorsSerializer'
import { findAllConnectors } from '../../providers/Connectors.find'
import { ConnectorsList } from '../../../../components/ConnectorsList'

const AutomationEditForm = ({
  editingAutomation,
  isOpen,
  onSuccess,
  onClose,
}: IEditAutomationProps) => {
  const [isPublicConnectors, setIsPublicConnectors] = useState(false)
  const [isPrivateConnectors, setIsPrivateConnectors] = useState(false)
  const [params, setParams] = useState({} as IFindAllConnectorsParams)
  const [isLoading, setIsLoading] = useState(false)
  const [connectorsSelected, setConnectorsSelected] = useState<string[]>([])
  const [connectors, setConnectors] = useState<IConnectorSerialized[]>([])
  const [currentPage, setCurrentPage] = useState<number | undefined>(undefined)
  const [totalPage, setTotalPage] = useState<number | undefined>(undefined)
  const [pagination, setPagination] = useState({} as IPaginationResponse)

  const handleClose = () => {
    onClose()
    setIsPublicConnectors(false)
    setIsPrivateConnectors(false)
    setConnectorsSelected(() => [])
    setPagination(values => {
      return { ...values, current: 1 }
    })
    formik.resetForm()
  }

  const schema = Yup.object().shape({
    name: Yup.string().required('Nome Obrigatório'),
    connectors: Yup.array().min(1, 'Selecione pelo menos um conector'),
  })

  const onSubmit = async (values: IUpdateAutomationPayload) => {
    try {
      setIsLoading(true)
      await updateAutomation({
        id: values.id,
        name: values.name,
        connectors: values.connectors,
      })
      handleClose()
      onSuccess()
      notificationSuccess({
        message: 'Automação atualizado com sucesso',
        position: 'top-right',
      })
    } catch (error) {
      const { message } = error.response.data
      notificationError({
        message: String(message).toLowerCase(),
        position: 'top-right',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const formik = useFormik({
    validateOnMount: false,
    enableReinitialize: true,
    initialValues: {
      id: editingAutomation.id,
      name: editingAutomation.name,
      connectors: editingAutomation.connectorsId,
    },
    validationSchema: schema,
    onSubmit,
  })

  const handleFindAllConnectors = async () => {
    try {
      const response = await findAllConnectors({
        current: pagination.current,
        params,
      })
      if (response.page.current > 1) {
        const formatedConnectors = await connectorsSerializer(response.content)
        formatedConnectors.forEach(connector => {
          setConnectors(values => [...values, connector])
        })
      } else {
        const formatedConnectors = await connectorsSerializer(response.content)
        setConnectors(formatedConnectors)
      }
      setPagination(response.page)
      setCurrentPage(response.page.current)
      setTotalPage(response.page.totalPages)
    } catch (error) {
      notificationError({
        message:
          error.response?.data?.message ||
          'Não foi possivel carregar conectores.',
        position: 'top-right',
      })
    }
  }

  useEffect(() => {
    setConnectors(() => [])
    setConnectorsSelected(editingAutomation.connectorsId)
  }, [editingAutomation, isOpen])

  useEffect(() => {
    if (isOpen) handleFindAllConnectors()
  }, [pagination.current, params, editingAutomation, isOpen])

  const handleCheckConnectorSelected = useCallback(() => {
    if (connectorsSelected) {
      connectorsSelected.forEach(connectorId => {
        const connectorSelectedIndex = connectors.findIndex(
          connector => connector.id === connectorId,
        )
        if (connectorSelectedIndex !== -1)
          connectors[connectorSelectedIndex].isSelected = true
      })
    }
  }, [connectorsSelected, connectors])

  useEffect(() => {
    if (connectors.length) handleCheckConnectorSelected()
  }, [connectors])

  const handleConector = (selected: boolean, id: string) => {
    if (selected) {
      setConnectorsSelected(oldValue => [...oldValue, id])
      formik.setFieldValue('connectors', [...connectorsSelected, id])

      const connectorIndex = connectors.findIndex(
        connector => connector.id === id,
      )
      connectors[connectorIndex].isSelected = true
      return
    }
    const updateList = connectorsSelected.filter(connector => connector !== id)

    const connectorIndex = connectors.findIndex(
      connector => connector.id === id,
    )
    connectors[connectorIndex].isSelected = false
    setConnectorsSelected(updateList)
    formik.setFieldValue('connectors', updateList)
  }

  const handleCheckPrivacyPrivate = () => {
    setIsPrivateConnectors(!isPrivateConnectors)
    setParams(values => {
      return { ...values, privacy: isPrivateConnectors ? '' : 'PRIVATE' }
    })
    setPagination(values => {
      return { ...values, current: 1 }
    })
    setIsPublicConnectors(false)
  }

  const handleCheckPrivacyPublic = () => {
    setIsPublicConnectors(!isPublicConnectors)
    setParams(values => {
      return { ...values, privacy: isPublicConnectors ? '' : 'PUBLIC' }
    })
    setPagination(values => {
      return { ...values, current: 1 }
    })
    setIsPrivateConnectors(false)
  }

  const handleSearch = (value: string) => {
    setParams(values => {
      return { ...values, search: value }
    })
  }

  const handleNextPage = (newPage: number) => {
    setPagination(values => {
      return { ...values, current: newPage }
    })
  }
  return (
    <Modal isOpen={isOpen} title="Edição da automação" onClick={handleClose}>
      <Container>
        <Loading text="Autalizando automação..." isOpen={isLoading} />
        <FormContainer>
          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
              <InputContainer>
                <InputText
                  label="Nome"
                  name="name"
                  placeHolder="Nome"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  errorMessage={formik.errors.name}
                  errorForm={!!formik.errors.name}
                />
              </InputContainer>
              <SearchContainer>
                <InputSearch
                  label="Encontrar conector"
                  placeHolder="Pesquisa"
                  onChange={handleSearch}
                />
                <SearchItem>
                  <CheckBoxContainer>
                    <Checkbox
                      label="Conectores privados"
                      value="private"
                      isChecked={isPrivateConnectors}
                      isDisabled={false}
                      onChange={handleCheckPrivacyPrivate}
                    />
                  </CheckBoxContainer>
                </SearchItem>
                <SearchItem>
                  <CheckBoxContainer>
                    <Checkbox
                      label="Conectores pre-built"
                      value="public"
                      isChecked={isPublicConnectors}
                      isDisabled={false}
                      onChange={handleCheckPrivacyPublic}
                    />
                  </CheckBoxContainer>
                </SearchItem>
              </SearchContainer>
              {formik.errors.connectors && (
                <ConnectorsErrorMessage>
                  {formik.errors.connectors}
                </ConnectorsErrorMessage>
              )}
              <ConnectorsList
                connectors={connectors}
                handleConector={handleConector}
                currentPage={currentPage}
                totalPages={totalPage}
                handleNextPage={handleNextPage}
              />
              <ModalActions>
                <Button
                  type="reset"
                  colorType="neutral"
                  variant="outlined"
                  text="Cancelar"
                  onClick={handleClose}
                />
                <Button
                  role="button-save"
                  variant="default"
                  colorType="green"
                  type="submit"
                  text="Salvar"
                  isDisabled={isLoading}
                  icon={<FiCheck />}
                />
              </ModalActions>
            </form>
          </FormikProvider>
        </FormContainer>
      </Container>
    </Modal>
  )
}
export { AutomationEditForm }
