import { AiOutlinePlus } from 'react-icons/ai'
import theme from 'src/assets/styles/theme'
import styled from 'styled-components'

export const IconContainer = styled.div`
  display: flex;

  width: 100px;
  height: 100px;

  justify-content: center;
  align-items: center;

  border-radius: 50%;
  border: 5px solid ${theme.colors.secondary};
`
export const PlusIcon = styled(AiOutlinePlus)`
  color: ${theme.colors.secondary};

  font-size: 70px;
`
