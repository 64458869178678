/* eslint-disable react/jsx-key */
import React, { useState, useEffect } from 'react'
import {
  IUserSearchFilters,
  IUserResponsePagination,
  IUpdateUser,
} from '../@types/users.interface'
import { IUser } from './Users.interface'
import theme from 'src/assets/styles/theme'
import { Container, Main, AlignCenter } from './Users.style'
import {
  InputSearch,
  Button,
  Table,
  Pagination,
  notificationError,
  Loading,
} from '@devapi/design-system'
import { BsPencil, BsArrowClockwise } from 'react-icons/bs'
import { InternalHeader } from 'src/components/InternalHeader'
import { serializeUsersForTable } from '../adapters/serializeUsersForTable'
import { findAllUsers } from '../providers'
import { CreateUser } from './create'
import { EditUser } from './edit'

export const Users = () => {
  const [users, setUsers] = useState([] as IUser[])
  const [isLoading, setIsLoading] = useState(false)
  const [responsive, setResponsive] = useState(false)
  const [userToUpdate, setUserToUpdate] = useState({} as IUpdateUser)
  const [isCreateUserModalOpen, setIsCreateUserModalOpen] = useState(false)
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false)
  const [searchFilters, setSearchFilters] = useState({} as IUserSearchFilters)
  const [pagination, setPagination] = useState({} as IUserResponsePagination)

  const handleFindAllUsers = async () => {
    try {
      setIsLoading(true)
      const { content, page } = await findAllUsers(
        pagination.current,
        searchFilters,
      )
      setUsers(serializeUsersForTable(content, handleFindAllUsers))
      setPagination(page)
    } catch (error) {
      notificationError({
        message: error.response?.data?.message || 'Erro ao buscar usuários',
        position: 'top-right',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const findUserById = itemRow => {
    const findUser = users.find(item => item.id === itemRow.id)
    if (findUser) {
      setUserToUpdate(findUser)
      setIsEditUserModalOpen(true)
    }
  }

  const clearPaginationAndFilters = () => {
    setPagination({ ...pagination, current: 1 })
    setSearchFilters({ ...searchFilters, search: '' })
  }

  const handleCreateUserModal = () => {
    setIsCreateUserModalOpen(!isCreateUserModalOpen)
  }

  const handleUpdateUserModal = () => {
    setUserToUpdate({} as IUpdateUser)
    setIsEditUserModalOpen(false)
  }

  const handleOrderBy = (column: string) => {
    const validOrderBy = {
      completeName: 'name',
      status: 'status',
    }
    setSearchFilters({ ...searchFilters, orderby: validOrderBy[column] })
  }

  useEffect(() => {
    handleFindAllUsers()
  }, [pagination.current, searchFilters])

  useEffect(() => {
    setResponsive(window.innerWidth <= 1366)
  }, [])

  const columns = [
    { key: 'id', label: 'ID', isOrder: false, columnWidth: '250px' },
    { key: 'completeName', label: 'Nome', isOrder: true },
    { key: 'email', label: 'E-mail', isOrder: false },
    { key: 'completePhone', label: 'Telefone', isOrder: false },
    { key: 'status', label: 'Bloquear', isOrder: true },
    { key: 'options', label: 'Editar', isOrder: false },
  ]

  const crumbs = [{ label: 'Usuários', value: 'users' }]

  return (
    <Container>
      <InternalHeader
        crumbs={crumbs}
        buttonText="Novo usuário"
        onClick={handleCreateUserModal}
        filters={[
          {
            field: (
              <InputSearch
                label="Encontrar usuário"
                placeHolder="Pesquisa"
                onChange={search => setSearchFilters({ search })}
                fieldSize={responsive ? '250px' : '320px'}
                defaultValue={searchFilters.search}
                isLabelBold={false}
              />
            ),
          },
        ]}
      />
      <Loading isOpen={isLoading} text="Carregando usuários..." />
      {!isLoading && (
        <Main>
          <Table
            noContentText="Nenhum resultado encontrado..."
            tableData={users}
            tableHeaderOptions={columns}
            orderBy={['name', 'status']}
            sortTableData={handleOrderBy}
            optionsActions={[
              <span role="span-edit" onClick={findUserById}>
                <BsPencil {...theme.icon} color={theme.colors.primary} />
              </span>,
            ]}
            buttonReloadClick={clearPaginationAndFilters}
            buttonReload={
              <Button
                colorType="green"
                variant="outlined"
                text="Recarregar"
                icon={<BsArrowClockwise />}
              />
            }
          />
          {pagination.totalPages > 1 && (
            <AlignCenter>
              <Pagination
                size="default"
                totalPages={pagination.totalPages}
                page={pagination.current}
                onChange={value =>
                  setPagination({ ...pagination, current: value })
                }
              />
            </AlignCenter>
          )}
        </Main>
      )}
      <CreateUser
        isOpen={isCreateUserModalOpen}
        onClose={handleCreateUserModal}
        onSuccess={handleFindAllUsers}
      />
      <EditUser
        isOpen={isEditUserModalOpen}
        onClose={handleUpdateUserModal}
        onSuccess={handleFindAllUsers}
        user={userToUpdate}
      />
    </Container>
  )
}
