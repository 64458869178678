import theme from 'src/assets/styles/theme'
import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 130px;
  border: 1px solid ${theme.colors.gray[300]};
  margin-bottom: 10px;
  border-radius: 10px;
  transition: 0.2s;
  cursor: pointer;
  &:hover {
    box-shadow: 0 0 10px ${theme.colors.gray[300]};
    transform: scale(1.1);
  }

  @media (max-width: 1400px) {
    width: 140px;
  }
`

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 80%;
  height: 100%;
`

export const Logo = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
  max-height: 60px;
  max-width: 100px;
`

export const Label = styled.span`
  font-size: 13px;
  color: ${theme.colors.primary};
`
export const IconContainer = styled.div`
  position: absolute;
  display: flex;
  top: 10px;
  right: 10px;
`
export const Icon = styled.div`
  background-color: ${theme.colors.secondary};
  color: ${theme.colors.white};
  width: 16px;
  height: 16px;
  border-radius: 16px;
  text-align: center;
  padding: 1px;
  font-size: 11px;
`
