import React, { useEffect, useState } from 'react'
import { Container, ListContainer, PaginationContainer } from './Tenants.style'
import theme from 'src/assets/styles/theme'
import {
  Button,
  InputSearch,
  Loading,
  notificationError,
  Pagination,
  Table,
} from '@devapi/design-system'

import { ITenantTable } from './Tenants.interface'
import {
  IPaginationResponse,
  ITenantResponse,
} from '../@types/provider.interface'

import { InternalHeader } from 'src/components/InternalHeader'
import { findTenants } from '../providers/tenant.find'
import { CreateTenant } from './create'
import { handleTableTenant } from '../adapters/handleTableTenant'
import { EditTenant } from './edit'
import { BsPencil, BsArrowClockwise } from 'react-icons/bs'

const Tenants = () => {
  const crumbs = [{ label: 'Tenants', value: 'tenants' }]

  const [tenants, setTenants] = useState<ITenantTable[]>([])
  const [listTenant, setListTenants] = useState<ITenantResponse[]>([])
  const [searchTenant, setSearchTenant] = useState('')

  const [openCreateTenantModal, setOpenCreateTenantModal] = useState(false)
  const [openEditTenantModal, setOpenEditTenantModal] = useState(false)
  const [pagination, setPagination] = useState({} as IPaginationResponse)
  const [orderBy, setOrderBy] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [responsive, setResponsive] = useState(false)
  const [editingTenant, setEditingTenant] = useState({} as ITenantResponse)

  const handleOpenCreateModal = () => {
    setOpenCreateTenantModal(!openCreateTenantModal)
  }

  const handleSearch = (search: string) => {
    setSearchTenant(search)
  }

  const handleEditTenant = tenant => {
    const findTenant = listTenant.find(item => item._id === tenant.id)
    if (findTenant) {
      setEditingTenant(findTenant)
      setOpenEditTenantModal(true)
    }
  }

  const handleToggleEditModal = () => {
    setOpenEditTenantModal(!openEditTenantModal)
  }

  const handleFindTenant = async () => {
    try {
      setIsLoading(true)
      const response = await findTenants({
        currentPage: pagination.current,
        search: searchTenant,
        orderBy,
      })
      const { page, content } = response

      const tenants = handleTableTenant({
        tenants: content,
        onSuccess: handleFindTenant,
      })
      setListTenants(content)
      setTenants(tenants)
      setPagination(page)
    } catch (error) {
      notificationError({
        message:
          error.response?.data?.message ||
          'Não foi possível carregar os tenants',
        position: 'top-right',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleOrderBy = (colum: string) => {
    const validOrderBy = {
      name: 'name',
      status: 'status',
    }
    setOrderBy(validOrderBy[colum])
  }

  const tableHeaderOptions = [
    {
      label: 'ID',
      isOrder: false,
      key: 'id',
      columnWidth: '350px',
    },
    {
      label: 'Nome',
      isOrder: true,
      key: 'name',
      columnWidth: '350px',
    },
    {
      label: 'Status',
      isOrder: true,
      key: 'status',
      columnWidth: '300px',
    },
    {
      label: 'Opções',
      columnWidth: '50px',
      key: 'options',
    },
  ]

  const filters = [
    {
      field: (
        <InputSearch
          label="Encontrar tenants"
          onChange={handleSearch}
          fieldSize={responsive ? '250px' : '320px'}
          placeHolder="Pesquisa"
          defaultValue={searchTenant}
          isLabelBold={false}
        />
      ),
    },
  ]

  useEffect(() => {
    handleFindTenant()
  }, [pagination.current, searchTenant, orderBy])

  useEffect(() => {
    setResponsive(window.innerWidth <= 1366)
  }, [])

  return (
    <Container>
      <InternalHeader
        filters={filters}
        crumbs={crumbs}
        buttonText="Novo tenant"
        onClick={() => handleOpenCreateModal()}
      />
      <Loading isOpen={isLoading} text="Carregando os tenants..." />
      {!isLoading && (
        <ListContainer>
          <Table
            width="100%"
            tableHeaderOptions={tableHeaderOptions}
            noContentText="Nenhum resultado encontrado."
            tableData={tenants}
            orderBy={['name', 'status']}
            sortTableData={handleOrderBy}
            optionsActions={[
              <BsPencil
                key={1}
                {...theme.icon}
                color={theme.colors.primary}
                onClick={itemRow => handleEditTenant(itemRow)}
              />,
            ]}
            buttonReload={
              <Button
                colorType="green"
                variant="outlined"
                text="Recarregar"
                icon={<BsArrowClockwise />}
                iconPosition="left"
                onClick={handleFindTenant}
              />
            }
          />
          {pagination.totalPages > 1 && (
            <PaginationContainer>
              <Pagination
                size="default"
                totalPages={pagination.totalPages}
                page={pagination.current}
                onChange={value =>
                  setPagination({ ...pagination, current: value })
                }
              />
            </PaginationContainer>
          )}
        </ListContainer>
      )}
      <CreateTenant
        isOpen={openCreateTenantModal}
        handleToggleModal={handleOpenCreateModal}
        onSuccess={handleFindTenant}
      />
      <EditTenant
        editingTenant={editingTenant}
        isOpen={openEditTenantModal}
        handleToggleModal={handleToggleEditModal}
        onSuccess={handleFindTenant}
      />
    </Container>
  )
}

export { Tenants }
