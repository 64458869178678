import React from 'react'
// import { Switch } from '@devapi/design-system'
// import { BsBook } from 'react-icons/bs'
import {
  IoMdHeartEmpty,
  IoIosCloudOutline,
  // IoIosNotificationsOutline,
  IoIosArrowForward,
} from 'react-icons/io'
// import { AiOutlineShoppingCart } from 'react-icons/ai'
import { FiActivity, FiUser, FiUsers, FiBriefcase, FiZap } from 'react-icons/fi'
import { BiCloudLightRain } from 'react-icons/bi'

import {
  Container,
  MenuLogoContainer,
  MenuItemsContainer,
  Item,
  // MascotContainer,
  IconContainer,
  ItemText,
  SubMenuWrapper,
  SubMenu,
  SubMenuItem,
} from './MenuNavigation.styles'
import { IMenuNavigationProps } from './MenuNavigation.interface'

export const MenuNavigation = ({
  // onChangeSwitch,
  // isActiveMascotSwitch,
  activeItemMenu,
  redirectRoute,
}: IMenuNavigationProps) => {
  const MenuItemsList = [
    {
      label: 'Dashboard',
      route: '/dashboard',
      icon: <FiActivity size="20px" />,
    },
    // {
    //   label: 'Notificações',
    //   route: '/notification',
    //   icon: <IoIosNotificationsOutline size="20px" />,
    // },
    // {
    //   label: 'Tutoriais',
    //   route: '/tutors',
    //   icon: <BsBook size="20px" />,
    // },
    {
      label: 'Conectores',
      route: '/connectors',
      icon: <IoMdHeartEmpty size="20px" />,
    },
    {
      label: 'iPaaS',
      icon: <BiCloudLightRain size="20px" />,
      route: '/ipaas',
      submenu: [
        {
          label: 'Jobs',
          route: '/ipaas/jobs',
          icon: <FiBriefcase size="20px" />,
        },
        {
          label: 'Automações',
          route: '/ipaas/automations',
          icon: <FiZap size="20px" />,
        },
      ],
    },
    {
      label: 'API Management',
      route: '/apis',
      icon: <IoIosCloudOutline size="20px" />,
    },
    // {
    //   label: 'DevStore',
    //   route: '/devstore',
    //   icon: <AiOutlineShoppingCart size="20px" />,
    // },
    {
      label: 'Tenants',
      route: '/tenants',
      icon: <FiUsers size="20px" />,
    },
    {
      label: 'Usuários',
      route: '/users',
      icon: <FiUser size="20px" />,
    },
  ]

  const handlePath = (path: string) => {
    return path.match(/^\/\w+/)?.join()
  }

  return (
    <Container>
      <div>
        <MenuLogoContainer />
        <MenuItemsContainer>
          {MenuItemsList.map((item, index) => {
            const itemActive = item.route === handlePath(activeItemMenu)
            return (
              <Item
                data-testid={item.route}
                key={index}
                isActive={itemActive}
                onClick={() => !item.submenu && redirectRoute(item.route)}
              >
                {item.submenu && (
                  <SubMenuWrapper>
                    <SubMenu>
                      {item.submenu.map((menu, index) => (
                        <SubMenuItem
                          key={index}
                          onClick={() => redirectRoute(menu.route)}
                        >
                          <span>{menu.icon}</span>
                          <h3>{menu.label}</h3>
                        </SubMenuItem>
                      ))}
                    </SubMenu>
                  </SubMenuWrapper>
                )}
                <ItemText>
                  <IconContainer>{item.icon}</IconContainer>
                  <span>{item.label}</span>
                </ItemText>
                <div>{itemActive && <IoIosArrowForward />}</div>
              </Item>
            )
          })}
        </MenuItemsContainer>
      </div>
      {/* <MascotContainer>
        Desativar Mascote
        <div style={{ marginLeft: '10px' }}>
          <Switch
            data-testid="mascot-switch"
            isChecked={isActiveMascotSwitch}
            onChange={onChangeSwitch}
          />
        </div>
      </MascotContainer> */}
    </Container>
  )
}
